import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { VendorOrderTicketModal } from '../../../../../../shared/models/Vendor';
import { Vendor, VendorBuyBinOptions, VendorOrderBuyPrintOptions, VendorOrderEditOptions } from '../../../../../../shared/types';
import { PaymentType } from '../../../../../../shared/models/enums/enums';
import { BinStatusEnum } from '../../types/enums';
import { Config } from '../../../../../../shared/config/config';
@Component({
  selector: 'app-agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.css'],
})
export class AgentsListComponent implements OnChanges {
  @Input()
  vendors: Vendor[];

  @Input()
  selectedVendor: number = 0;

  @Input()
  pagination: { currentPage: number; totalElements: number; paginationSize: number };

  @Output()
  eventModal: EventEmitter<VendorOrderTicketModal> = new EventEmitter();

  @Output()
  orderRemove: EventEmitter<string> = new EventEmitter();

  @Output()
  buyOrder: EventEmitter<VendorBuyBinOptions> = new EventEmitter();

  @Output()
  orderEdit: EventEmitter<VendorOrderEditOptions> = new EventEmitter();

  @Output()
  orderBuyDocumentPrint: EventEmitter<VendorOrderBuyPrintOptions> = new EventEmitter();

  @Output()
  editVendor: EventEmitter<Partial<Vendor>> = new EventEmitter();

  @Output()
  onExpandVendor: EventEmitter<{ vendorId: number; isExpanded: boolean; page: number; binStatus: 'ARCHIVE' | 'ACTIVE' }> =
    new EventEmitter();

  @Output()
  onPageChange: EventEmitter<{ vendorId: number; page: number; binStatus: 'ARCHIVE' | 'ACTIVE' }> = new EventEmitter();

  @Output()
  onPageChangeAllVendors: EventEmitter<number> = new EventEmitter();

  isPayedTab = false;
  _vendors: any[] = [];
  readonly PHONE_PATTERN = Config.PHONE_MASK;
  readonly PAYMENT_TYPES: { type: PaymentType; label: string }[] = [
    { type: 'CASH', label: 'Готівка' },
    { type: 'CARD', label: 'Термінал' },
    { type: 'NO_CASH', label: 'Безготівка' },
  ];
  readonly PAYMENT_TYPES_MAP = {
    CASH: 'Готівка',
    CARD: 'Термінал',
    NO_CASH: 'Безготівка',
  };
  binStatusEnum = BinStatusEnum;
  editId: number | null = null;
  vendorNameControl: FormControl = new FormControl();
  vendorPhoneControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/(38) \([0-9 ]{3}\)\-[0-9 ]{2}\-[0-9 ]{2}\-[0-9 ]{3}/),
  ]);

  private selectedVendorId: number | null = null;
  private rejectReturnActionModal: NzModalRef;

  constructor(private modal: NzModalService) {}

  ngOnInit(): void {
    this.selectedVendorId = history.state.data?.vendorId;
  }

  changePage(ev: number) {
    this.onPageChangeAllVendors.emit(ev);
  }

  expandVendor(vendor: { id: number; expand: boolean; binStatus: 'ACTIVE' | 'ARCHIVE' }) {
    this.onExpandVendor.emit({ vendorId: vendor.id, isExpanded: vendor.expand, page: 0, binStatus: vendor.binStatus || 'ACTIVE' });
  }

  getChildTable(index) {
    return this.vendors[index]?.bins ?? [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.vendors) {
      return;
    }

    if (!this.vendors || !this.vendors.length) {
      this._vendors = [];
    } else {
      this._vendors = this.vendors.map(agent => {
        if (this.selectedVendorId && this.selectedVendorId === agent?.id) {
          return { ...agent, expand: true };
        } else {
          return { ...agent, expand: !!(agent as any).expand };
        }
      });
    }
  }

  checkReturnOption(
    vendorIndex: number,
    order: string,
    isSimplePreview: boolean = false,
    actionMode: string = 'print',
    binStatus: string,
  ): void {
    if (binStatus !== 'IN_PROGRESS_LOCKING_BY_VENDOR') {
      this.showActionsModal(vendorIndex, order, isSimplePreview, actionMode);
    } else {
      this.rejectReturnActionModal = this.modal.confirm({
        nzTitle: 'Повернення неможливе',
        nzContent: 'Статус даного замовлення не дозволяє виконати повернення!',
        nzOnOk: (): void => {},
      });
    }
  }

  showActionsModal(vendorIndex: number, order: string, isSimplePreview: boolean = false, actionMode: string = 'print'): void {
    this.eventModal.emit({
      vendorIndex,
      order,
      isSimplePreview,
      actionMode,
    });
  }

  nzPageIndexChange(ev: any, vendorId: number, vendorbinStatus: 'ACTIVE' | 'ARCHIVE'): void {
    this.onPageChange.emit({ vendorId, page: ev, binStatus: vendorbinStatus });
  }

  removeOrder(orderId: string): void {
    this.orderRemove.emit(orderId);
  }

  buyBin(vendorId: number, orderId: string, typeOfPayment: PaymentType): void {
    const options: VendorBuyBinOptions = { vendorId, orderId, typeOfPayment };
    this.buyOrder.emit(options);
  }

  editOrder(eventId, vendor, binId): void {
    this.orderEdit.emit({ eventId, vendor, binId });
  }

  printBuyOrder(vendor, orderId: string, eventId, revert = false, isNew: boolean): void {
    this.orderBuyDocumentPrint.emit({ vendor, orderId, eventId, revert, isNew });
  }

  onVendorEdit(vendor: Vendor): void {
    this.editId = vendor.id;
    this.vendorNameControl.setValue(vendor.fullName);
    this.vendorPhoneControl.setValue(vendor.phone);
  }

  getEventTime(startDate: string, endDate: string): number {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return end.valueOf() - start.valueOf();
  }

  onVendorSave(id: number): void {
    if (!this.vendorNameControl.value || !this.vendorPhoneControl.value || !this.vendorPhoneControl.valid) {
      this.modal.confirm({
        nzTitle: 'Реданування неможливе',
        nzContent: 'Перевірте введені дані!',
        nzOnOk: (): void => {},
      });

      return;
    }

    this.editVendor.emit({
      id,
      fullName: this.vendorNameControl.value,
      phone: this.vendorPhoneControl.value,
    });
    this.editId = null;
  }
}
