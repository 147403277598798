import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BoxOfficeEventDto } from '../../../../../shared/models/dto/BoxOfficeEventDto';

@Component({
  selector: 'app-returned-tickets-one',
  templateUrl: './returned-tickets-one.component.html',
  styleUrls: ['./returned-tickets-one.component.css']
})
export class ReturnedTicketsOneComponent implements OnInit {

  @Input() one: BoxOfficeEventDto;

  @Output() retTic = new EventEmitter();
  eventRet;

  lang: string;

  constructor(private _translateService: TranslateService) {
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(next => {
      this.lang = next.lang;
    }, error => {
      console.error(error);
    });
  }

  ngOnInit() {
  }

  returnTicket() {
    this.retTic.emit(this.one.id);
  }

}
