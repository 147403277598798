<div class="platforms">
  <div class="platforms_number">
    <p class="text_style"></p>
  </div>
  <div class="platform_name">
    <p class="name_platforms">{{'platforms.name' | translate}}</p>
    <div class="name">
      <p class="text_style name_plat"
        *ngIf="lang == 'ua'">{{one.name.valueUA}}</p>
      <p class="text_style name_plat"
        style="font-weight: normal;font-size: 14px;"
        *ngIf="lang == 'ua'">{{one.cityName.valueUA + ', ' + one.address.valueUA}}</p>
      <p class="text_style name_plat"
        *ngIf="lang == 'ru'">{{one.name.valueRU}}</p>
      <p class="text_style name_plat"
        style="font-weight: normal;font-size: 14px;"
        *ngIf="lang == 'ru'">{{one.cityName.valueRU + ', ' + one.address.valueRU}}</p>
      <p class="text_style name_plat"
        *ngIf="lang == 'en'">{{one.name.valueEN}}</p>
      <p class="text_style name_plat"
        style="font-weight: normal;font-size: 14px;"
        *ngIf="lang == 'en'">{{one.cityName.valueEN + ', ' + one.address.valueEN}}</p>
      <a [href]="'https://www.google.com/maps?z=12&t=m&q=loc:'+one.lat+'+'+one.lng"
        target="_blank">{{'platforms.maps'|translate}}</a>
    </div>
  </div>
  <button class="platform_btn"
    (click)="navigationToHome()">
    {{'platforms.events' | translate}}<br>
    <span>{{'platforms.countEvents'|translate}}: {{one.numberOfEvents}}</span>
  </button>
</div>
