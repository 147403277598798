import { Inject, PLATFORM_ID } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimePipe } from '../../pipes/time.pipe';

export class TimerBuilder {
  private timer: Subscription;
  public time: string;
  private onDestroyFunc;
  private onEndFunc;
  private name: string;

  constructor(private date?: string, @Inject(PLATFORM_ID) private platformId?: Object) {
    if (date) {
      if ((!date.match('[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]T[0-9][0-9]:[0-9][0-9]:[0-9][0-9].[0-9][0-9][0-9]Z')) || (!date.match('[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]T[0-9][0-9]:[0-9][0-9]:[0-9][0-9]'))) {
        throw new class implements Error {
          message: string = 'error date format';
          name: string = 'customTimerBuilderError';
          stack: string = 'TimerBuilder';
        };
      }
      if (date.match('[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]T[0-9][0-9]:[0-9][0-9]:[0-9][0-9].[0-9][0-9][0-9]Z')) {
        this.date = this.date + '.625Z';
      }
    }
  }

  getDate(): string {
    return this.date;
  }

  setStorage(name: string): this {
    // if (isPlatformBrowser(this.platformId)) {
    this.name = name;
    localStorage.setItem(name, this.getDate());
    return this;
    // }
  }

  getFromStorage(name: string): this {
    this.name = name;
    if (!localStorage.getItem(name)) {
      throw new class implements Error {
        message: string = 'error not found in storage';
        name: string = 'customTimerBuilderError';
        stack: string = 'TimerBuilder.getFromStorage';
      };
    }
    this.setDate(new Date(localStorage.getItem(name)));
    return this;
  }

  setDate(date: Date): this {
    this.date = date.toISOString();
    return this;
  }

  setSecond(second: number): this {
    let d: Date = new Date();
    d.setTime(d.getTime() + (second * 1000));
    this.setDate(d);
    return this;
  }

  onDestory(func): this {
    this.onDestroyFunc = func;
    return this;
  }

  onEnd(func): this {
    this.onEndFunc = func;
    return this;
  }

  destroy() {
    if (this.onDestroyFunc)
      this.onDestroyFunc();
    if (this.timer) {
      this.timer.unsubscribe();
      this.time = '00:00';
    }
  }

  private end() {
    // if (isPlatformBrowser(this.platformId)) {
    if (this.name)
      localStorage.removeItem(this.name);
    if (this.onEndFunc)
      this.onEndFunc();
    // }
  }

  buildFromNow(): this {
    if (this.date) {
      if (new Date(this.date).getTime() > new Date().getTime())
        this.timer = timer(0, 1000).pipe(
          map((res) => {
            return ((new Date(this.date).getTime() - new Date().getTime()) / 1000).toFixed(0);
          })
        ).subscribe((data: string) => {
          if (parseInt(data) > 0) {
            this.time = new TimePipe().transform(parseInt(data));
          } else {
            this.end();
            this.destroy();
          }
        })
      else {
        this.end();
        this.destroy();
      }
      return this;
    }
    throw new class implements Error {
      message: string = 'date is null';
      name: string = 'customTimerBuilderError';
      stack: string = 'TimerBuilder.buildFromNow';
    };
  }
}
