import { Pipe, PipeTransform } from "@angular/core";
import { CurrentLang } from '../types/types/lang.types';

@Pipe({ name: 'valueByLang' })
export class ValueByLanguage implements PipeTransform {
  /**
  * @param lang - current language value
  */
  transform(localizedData: any, lang: CurrentLang): string {
    switch (lang) {
      case 'ua':
        return localizedData['valueUA'];
      case 'en':
        return localizedData['valueEN'];
      case 'ru':
        return localizedData['valueRU'];
    }
  }
}
