import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { forkJoin } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { BoxOfficeService } from '../../../../../../shared/service/backend/box-office.service';

@Component({
  selector: 'app-event-by-sellers',
  templateUrl: './event-by-sellers.component.html',
  styleUrls: ['./event-by-sellers.component.css']
})
export class EventBySellersComponent implements OnInit {
  public sellersStatistics;
  public onlineStatistics;

  constructor(
    private route: ActivatedRoute,
    private boxOfficeService: BoxOfficeService,
  ) { }

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        switchMap(
          (params: ParamMap) => forkJoin({
            a: this.boxOfficeService.getSellersStatistic(params['id']),
            b: this.boxOfficeService.getSellersOnlineStatistic(params['id']),
          })),
      )
      .subscribe((data) => {
        this.sellersStatistics = data.a;
        this.onlineStatistics = data.b;
      })
  }

}
