import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PdfService {
  controller = '/pdf';

  constructor(private _http: HttpClient) {}

  generatePdfTerm(list: number[], withPrice?: boolean): Observable<any> {
    let body: string = '?';
    let nameFile: string = '';
    list.forEach(value => {
      body += 'tickets=' + value + '&';
      nameFile += '_' + value;
    });
    body += `withPrice=${withPrice || false}`;
    return this._http.get(this.controller + '/generate-pdf-term' + body, { responseType: 'blob' }).pipe(catchError(err => throwError(err)));
  }

  generateBigPdfTerm(list: number[], withPrice?: boolean): Observable<any> {
    let body: string = '?';
    let nameFile: string = '';
    list.forEach(value => {
      body += 'tickets=' + value + '&';
      nameFile += '_' + value;
    });
    body += `withPrice=${withPrice || false}`;
    return this._http
      .get(this.controller + '/generate-big-pdf-term' + body, { responseType: 'blob' })
      .pipe(catchError(err => throwError(err)));
  }

  generatePdfA4(list: number[], withPrice?: boolean): Observable<Blob> {
    let body: string = '?';
    let nameFile: string = '';
    list.forEach(value => {
      body += 'tickets=' + value + '&';
      nameFile += '_' + value;
    });
    body += `withPrice=${withPrice || false}`;
    return this._http.get(this.controller + '/generate-pdf-a4' + body, { responseType: 'blob' }).pipe(catchError(err => throwError(err)));
  }

  generatePdfTermForInvite(list: string[] | string[][] | number[], withPrice?: boolean): Observable<Blob> {
    let body: string = '?';
    let nameFile: string = '';
    list.forEach(value => {
      body += 'tickets=' + value + '&';
      nameFile += '_' + value;
    });
    body += `withPrice=${withPrice || false}`;
    return this._http
      .get(this.controller + '/generate-pdf-term-for-invite' + body, { responseType: 'blob' })
      .pipe(catchError(err => throwError(err)));
  }

  generateBigPdfTermForInvite(list: string[] | number[] | string[][], withPrice?: boolean): Observable<any> {
    let body: string = '?';
    let nameFile: string = '';
    list.forEach(value => {
      body += 'tickets=' + value + '&';
      nameFile += '_' + value;
    });
    body += `withPrice=${withPrice || false}`;
    return this._http
      .get(this.controller + '/generate-big-pdf-term-for-invite' + body, { responseType: 'blob' })
      .pipe(catchError(err => throwError(err)));
  }

  generatePdfA4ForInvite(list: string[] | string[][] | number[], withPrice?: boolean): Observable<Blob> {
    let body: string = '?';
    let nameFile: string = '';
    list.forEach(value => {
      body += 'tickets=' + value + '&';
      nameFile += '_' + value;
    });
    body += `withPrice=${withPrice || false}`;
    return this._http
      .get(this.controller + '/generate-pdf-a4-for-invite' + body, { responseType: 'blob' })
      .pipe(catchError(err => throwError(err)));
  }
}
