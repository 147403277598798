import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDetailsService } from '../../../../shared/service/user-details-service.service';
import { MainSettingConfig, MainSettingsService } from '../../../../shared/service/rx/main-settings.service';
import { AccessRoleService } from 'src/shared/service/backend/access-roles.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
})
export class SideBarComponent implements OnInit, OnDestroy {
  mainSettingConfig: MainSettingConfig;
  isReportsBlocked: boolean = true;

  subs: Subscription[] = [];

  constructor(
    private _userDetailsService: UserDetailsService,
    private _mainSettingsService: MainSettingsService,
    private accessRoleService: AccessRoleService,
  ) {
    this._mainSettingsService.settingConfig$.subscribe(
      next => {
        this.mainSettingConfig = next;
      },
      error => {
        console.error(error);
      },
    );
    this.mainSettingConfig = this._mainSettingsService.getConfig();
    this.subs.push(this.accessRoleService.accessList.subscribe(res => (this.isReportsBlocked = res && res.excludedModule === 'REPORT')));
  }

  ngOnInit() {}

  logout() {
    this._userDetailsService.logout(true);
  }

  openCloseDetails(id: string, ids: string) {
    document.getElementById(id).classList.toggle('contains');
    document.getElementById(ids).classList.toggle('tranforms');
    let classes = document.getElementsByClassName('text-bar');
    for (let i = 0; i < classes.length; i++) {
      (<HTMLElement>classes[i]).classList.toggle('display-block');
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach(el => el && el.unsubscribe());
  }
}
