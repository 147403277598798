import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { PlatformHall } from '../../../../../shared/models/PlatformHall';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { HallService } from '../../../../../shared/service/backend/hall.service';
import { BinHistoryBoxOfficeDto } from '../../../../../shared/models/dto/BinHistoryBoxOfficeDto';
import { ExcelBuilderService } from '../../../../../shared/service/backend/excel-builder.service';
import { Event } from '../../../../../shared/models/Event';
import { DataForPopUp } from '../history-container/history-container.component';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { downloadExcel } from 'src/shared/service/utils/download-exel';

class BinDate {
  date: string;
  bins: BinHistoryBoxOfficeDto[] = [];
}

class Pageable {
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: number;
  first: number;
  numberOfElements: number;
}

@Component({
  selector: 'app-history-booked-container',
  templateUrl: './history-booked-container.component.html',
  styleUrls: ['./history-booked-container.component.css'],
})
export class HistoryBookedContainerComponent implements OnInit {
  bins: BinDate[] = [];
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  lang: string;

  @ViewChild('mainForm') mainForm: ElementRef;

  pageable: Pageable = new Pageable();

  popUpMain: DataForPopUp = new DataForPopUp();

  page: number = 0;
  count: number = 20;

  platforms: PlatformHall[] = [];

  typeBooked: boolean = true;

  constructor(
    private _bookingService: BookingService,
    private _userDetailsService: UserDetailsService,
    private _translateService: TranslateService,
    private _hallService: HallService,
    private _excelBuilderService: ExcelBuilderService,
  ) {
    this.user = this._userDetailsService.getUser();
    this._userDetailsService.user$.subscribe(
      next => {
        this.user = next;
      },
      error => {
        console.error(error);
      },
    );
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(
      next => {
        this.lang = next.lang;
      },
      error => {
        console.error(error);
      },
    );

    this._bookingService.filterBinBoughtTicket(this.page, this.count, 'BOOKED', 'DESC').subscribe(
      next => {
        this.groupEventByDate(next);
        this.pageable = next;
      },
      error => {
        console.error(error);
      },
    );
    this._hallService.findAllPlatformHallsAvailable().subscribe(
      next => {
        this.platforms = next;
      },
      error => {
        console.error(error);
      },
    );
  }

  filter() {
    this.page = 0;
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.loadBins();
  }

  loadBins() {
    this._bookingService
      .filterBinBoughtTicket(this.page, this.count, 'BOOKED', 'DESC', {
        dateFrom: this.mainForm.nativeElement[0].value,
        dateTo: this.mainForm.nativeElement[1].value,
        platform_hall: this.mainForm.nativeElement[3].value,
        type_of_payment: this.mainForm.nativeElement[2].value,
      })
      .subscribe(
        next => {
          this.groupEventByDate(next);
          this.pageable = next;
        },
        error => {
          console.error(error);
        },
      );
  }

  resetFilter() {
    this.page = 0;
    this.mainForm.nativeElement.reset();
    this.loadBins();
  }

  groupEventByDate(arr) {
    this.bins = [];
    let tempArr: BinDate = new BinDate();
    arr.content.forEach((value, index) => {
      if (index == 0) {
        tempArr.date = value.created;
        tempArr.bins.push(value);
        if (arr.content.length == 1) {
          this.bins.push(tempArr);
        }
      } else {
        if (new Date(value.created).toLocaleDateString() == new Date(tempArr.date).toLocaleDateString()) {
          tempArr.bins.push(value);
          if (index == arr.content.length - 1) {
            this.bins.push(tempArr);
          }
        } else {
          this.bins.push(tempArr);
          tempArr = new BinDate();
          tempArr.date = value.created;
          tempArr.bins.push(value);
          if (index == arr.content.length - 1) {
            this.bins.push(tempArr);
          }
        }
      }
    });
  }

  nextPage() {
    this.page++;
    this.loadBins();
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  prevPage() {
    this.page--;
    this.loadBins();
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  ngOnInit() {}

  downloadExcel() {
    this._excelBuilderService
      .createBinHistoryBoxOfficeExcel(this.page, this.count, 'BOOKED', 'DESC', this.mainForm.nativeElement)
      .subscribe(
        next => {
          downloadExcel(next, 'Історія_Заброньованих_Квитків_' + new Date().toISOString().slice(0, 10));
        },
        error => {
          console.error(error);
        },
      );
  }

  open(i) {
    this.page = i;
    this.loadBins();
  }

  changeCount(i) {
    this.count = i;
    this.page = 0;
    this.loadBins();
  }

  openCloseDetails(id: string, ids: string) {
    document.getElementById(id).classList.toggle('display-flex');
    document.getElementById(ids).classList.toggle('tranforms');
  }

  openClosePopUp(id: string, order?: string, one?: Event) {
    if (order)
      this._bookingService.findBinByOrderId(order).subscribe(
        next => {
          this.popUpMain.event = one;
          this.popUpMain.tickets = next;
        },
        error => {
          console.error(error);
          return;
        },
      );
    else this.popUpMain = new DataForPopUp();
    document.getElementById(id).classList.toggle('display-flex');
  }

  openCloseFilter(id: string) {
    document.getElementById(id).classList.toggle('display-inline-flex');
  }
}
