import { IDictionary } from 'src/shared/interfaces/interfaces';
import { Event } from './Event';
import { Ticket } from './Ticket';
import { ColorPrice } from './color-price';

export class TicketGroupWithOutSachem {
  id: number;
  lastModified: string;
  name: IDictionary;
  available: boolean;
  canBuy: boolean;
  comment: IDictionary;
  event: Event;
  tickets: Ticket[] = [];
  colorPrice: ColorPrice;
  ticketsForColorPriceCount?: number;
  order: number;
  dictionaryDto?: IDictionary;
}
