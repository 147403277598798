import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {


  transform(value: number, ...args): any {
    if (!value) {
      return "";
    }
    if (value < 60) {
      if (value < 10)
        return "0:0" + value;
      return "0:" + value;
    }
    else {
      if (value % 60 < 10)
        return ((value - value % 60) / 60) + ":0" + value % 60;
      return ((value - value % 60) / 60) + ":" + value % 60;
    }
  }
}
