<div class="form-wrapper">
  <form class="filter_container_form" #mainForm>
    <!-- search input -->
    <label style="width: 22%" class="search_svg_container">
      <svg class="search_svg" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.7272 17.0788L13.2881 12.4355C14.3889 11.1154 15.0091 9.45702 15.0463 7.73428C15.0835 6.01155 14.5354 4.32761 13.4925 2.96072C12.4497 1.59383 10.9746 0.625792 9.31089 0.216573C7.6472 -0.192646 5.89454 -0.0185567 4.34251 0.710073C2.79047 1.4387 1.53196 2.67827 0.774929 4.22393C0.0178962 5.7696 -0.192349 7.52887 0.178934 9.21103C0.550217 10.8932 1.48081 12.3976 2.81693 13.4756C4.15306 14.5536 5.81475 15.1407 7.52744 15.1399C9.0714 15.1441 10.5784 14.6651 11.8397 13.7692L16.3116 18.4454C16.4027 18.5421 16.5125 18.619 16.6342 18.6714C16.7559 18.7238 16.887 18.7505 17.0194 18.75C17.2117 18.7497 17.3997 18.6927 17.56 18.5859C17.7204 18.4791 17.846 18.3273 17.9214 18.1493C17.9968 17.9714 18.0186 17.775 17.984 17.5847C17.9495 17.3944 17.8602 17.2185 17.7272 17.0788ZM7.52744 1.96762C8.62794 1.96762 9.70374 2.29595 10.6188 2.9111C11.5338 3.52624 12.247 4.40056 12.6682 5.42351C13.0893 6.44645 13.1995 7.57207 12.9848 8.65802C12.7701 9.74398 12.2401 10.7415 11.462 11.5244C10.6838 12.3073 9.69233 12.8405 8.61297 13.0565C7.53361 13.2725 6.41482 13.1617 5.39808 12.738C4.38135 12.3142 3.51233 11.5967 2.90092 10.6761C2.28951 9.75545 1.96317 8.67309 1.96317 7.56586C1.96425 6.08145 2.55083 4.65815 3.5941 3.60851C4.63737 2.55888 6.05203 1.96871 7.52744 1.96762Z"
          fill="#C4C4C4"
        />
      </svg>
      <input
        type="text"
        class="{{ configSetting.color }}_main-input"
        #researcher
        timeInput
        [time]="500"
        (timeInput)="loadEventWithResearcher(researcher.value)"
        style="padding: 7px 15px; padding-left: 34px !important; color: #949494"
      />
    </label>
    <!-- date start -->
    <label style="width: 25%; margin-bottom: 0 !important">
      <input
        type="date"
        [(ngModel)]="filterDateFrom"
        [ngModelOptions]="{ standalone: true }"
        (change)="setDefaultDataToInput(false); loadEventWithFilterDate()"
        class="{{ configSetting.color }}_main-input"
        style="padding: 4.5px 15px; border: 0.5px solid #e5e5e5 !important"
      />
    </label>
    <!-- date end -->
    <label style="width: 25%; margin-bottom: 0 !important">
      <input
        type="date"
        [(ngModel)]="filterDateTo"
        [ngModelOptions]="{ standalone: true }"
        (change)="setDefaultDataToInput(true); loadEventWithFilterDate()"
        class="{{ configSetting.color }}_main-input"
        style="padding: 4.5px 15px; border: 0.5px solid #e5e5e5 !important"
      />
    </label>

    <select class="event-status" style="width: 19%" (change)="onEventStatusChange($event)">
      <option disabled>Статус події</option>
      <option value="ALL">Актуальні</option>
      <option value="ARCHIVE">Архів</option>
    </select>
    <!-- reset filter button -->
    <label style="width: 19%; margin-bottom: 0 !important" class="label_btn label_btn_reset" (click)="resetFilter()">
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.8782 5.42088L14.3242 2.73345C14.1549 2.4232 13.7894 2.261 13.4062 2.40264L11.6502 3.10814C11.3166 2.86802 10.966 2.66402 10.6033 2.50039L10.3362 0.6375C10.2909 0.274125 9.97572 0 9.60311 0H6.48643C6.11386 0 5.79863 0.274125 5.75401 0.631125L5.48626 2.4997C5.13493 2.65907 4.78995 2.86025 4.44145 3.10814L2.68059 2.40055C2.34202 2.27023 1.93827 2.41686 1.76966 2.72498L0.212737 5.41732C0.0257369 5.73464 0.102934 6.1462 0.393362 6.3757L1.88156 7.53877C1.85818 7.76332 1.84686 7.95952 1.84686 8.14582C1.84686 8.33212 1.85818 8.52832 1.88086 8.75288L0.391934 9.91668C0.107881 10.1412 0.0299869 10.5393 0.211309 10.8708L1.76538 13.5589C1.93468 13.8685 2.30088 14.0307 2.68408 13.889L4.44003 13.1835C4.77365 13.4236 5.12428 13.6276 5.48695 13.7912L5.75401 15.6534C5.79863 16.0175 6.11383 16.2916 6.48643 16.2916H8.92736C9.05981 16.2916 9.18024 16.218 9.24117 16.1011C9.30209 15.9842 9.29286 15.8433 9.21779 15.7349C8.67591 14.9586 8.38904 14.0483 8.38904 13.1041C8.38904 12.6636 8.45349 12.2223 8.58099 11.7923C8.61356 11.6818 8.59091 11.5628 8.51936 11.4721C8.44781 11.3822 8.33804 11.334 8.22256 11.3376C8.18431 11.3397 8.14676 11.3446 8.05043 11.3567C6.28881 11.3567 4.85586 9.92375 4.85586 8.16213C4.85586 6.4005 6.28881 4.96682 8.05043 4.96682C9.81206 4.96682 11.245 6.39977 11.2386 8.2202C11.2337 8.25775 11.2287 8.29527 11.2266 8.33352C11.2209 8.44897 11.2712 8.55947 11.3612 8.63032C11.4518 8.70187 11.5715 8.72313 11.6813 8.69195C12.3705 8.48652 13.0498 8.43764 13.8191 8.5772C13.9133 8.5942 14.0167 8.5517 14.096 8.49289C14.1747 8.43409 14.2271 8.32502 14.2377 8.22727C14.2391 8.21452 14.2427 8.15714 14.2427 8.14439C14.2427 7.92409 14.2271 7.71657 14.2087 7.53734L15.6969 6.375C15.981 6.15118 16.0589 5.75307 15.8782 5.42088Z"
          fill="#72002D"
        />
      </svg>
      <span class="calendar_text">{{ 'events.reset_filter' | translate }}</span>
    </label>
  </form>
</div>

<div class="events-wrapper">
  <ng-container *ngFor="let event of events?.content">
    <app-event-record [eventItem]="event" displayMode="extended"></app-event-record>
  </ng-container>
</div>

<div *ngIf="events?.content" class="container_pageable">
  <app-pageable [page]="events" (countOnPage)="changeCount($event)" (openPage)="openPage($event)" [range]="6"></app-pageable>
</div>
