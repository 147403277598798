<div class="container">
  <div class="box-container">
    <div class="box-name">Каса</div>
    <div class="box-value">Квитки(шт)</div>
    <div class="box-value">Сума</div>
  </div>
  <div class="seller-container">
    <h4>сайт</h4>
    <div class="box-container">
      <div class="box-name">SOLDOUT.COM.UA</div>
      <div class="box-value">{{onlineStatistics?.onlineCount}}</div>
      <div class="box-value">{{onlineStatistics?.onlinePrice}}</div>
    </div>
  </div>
  <ng-container *ngIf="sellersStatistics">
    <ng-container *ngFor="let seller of sellersStatistics">
      <div class="seller-container">
        <h4>{{seller?.name?.valueUA}}</h4>
        <ng-container *ngFor="let boxOffice of seller?.boxOffices">
          <div class="box-container">
            <div class="box-name">{{boxOffice?.name?.valueUA}}</div>
            <div class="box-value">{{boxOffice?.ticketCount}}</div>
            <div class="box-value">{{boxOffice?.nominalPrice}}</div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
