import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { openCloseElementBlock, openCloseElementFlex } from '../../../../../../shared/service/utils/export-functions';
import { IBin } from '../../event-action/event-action.component';

@Component({
  selector: 'app-event-bin',
  templateUrl: './event-bin.component.html',
  styleUrls: ['./event-bin.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventBinComponent {
  @Input() currentBin: IBin;
  @Input() lang: string;
  @Input() isActivePromocode: boolean;

  @Output() removeTicket: EventEmitter<number> = new EventEmitter();
  @Output() binSave: EventEmitter<void> = new EventEmitter();
  @Output() onUserPromoCode: EventEmitter<string> = new EventEmitter();

  promoCode = '';
  promoCodePrice = 0;

  openCloseBinFlex(el: HTMLElement) {
    openCloseElementFlex(el);
  }

  usePromoCode() {
    if (this.promoCode) {
      this.onUserPromoCode.emit(this.promoCode);
    }
  }

  removePromoCode() {
    this.promoCode = '';
    this.promoCodePrice = 0;
  }

  expandBin: (el: HTMLElement) => void = openCloseElementBlock;

  trackById(index, obj): number | string {
    return obj.id;
  }

  deleteTicketFromBin(binTicket): void {
    this.removeTicket.emit(binTicket);
  }

  saveBin(): void {
    this.binSave.emit();
  }
}
