import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BoxOfficeEventDto } from '../../../../../../shared/models/dto/BoxOfficeEventDto';
import { MainSettingConfig, MainSettingsService } from '../../../../../../shared/service/rx/main-settings.service';
import { AbonementService } from 'src/shared/service/backend/abonement.service';

@Component({
  selector: 'app-event-one-small',
  templateUrl: './event-one-small.component.html',
  styleUrls: ['./event-one-small.component.css'],
})
export class EventOneSmallComponent implements OnInit {
  @Output() moreInfo = new EventEmitter();
  @Input() eventData: BoxOfficeEventDto;

  lang: string;
  mainSettingConfig: MainSettingConfig;

  constructor(
    private _translateService: TranslateService,
    private _mainSettingsService: MainSettingsService,
    private abonementService: AbonementService,
  ) {
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(
      next => {
        this.lang = next.lang;
      },
      error => {
        console.error(error);
      },
    );
    this._mainSettingsService.settingConfig$.subscribe(
      next => {
        this.mainSettingConfig = next;
      },
      error => {
        console.error(error);
      },
    );
    this.mainSettingConfig = this._mainSettingsService.getConfig();
  }

  ngOnInit() {}

  useAbonement() {
    const a = prompt('Введіть абонемент код').replace(/ /g, '');
    if (a) {
      this.abonementService.getAllAbonementsByAbonementCodeDetailed(a).subscribe(
        res => {
          if (!(res && res.abonementShortDtoList.length && res.abonementShortDtoList[0])) {
            alert('Абонемент-код невалідний');
          } else if (!res.abonementShortDtoList.filter(el => !el.used).length) {
            alert('Абонемент-код використано');
          } else {
            const notUsedAbonements = res.abonementShortDtoList.filter(el => !el.used);
            window.location.href = `${window.location.origin}/#/buy/${notUsedAbonements[0].event.id}?abonement=${res.binId}`;
          }
        },
        err => {
          alert('Абонемент-код невалідний');
        },
      );
    }
  }

  openMoreInfo() {
    this.moreInfo.emit(this.eventData.id);
  }

  openHover(id: string) {
    document.getElementById(id).classList.add('display-flex');
  }

  closeHover(id: string) {
    document.getElementById(id).classList.remove('display-flex');
  }
}
