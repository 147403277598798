<div class="container" *ngIf="mainSettingConfig && mainSettingConfig.pageable == 'STANDART'">
  <span>
    <button class="nav-first" [ngClass]="{ disabled: page.first }" (click)="open(page.number - 1)"><</button>
  </span>
  <div>
    <span [ngClass]="{ disabled: page.number < range + 1 }">
      <button (click)="open(0)">1</button>
    </span>
    <span [ngClass]="{ disabled: page.number < range + 1 }">...</span>
    <span *ngFor="let one of generate()" (click)="open(one)" [ngClass]="{ active: page.number == one, disabled: !disabled(one) }">
      <button>{{ one + 1 }}</button>
    </span>
    <span [ngClass]="{ disabled: page.number + 2 * range > page.totalPages }">...</span>
    <span [ngClass]="{ disabled: page.number + 2 * range > page.totalPages }">
      <button (click)="open(page.totalPages - 1)">{{ page.totalPages }}</button>
    </span>
  </div>
  <button class="nav-last" [ngClass]="{ disabled: page.last }" (click)="open(page.number + 1)">></button>
</div>
<div class="container" *ngIf="mainSettingConfig && mainSettingConfig.pageable == 'MODERN'">
  <span class="modern_text">{{ 'page.pages' | translate }}:</span>
  <label>
    <select class="modern_select" (change)="count($event)">
      <option *ngFor="let one of generateCountInPage()" [value]="one" [selected]="page.size == one">{{ one }}</option>
    </select>
  </label>
  <span class="modern_text">{{ 'page.goTo' | translate }}:</span>
  <label>
    <input
      class="modern_input"
      style="width: 50px"
      (timeInput)="onOpenPage($event)"
      type="number"
      max="{{ page.totalPages }}"
      value="{{ page.number + 1 }}"
      [time]="500"
    />
  </label>
  <span class="pointer" (click)="open(page.number - 1)" *ngIf="!page.first"><</span>
  <span class="modern_text" style="color: #000; font-weight: bold">
    {{ page.number * page.size + 1 }} - {{ page.number * page.size + page.numberOfElements }} {{ 'page.from' | translate }}
    {{ page.totalElements }}
  </span>
  <span class="pointer" (click)="open(page.number + 1)" *ngIf="!page.last">></span>
</div>
