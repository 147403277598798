import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientComponent } from './client/client.component';
import { SideBarComponent } from './client-source/side-bar/side-bar.component';
import { GlobalImportModule } from '../global-import.module';
import { ReturnedTicketsOneComponent } from './client/returned-tickets/returned-tickets-one/returned-tickets-one.component';
import { EventsComponent } from './client/events/events.component';
import { EventContainerComponent } from './client/events/event-container/event-container.component';
import { EventOneSmallComponent } from './client/events/event-container/event-one-small/event-one-small.component';
import { EventOneBigComponent } from './client/events/event-container/event-one-big/event-one-big.component';
import { ReturnedTicketsComponent } from './client/returned-tickets/returned-tickets.component';
import { EventBuyComponent } from './client/events/event-buy/event-buy.component';
import { PlatformComponent } from './client/platform/platform.component';
import { PlatformEventsOneComponent } from './client/platform/platform-events-one/platform-events-one.component';
import { InviteComponent } from './client/invite/invite.component';
import { TranslateModule } from '@ngx-translate/core';

import { InviteContainerComponent } from './client/invite/invite-container/invite-container.component';
import { InviteContainerOneComponent } from './client/invite/invite-container-one/invite-container-one.component';
import { ReserveComponent } from './client/reserve/reserve.component';

import { ReserveContainerComponent } from './client/reserve/reserve-container/reserve-container.component';
import { ReserveContainerOneComponent } from './client/reserve/reserve-container-one/reserve-container-one.component';
import { SettingsComponent } from './client-source/settings/settings.component';
import { HistoryComponent } from './client/history/history.component';
import { HistoryContainerComponent } from './client/history/history-container/history-container.component';
import { CollectionComponent } from './client/collection/collection.component';
import { CollectionContainerComponent } from './client/collection/collection-container/collection-container.component';
import { HistoryBookedContainerComponent } from './client/history/history-booked-container/history-booked-container.component';
import { HistoryReturnTicketContainerComponent } from './client/history/history-return-ticket-container/history-return-ticket-container.component';
import { PageableComponent } from '../source/pageable/pageable.component';
import { HistoryBookedCanceledComponent } from './client/history/history-booked-canceled/history-booked-canceled.component';
import { ReportsWrapperComponent, CgDailyReviewComponent, CgReportsArchiveComponent } from './client/reports/components';
import { ReportsCashierPage, ReportsAccountantPage } from './client/reports/pages';
import { AgentsPage } from './client/agents/pages/agents-page/agents.page';
import { AgentAddComponent } from './client/agents/components/agent-add/agent-add.component';
import { AgentsListComponent } from './client/agents/components/agents-list/agents-list.component';
import { AntModule } from './ant.module';
import { TicketsModalComponent } from './client/agents/components/tickets-modal/tickets-modal.component';
import { EventHallComponent } from './client/events/components/event-hall/event-hall.component';
import { EventNoSchemaComponent } from './client/shared/components/event-no-schema/event-no-schema.component';
import { VendorBinComponent } from './client/events/components/vendor-bin/vendor-bin.component';
import { EventLockListComponent } from './client/events/event-lock-list/event-lock-list.component';
import { EventInvitationListComponent } from './client/events/event-invitation-list/event-invitation-list.component';
import { ReportTypeModalComponent } from './client/reports/components/report-type-modal/report-type-modal.component';
import { ReportsByEventComponent } from './client/reports/pages/reports-by-event/reports-by-event.component';
import { PriceLegendComponent } from './client/events/components/price-legend/price-legend.component';
import { EventActionComponent } from './client/events/event-action/event-action.component';
import { EventBinComponent } from './client/events/components/event-bin/event-bin.component';
import {
  AccountantWrapperComponent,
  EventStatisticComponent,
  ReportsComponent,
  SailsByMonthComponent,
  EventDetailsComponent,
  EventGeneralComponent,
  EventBySellersComponent,
  EventByReportsComponent
} from './client/accountant/pages';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventRecordComponent } from './client/accountant/components/event-record/event-record.component';

@NgModule({
  imports: [
    CommonModule,
    GlobalImportModule,
    TranslateModule,
    AntModule,
    NgSelectModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    ClientComponent,
    SideBarComponent,
    ReturnedTicketsComponent,
    ReturnedTicketsOneComponent,
    EventsComponent,
    EventContainerComponent,
    EventOneSmallComponent,
    EventOneBigComponent,
    EventBuyComponent,
    PlatformComponent,
    PlatformEventsOneComponent,
    InviteComponent,
    InviteContainerComponent,
    InviteContainerOneComponent,
    ReserveComponent,
    ReserveContainerComponent,
    ReserveContainerOneComponent,
    SettingsComponent,
    HistoryComponent,
    HistoryContainerComponent,
    CollectionComponent,
    CollectionContainerComponent,
    HistoryBookedContainerComponent,
    HistoryReturnTicketContainerComponent,
    PageableComponent,
    HistoryBookedCanceledComponent,
    ReportsWrapperComponent,
    ReportsCashierPage,
    ReportsAccountantPage,
    CgDailyReviewComponent,
    CgReportsArchiveComponent,
    AgentsPage,
    AgentAddComponent,
    AgentsListComponent,
    TicketsModalComponent,
    EventHallComponent,
    EventNoSchemaComponent,
    VendorBinComponent,
    EventLockListComponent,
    EventInvitationListComponent,
    ReportTypeModalComponent,
    ReportsByEventComponent,
    PriceLegendComponent,
    EventActionComponent,
    EventBinComponent,
    AccountantWrapperComponent,
    EventStatisticComponent,
    ReportsComponent,
    SailsByMonthComponent,
    EventDetailsComponent,
    EventGeneralComponent,
    EventBySellersComponent,
    EventByReportsComponent,
    EventRecordComponent,
  ]
})
export class ClientModule {
}
