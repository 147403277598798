import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFinishAbonementBinPayload } from 'src/app/client/client/events/event-buy/event-buy.component';
import { environment } from 'src/environments/environment';
import { ColorPrice } from 'src/shared/models/color-price';
import { ClientEventOneBaseDataDto } from 'src/shared/models/dto/ClientEventOneBaseDataDto';

export interface IAbonement {
  colorPrice: ColorPrice;
  event: ClientEventOneBaseDataDto;
  id: number;
  used: boolean;
  abonementCode: string;
}

export interface IAbonementColorPrice {
  abonementPrice: number;
  colorPrice: {
    color: string;
    id: number;
  };
  event: ClientEventOneBaseDataDto;
  eventsCount: number;
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class AbonementService {
  constructor(private http: HttpClient) {}

  getAllAbonementsByAbonementCodeDetailed(abonementCode: string) {
    return this.http.get<{ abonementShortDtoList: IAbonement[]; binId: number }>(
      `/abonements/get-all-abonements/by-abonement-code-detailed?code=${abonementCode}`,
    );
  }

  getAbonementsByBin(binId: string): Observable<IAbonement[]> {
    return this.http.get<IAbonement[]>(`/abonements/by-bin/${binId}?retry=true`, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  }

  getActiveAbonementsByAbonementCode(abonementCode: string, eventId: string): Observable<IAbonement[]> {
    return this.http.get<IAbonement>(`/abonements/by-abonement-code?eventId=${eventId}&code=${abonementCode}`).pipe(map(res => [res]));
  }

  finishAbonementBin(binInfo: IFinishAbonementBinPayload[]): Observable<any> {
    return this.http.post(
      `/abonements/finish-bin-updates`,
      binInfo.filter(el => el.binId),
    );
  }
  printTickets(binIds: number[], userId: number) {
    const query = binIds.map(binId => `binId=${binId}&`).join('') + `userId=${userId}`;

    window.open(`${environment.apiUrl}/main-service/pdf-user/generate-pdf-a4-by-bin-file-path?${query}`);
  }

  getAbonementPrice(eventId: number) {
    return this.http.get<IAbonementColorPrice[]>(`/abonement-price/get-abonement-price?eventId=${eventId}`);
  }

  setSelectedAdditionalEvents(eventIdList: number[], ticketId: number) {
    return this.http.post(`/abonements/select-ticket-events?eventIdList=${eventIdList}&ticketId=${ticketId}`, null);
  }
}
