import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimerInputDirective} from './input/timer-input.directive';
import {AutoFocusDirective} from './autofocus/auto-focus.directive';
import {ValueByLang} from './valueByLang/valueByLang';
import { ValidEmailDirective } from './valid-email/valid-email.directive';


@NgModule({
  exports: [
    TimerInputDirective,
    AutoFocusDirective,
    ValueByLang,
    ValidEmailDirective
  ],
  imports: [
    // GlobalImportModule
  ],
  declarations: [
    TimerInputDirective,
    AutoFocusDirective,
    ValueByLang,
    ValidEmailDirective
  ]
})
export class DirectiveModule {
}
