import { City } from './City';
import { IDictionary } from 'src/shared/interfaces/interfaces';
import { ImageD } from './User';

export class PlatformHall {
  id: number;
  lastModified: string;
  image: ImageD;
  city: City;
  address: IDictionary;
  name: IDictionary;
  shortName: IDictionary;
  phone: string;
  site: string;
  available: boolean;
  lat: string;
  lng: string;
}
