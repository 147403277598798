import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { MainLoaderService } from './rx/main-loader.service';
import { OnlineService } from './backend/online.service';
import { UserWithBoxOfficeDto } from '../models/dto/UserWithBoxOfficeDto';
import { IAuthTokenData } from './backend/sign.in.service';

@Injectable({ providedIn: 'root' })
export class UserDetailsService {
  public user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();
  public isAuth: boolean = false;
  public isAdmin: boolean = false;
  public currentUser$ = new Subject<UserWithBoxOfficeDto>();
  user$ = this.currentUser$.asObservable();
  private _isAuth = new Subject<boolean>();
  isAuth$ = this._isAuth.asObservable();

  public service: boolean = true;
  private _service = new Subject<boolean>();
  service$ = this._service.asObservable();

  private pingInfo: boolean = false;

  private get accessToken() {
    return localStorage.getItem('access_token');
  }

  private get role() {
    return localStorage.getItem('ROLE');
  }

  getPingInfo(): boolean {
    return localStorage.getItem('ping') == 'true';
  }

  getTimes(value) {
    if (value < 60) {
      if (value < 10) return '0:0' + value;
      return '0:' + value;
    } else {
      if (value % 60 < 10) return (value - (value % 60)) / 60 + ':0' + (value % 60);
      return (value - (value % 60)) / 60 + ':' + (value % 60);
    }
  }

  constructor(private _router: Router, private _mainLoaderService: MainLoaderService, private _onlineService: OnlineService) {
    if (this.checkAuth()) this.ping();
  }

  time: Date;

  ping() {
    if (this.checkAuth() && !this.pingInfo) {
      this.pingInfo = true;
      this._onlineService.ping().subscribe(
        next => {
          setTimeout(() => {
            if (!this.service) {
              this._mainLoaderService.endLoader();
              // this._sideInfoRxService.add(new SideInfo().setInfo('зєднання відновлено через '+this.getTimes(((new Date().getTime()-this.time.getTime())/1000).toFixed(0))+"     ").setDate());
              this.service = true;
              this._service.next(this.service);
            }
            this.pingInfo = false;
            if (this.checkAuth()) this.ping();
          }, 30000);
        },
        error => {
          this._mainLoaderService.endLoader();
          if (this.service) {
            this.time = new Date();
            this.service = false;
            this._service.next(this.service);
            // this._sideInfoRxService.add(new SideInfo().setError(error).setInfo('немає зєдняння з сервісом, перевірте зєднання з інтернетом або зачекайте декілька хвилин').setDate());
          } else {
            // this._sideInfoRxService.add(new SideInfo().setError(error).setInfo("зєднання відсутнє "+this.getTimes(((new Date().getTime()-this.time.getTime())/1000).toFixed(0))+"      ").setDate());
          }
          setTimeout(() => {
            if (!this.service) {
              this.pingInfo = false;
              this.ping();
            }
          }, 30000);
          console.error(error);
        },
      );
    }
  }

  public login(user: UserWithBoxOfficeDto) {
    console.log(user);
    if (this.isAuth) return;
    this.user = user;
    localStorage.setItem('ROLE', user.role);
    localStorage.setItem('user', JSON.stringify(this.user));
    this.currentUser$.next(this.user);
    this.isAuth = true;
    this._isAuth.next(this.isAuth);
    this.isAdmin = user.role.toUpperCase() == 'ADMIN';
    // }
    if (this.checkAuth() && !this.pingInfo) this.ping();
  }

  public logout(redirect: boolean = true) {
    // if (isPlatformBrowser(this.platformId)) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_details');
    localStorage.removeItem('user');
    this.user = new UserWithBoxOfficeDto();
    // this.user.role = '';
    this.isAuth = false;
    localStorage.removeItem('ROLE');
    this.isAdmin = false;
    this.currentUser$.next(this.user);
    this._isAuth.next(this.isAuth);
    for (let key in localStorage) {
      if (key.includes('order')) {
        localStorage.removeItem(key);
      } else if (key.includes('time')) {
        localStorage.removeItem(key);
      }
    }
    if (redirect) {
      this._router.navigateByUrl('/sign-in');
    }
    // }
  }

  getUser(): UserWithBoxOfficeDto {
    return <UserWithBoxOfficeDto>JSON.parse(localStorage.getItem('user'));
  }

  checkAuth(): boolean {
    return !!this.accessToken;
  }

  tokenParseInLocalStorage(data: IAuthTokenData): void {
    Object.keys(data).forEach(key =>
      localStorage.setItem(key, key !== 'expires_in' ? data[key] : new Date().setSeconds(data.expires_in).toString()),
    );
  }

  checkPermissionAdmin(role: string): boolean {
    if (!this.role) {
      return false;
    }
    return localStorage.getItem('ROLE') == role;
  }
}
