import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'localdate' })
export class LocalDataTimeToDate implements PipeTransform {


  transform(value: string, ...args): any {
    return !value ? '' : new Date(value).toLocaleString().substring(0, 10).replace("-", ".").replace("-", ".").replace("T", " ");
  }
}
