import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';

import { Url } from '../config/url';

export class WebSocketData {
  seatId: number;
  eventId: number;
  colorPrice: number;
  ticketId: number;
  seatKey: string;
  fanzone: boolean;
  order: string;
}

@Injectable({ providedIn: 'root' })
export class WebSocketService {
  private serverUrl = Url.ws;
  private stompClient: Stomp;

  private _lockWebSocketData = new Subject<WebSocketData>();
  lockWebSocketData$ = this._lockWebSocketData.asObservable();

  private _unLockWebSocketData = new Subject<WebSocketData>();
  unLockWebSocketData$ = this._unLockWebSocketData.asObservable();

  private idEvent: number[] = [];

  initializeWebSocketConnection(id: number) {
    this.idEvent.push(id);
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    this.stompClient.debug = null;
    this.stompClient.connect('rabbitMqUser', 'rabbitMqUser', frame => {
      this.stompClient.subscribe('/topic/locker.' + id, message => {
        this._lockWebSocketData.next(JSON.parse(message.body));
      });
      this.stompClient.subscribe('/topic/un-locker.' + id, message => {
        this._unLockWebSocketData.next(JSON.parse(message.body));
      });
    });
  }

  disconnect() {
    if (this.stompClient != null) {
      try {
        this._lockWebSocketData = new Subject<WebSocketData>();
        this.lockWebSocketData$ = this._lockWebSocketData.asObservable();
        this._unLockWebSocketData = new Subject<WebSocketData>();
        this.unLockWebSocketData$ = this._unLockWebSocketData.asObservable();
        this.stompClient.disconnect();
        this.idEvent.forEach(value => {
          this.stompClient.unsubscribe('/topic/locker.' + value);
          this.stompClient.unsubscribe('/topic/un-locker.' + value);
        });
        this.idEvent = [];
      } catch (e) {
        console.error(e);
      }
    }
  }
}
