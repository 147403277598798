import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { NzModalService } from 'ng-zorro-antd/modal';

import { UserWithBoxOfficeDto } from '../../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { UserDetailsService } from '../../../../../../shared/service/user-details-service.service';
import {
  AddVendorOptions,
  Vendor,
  VendorBuyBinOptions,
  VendorOrderBuyPrintOptions,
  VendorOrderEditOptions,
} from '../../../../../../shared/types';
import { VendorsService } from '../../../../../../shared/service/backend/vendors.service';
import { Ticket } from '../../../../../../shared/models/Ticket';
import { VendorOrderTicketModal } from '../../../../../../shared/models/Vendor';
import { ReportsService } from '../../../../../../shared/service/backend/reports.service';
import { IBinTicket } from '../../../events/event-action/event-action.component';

@Component({
  selector: 'app-agents-page',
  templateUrl: './agents.page.html',
  styleUrls: ['./agents.page.css'],
})
export class AgentsPage implements OnInit, OnDestroy {
  user: UserWithBoxOfficeDto;
  vendors: (Vendor & { expand?: boolean; binStatus?: 'ACTIVE' | 'ARCHIVE'; currentPage?: number })[];
  selectedVendor: number;
  isActionsModalVisible: boolean = false;
  tickets: IBinTicket[];
  modalEventName: string;
  simpleTicketsTable: boolean = false;
  modalAction: string;
  currentBinId: number;
  currentEventId: number;
  isLoading: boolean = false;
  pagination: { currentPage: number; totalElements: number; paginationSize: number } = {
    currentPage: 1,
    totalElements: 20,
    paginationSize: 20,
  };

  get modalWidth(): number {
    return this.simpleTicketsTable ? 700 : 800;
  }

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private userDetailsService: UserDetailsService,
    private vendorsService: VendorsService,
    private router: Router,
    private modalService: NzModalService,
    private reportsService: ReportsService,
  ) {}

  ngOnInit(): void {
    this.user = this.userDetailsService.getUser();
    this.getVendors(1);
    this.selectedVendor = history.state?.data?.vendorId ?? 0;
  }

  getVendorBins(expandEvent: { vendorId: number; isExpanded: boolean; page: number; binStatus: 'ARCHIVE' | 'ACTIVE' }) {
    this.vendors.forEach(el => (el.id === expandEvent.vendorId ? (el.expand = expandEvent.isExpanded) : ''));
    if (expandEvent.isExpanded) {
      this.vendorsService.getVendorsBins(expandEvent.vendorId, expandEvent.page, expandEvent.binStatus).subscribe(res => {
        this.vendors = this.vendors.map(el =>
          el.id === expandEvent.vendorId
            ? {
                ...el,
                bins: res.content,
                expand: true,
                binStatus: el.id === expandEvent.vendorId ? expandEvent.binStatus : 'ACTIVE',
                currentPage: (res as any).number + 1 || 1,
                totalElements: res.totalElements,
              }
            : el,
        );
      });
    }
  }
  pageExpandedChange(expandEvent: { vendorId: number; page: number; binStatus: 'ARCHIVE' | 'ACTIVE' }) {
    this.vendors.forEach(el => {
      if (el.id === expandEvent.vendorId) {
        this.vendorsService.getVendorsBins(expandEvent.vendorId, expandEvent.page - 1, expandEvent.binStatus).subscribe(res => {
          el.currentPage = (res as any).number;
          el.bins = res.content;
        });
      }
    });
  }

  onAddAgent(vendor: AddVendorOptions): void {
    this.vendorsService
      .createVendor(vendor)
      .pipe(take(1))
      .subscribe(() => this.getVendors(this.pagination.currentPage - 1));
  }

  onTicketsModalClose(): void {
    this.simpleTicketsTable = false;
    this.isActionsModalVisible = false;
    this.modalEventName = '';
    this.modalAction = '';
    this.currentBinId = null;
    this.currentEventId = null;
  }

  onActionsModalShow(options: VendorOrderTicketModal): void {
    this.vendorsService.getVendorBinDetails(options.order).subscribe(res => {
      this.simpleTicketsTable = options?.isSimplePreview ?? false;
      this.modalEventName = res?.event?.name?.valueUA;
      this.tickets = res?.ticketList;
      this.isActionsModalVisible = true;
      this.modalAction = options.actionMode;
      this.currentBinId = res.id;
      this.currentEventId = res.event?.id;
    });
  }

  onOrderRemove(orderId: string): void {
    this.modalService.confirm({
      nzTitle: 'Ви впевнені що хочете видалити замовлення?',
      nzOkText: 'Підтвердити',
      nzCancelText: 'Скасувати',
      nzOnOk: () => this.deleteOrder(orderId),
    });
  }

  onOrderBuy(options: VendorBuyBinOptions): void {
    this.modalService.confirm({
      nzTitle: 'Ви підтверджуєте оплату замовлення?',
      nzOkText: 'Підтвердити',
      nzCancelText: 'Скасувати',
      nzOnOk: () => this.buyOrder(options),
    });
  }

  onOrderEdit(options: VendorOrderEditOptions): void {
    const { eventId, vendor, binId } = options;

    this.router.navigate([`/order/${eventId}`], {
      queryParams: { vendor, binId },
    });
  }

  onOrderBuyDocumentPrint(options: VendorOrderBuyPrintOptions): void {
    const fullOptions = {
      ...options,
      cashier: this.user.id,
      reason: '',
      numberInvoice: '',
    };

    if (fullOptions.isNew) {
      this.reportsService.newLoadKG7report(fullOptions).subscribe();
    } else {
      this.reportsService.loadKG7report(fullOptions).subscribe();
    }
  }

  private buyOrder(options: VendorBuyBinOptions): void {
    this.vendorsService.buyBinByVendor(options).subscribe(() => this.getVendors(1));
  }

  getVendors(page: number): void {
    this.isActionsModalVisible = false;
    this.isLoading = true;
    this.vendorsService.getVendorsDetailed(page - 1).subscribe(
      res => {
        this.isLoading = false;
        this.vendors = res.content;
        this.pagination = {
          currentPage: res.number + 1 || 1,
          totalElements: res.totalElements,
          paginationSize: res.size,
        };
      },
      error => (this.isLoading = false),
    );
  }

  onVendorEdit(vendorOptions: Partial<Vendor>): void {
    const { id, fullName, phone } = vendorOptions;
    this.vendorsService
      .update({ fullName, phone }, id)
      .pipe(take(1))
      .subscribe(() => this.getVendors(this.pagination.currentPage - 1));
  }

  private deleteOrder(orderId: string): void {
    this.vendorsService.removeOrderByBin(orderId).subscribe(() => this.getVendors(this.pagination.currentPage - 1));
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
