import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'localtime' })
export class LocaldatatimeToTimePipe implements PipeTransform {


  transform(value: string, ...args): any {
    return !value ? '' : value.substring(11, 16);
  }
}
