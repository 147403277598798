export function printPdf(url: string): void {
  let iframe;
  iframe = document.createElement('iframe');
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.src = url;
  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
}
export function downloadPdf(url: string, filename: string, addIndex?: number): void {
  const aElement = document.createElement('a');

  aElement.setAttribute('download', addIndex ? filename + '_' + addIndex : prompt('Введіть назву файлу') || filename);
  aElement.href = url;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(url);
}
