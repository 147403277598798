import {Row} from './Row';

export class Seat {
  id: number;
  lastModified: string;
  key: string;
  name: string;
  row: Row;
  fanzone: boolean;
  count: number;
  constructor(id?:number,key?:string){
    this.id=id;
    this.key=key;
  }
}
