import { Component, OnInit } from '@angular/core';
import { take, tap } from 'rxjs/operators';

import { MainLoaderService } from '../../../../../../shared/service/rx/main-loader.service';
import { ReportsService } from '../../../../../../shared/service/backend/reports.service';
import { PaymentType } from '../../../../../../shared/models/enums/enums';
import { SellKGReportOptions } from '../../../../../../shared/types';
import { UserDetailsService } from '../../../../../../shared/service/user-details-service.service';

@Component({
  selector: 'app-reports-cashier',
  templateUrl: './reports-cashier.page.html',
  styleUrls: ['./reports-cashier.page.css'],
})
export class ReportsCashierPage implements OnInit {
  public readonly statisticRows: string[] = ['Власні', 'Чужі', 'Разом'];

  public dateFormat: string = 'dd-MM-yyyy';
  public dateRange: Date[] = [new Date(), new Date()];
  public eventType: boolean | null = null;
  public paymentType: PaymentType = null;
  public isVendor: boolean = true;
  public isReverted: boolean = true;
  public isRevertCounted: boolean = false;
  public kgPreviewData: any;
  public sellsPreviewData: any;

  private userId: number;

  constructor(
    private reportsService: ReportsService,
    private mainLoader: MainLoaderService,
    private userDetailsService: UserDetailsService,
  ) {}

  public ngOnInit(): void {
    this.userId = this.userDetailsService.getUser().id;
    this.loadKGTableData();
    this.loadSellsData();
  }

  public loadReport(): void {
    const options: SellKGReportOptions = this.getApiOptions();
    this.reportsService
      .newLoadCGReport(options)
      .pipe(tap(() => this.mainLoader.startLoader()))
      .subscribe(() => this.mainLoader.endLoader());
  }

  private getApiOptions(): SellKGReportOptions {
    const apiOptions: SellKGReportOptions = {
      cashierId: this.userId,
      dateFrom: this.formatDateForApi(this.dateRange[0]),
      dateTo: this.formatDateForApi(this.dateRange[1], true),
      isVendor: this.isVendor,
      isReverted: this.isReverted,
      isRevertCounted: this.isRevertCounted,
    };

    if (this.eventType !== null) {
      apiOptions.ownEventsOnly = this.eventType;
    }

    if (this.paymentType !== null) {
      apiOptions.typeOfPayment = this.paymentType;
    }

    return apiOptions;
  }

  private formatDateForApi(date: Date, endDay: boolean = false): string {
    let [day, month, year] = date.toLocaleDateString().split('.');
    return endDay ? `${year}-${month}-${day}T23:59` : `${year}-${month}-${day}T00:00`;
  }

  public loadKGTableData(): void {
    const options: SellKGReportOptions = this.getApiOptions();

    this.reportsService.sellKGReport(options).subscribe(data => {
      this.kgPreviewData = data;
      if (data[2]) {
        data[2].allStatistic.sum = data[0].allStatistic.sum + data[1].allStatistic.sum;
        data[2].allStatistic.count = data[0].allStatistic.count + data[1].allStatistic.count;
      }
      console.log(data);
    });
  }

  public loadSellsData(): void {
    const options: SellKGReportOptions = this.getApiOptions();

    this.reportsService
      .sellDataReport(options)
      .pipe(take(1))
      .subscribe(data => (this.sellsPreviewData = data));
  }
}
