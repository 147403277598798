import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { HallService } from '../../../../shared/service/backend/hall.service';
import { PlatformHall } from '../../../../shared/models/PlatformHall';
import { City } from '../../../../shared/models/City';
import { Category } from '../../../../shared/models/Category';
import { CategoryService } from '../../../../shared/service/backend/category.service';
import { LocationService } from '../../../../shared/service/backend/location.service';
import { UserDetailsService } from '../../../../shared/service/user-details-service.service';
import { Page } from '../../../../shared/models/Page';
import { BookingService } from '../../../../shared/service/backend/booking.service';
import { UserWithBoxOfficeDto } from '../../../../shared/models/dto/UserWithBoxOfficeDto';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.css'],
})
export class PlatformComponent {
  @ViewChild('mainFilterForm') mainFilterForm: ElementRef;

  platforms: Page<PlatformHall>;

  allCities: City[] = [];
  categories: Category[] = [];
  platformHalls: PlatformHall[] = [];

  lang: string;
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  page: number = 0;
  count: number = 10;

  inputTimeout;

  constructor(
    private _hallService: HallService,
    private _categoryService: CategoryService,
    private _locationService: LocationService,
    private _translateService: TranslateService,
    private _userDetailService: UserDetailsService,
    private _bookingService: BookingService,
  ) {
    this.user = this._userDetailService.getUser();
    this._userDetailService.user$.subscribe(
      next => {
        this.user = next;
      },
      error => {
        console.error(error);
      },
    );
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(
      next => {
        this.lang = next.lang;
      },
      error => {
        console.error(error);
      },
    );
    this._categoryService.findAll().subscribe(
      next => {
        this.categories = next;
      },
      error => {
        console.error(error);
      },
    );
    this._locationService.findAllCity().subscribe(
      next => {
        this.allCities = next;
      },
      error => {
        console.error(error);
      },
    );
    this._bookingService.findAllAvailablePlatformHallsForUser(this.page, this.count).subscribe(
      next => {
        this.platforms = next;
      },
      error => {
        console.error(error);
      },
    );
  }

  changeCount(e) {
    this.count = e;
    this.page = 0;
    this.mainLoadPlatform();
  }

  filter() {
    this.page = 0;
    this.mainLoadPlatform();
  }

  mainLoadPlatform() {
    this._bookingService.findAllAvailablePlatformHallsForUser(this.page, this.count, this.mainFilterForm.nativeElement).subscribe(
      next => {
        this.platforms = next;
      },
      error => {
        console.error(error);
      },
    );
  }

  openPage(i: number) {
    this.page = i;
    this.mainLoadPlatform();
  }

  trackById(index, obj) {
    return obj.id;
  }

  clearForm() {
    this.mainFilterForm.nativeElement.reset();
    this.filter();
  }

  onSearchInput() {
    if (this.inputTimeout) {
      clearTimeout(this.inputTimeout);
    }
    this.inputTimeout = setTimeout(() => {
      this.filter();
    }, 500);
  }

  changeFilterCity(val) {
    if (val) {
      this._hallService.findAllPlatformHallsByCity(val).subscribe(
        next => {
          this.platformHalls = next;
        },
        error => {
          console.error(error);
        },
      );
    }
    this.mainLoadPlatform();
  }

  open(i) {
    this.page = i;
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.mainLoadPlatform();
  }
}
