<div class="report">
  <div class="report-section report-preview">
    <table class="archive-table">
      <caption>{{ 'reports.cashier_report.archive_table_title' | translate }}</caption>
      <tr>
        <th>{{ 'reports.cashier_report.archive_table_caption.document' | translate }}</th>
        <th>{{ 'reports.cashier_report.archive_table_caption.date' | translate }}</th>
        <th>{{ 'reports.cashier_report.archive_table_caption.organizer' | translate }}</th>
        <th>{{ 'reports.cashier_report.archive_table_caption.paymentType' | translate }}</th>
        <th>{{ 'reports.cashier_report.archive_table_caption.amount' | translate }}</th>
        <th>{{ 'reports.cashier_report.archive_table_caption.print' | translate }}</th>
      </tr>
      <tr *ngFor="let item of [1,2,3,4]">
        <td>1</td>
        <td>2</td>
        <td>3</td>
        <td>4</td>
        <td>5</td>
        <td>
          <input type="checkbox"
            (click)="onPrintSelect(reportItem.checked)"
            #reportItem>
        </td>
      </tr>
    </table>
  </div>
  <div class="report-section report-filter-form">
    <h4 class="report-title">Фільтрувати:</h4>
    <form [formGroup]="formGroup">
      <div class="filter-row">
        <p class="row-title">
          {{ 'reports.cashier_report.date_title' | translate }}
        </p>
        <input type="date"
          class="cell-value"
          [max]="maxDate | date:'yyyy-MM-dd'"
          name="docDate"
          formControlName="cashierWorkDay">
      </div>

      <div class="filter-row">
        <p class="row-title">
          {{ 'reports.cashier_report.events_payment_label' | translate }}
        </p>
        <select class="cell-value"
          name="eventsPaymentType"
          formControlName="type_of_payment">
          <option selected
            value="All">{{ 'reports.cashier_report.events_payment_all_option' | translate }}</option>
          <option value="CASH">{{ 'reports.cashier_report.events_payment_cash_option' | translate }}</option>
          <option value="CARD">{{ 'reports.cashier_report.events_payment_card_option' | translate }}</option>
        </select>
      </div>

      <div class="filter-row">
        <p class="row-title">
          {{ 'reports.cashier_report.events_type_label' | translate }}
        </p>
        <select name="eventsType"
          class="cell-value"
          formControlName="ownEventsOnly">
          <option selected
            value="">{{ 'reports.cashier_report.events_type_all_option' | translate }}</option>
          <option [value]="false">{{ 'reports.cashier_report.events_type_not_own_option' | translate }}</option>
          <option [value]="true">{{ 'reports.cashier_report.events_type_own_option' | translate }}</option>
        </select>
      </div>

      <button [disabled]="!selectedReports.length"
        class="report-actoin"
        (click)="loadReport()">
        {{ 'reports.cashier_report.archive_table_action' | translate }}
        <span *ngIf="selectedReports.length">({{ selectedReports.length }})</span>
      </button>
    </form>
  </div>
</div>
