import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserDetailsService } from '../user-details-service.service';

export type ColorServiceTypeConstant = 'white' | 'dark'

export class MainSettingConfig {
  line: 'ONE' | 'FOUR';
  date: 'WITH' | 'WITHOUT';
  lang: string;
  pageable: 'STANDART' | 'MODERN';
  color: ColorServiceTypeConstant;

  constructor(line?: 'ONE' | 'FOUR', date?: 'WITH' | 'WITHOUT', lang?: string, color?: ColorServiceTypeConstant, pageable?: 'STANDART' | 'MODERN') {
    this.line = line;
    this.date = date;
    this.lang = lang;
    this.color = color;
    this.pageable = pageable;
  }
}

@Injectable({ providedIn: 'root' })
export class MainSettingsService {
  private _settingConfig = new Subject<MainSettingConfig>();
  settingConfig$ = this._settingConfig.asObservable();

  private get localStorageItem() {
    return localStorage.getItem(this.getKey());
  }

  constructor(
    private _translateService: TranslateService,
    private _userDetailsService: UserDetailsService,
    private _router: Router,
  ) { }

  private getKey() {
    if (this._userDetailsService.getUser()) {
      if (this._userDetailsService.checkAuth()) {
        return btoa('settingConfig_' + this._userDetailsService.getUser().mail);
      } else {
        this._router.navigateByUrl('/sign-in');
        return 'null';
      }
    }
  }

  getConfig(): MainSettingConfig {
    if (!this.localStorageItem) {
      localStorage.setItem(this.getKey(), JSON.stringify(new MainSettingConfig('FOUR', 'WITHOUT', 'ua', 'white', 'MODERN')));
    }
    this._settingConfig.next((<MainSettingConfig>JSON.parse(localStorage.getItem(this.getKey()))));
    return (<MainSettingConfig>JSON.parse(localStorage.getItem(this.getKey())));
  }

  changeSetting(one: MainSettingConfig) {
    this._settingConfig.next(one);
    localStorage.setItem(this.getKey(), JSON.stringify(one));
  }

  changeFieldOfObj(name: string, value: string) {
    let tempObj = (<MainSettingConfig>JSON.parse(localStorage.getItem(this.getKey())));
    tempObj[name] = value;
    if (name == 'lang') {
      this._translateService.setDefaultLang(value.toLowerCase());
      this._translateService.resetLang(value.toLowerCase());
      this._translateService.use(value.toLowerCase());
    }
    this.changeSetting(tempObj);
  }

}
