<nz-table
  *ngIf="events"
  #fixedTable
  [nzData]="events"
  [nzBordered]="true"
  [nzScroll]="{ x: '4000px' }"
  nzSize="small"
  nzShowSizeChanger
  [nzFrontPagination]="false"
  [nzLoading]="loading"
  [nzTotal]="total"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead class="table-header">
    <tr>
      <th nzLeft rowspan="2"></th>
      <th nzLeft rowspan="2" nzWidth="200px">Дані</th>
      <th nzLeft rowspan="2" nzWidth="100px">Дата</th>
      <ng-container *ngFor="let month of monthInYear">
        <th colspan="5">{{ month }}</th>
      </ng-container>
      <th rowspan="2" nzRight>Разом</th>
    </tr>
    <tr>
      <ng-container *ngFor="let month of monthInYear">
        <th>Онлайн</th>
        <th>Готівка</th>
        <th>Термінал</th>
        <th>Борзовик</th>
        <th>Повернення</th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of fixedTable.data">
      <td nzLeft>
        <img class="event-picture" [src]="data?.mainImageSmall?.path | img" alt="Event Image" />
      </td>
      <td nzLeft nzWidth="200px">
        <p class="event-data event-title">{{ data?.name?.valueUA }}</p>
        <p class="event-data event-adress">{{ data?.platformHall?.name?.valueUA }}</p>
        <p class="event-data event-adress">{{ data?.platformHall?.address?.valueUA }}</p>
      </td>
      <td style="text-align: center">
        {{ data?.beginDate | date : 'dd.MM.yyyy' }}
        <br />
        <span>{{ data?.beginDate | date : 'HH:mm' }}</span>
      </td>
      <ng-container *ngFor="let month of monthInYear">
        <td>н/д</td>
        <td>н/д</td>
        <td>н/д</td>
        <td>н/д</td>
        <td>н/д</td>
      </ng-container>
      <td nzRight>н/д</td>
    </tr>
  </tbody>
</nz-table>
