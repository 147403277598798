<form [formGroup]="addAgentForm"
  (ngSubmit)="onAddAgent()"
  class="add-form">
  <input name="agentName"
    class="cell-value"
    type="text"
    formControlName="fullName"
    placeholder="{{'agents.add_form.name_placeholder'|translate}}">
  <input type="text"
    class="cell-value"
    [textMask]="{mask: PHONE_PATTERN, guide: false, modelClean: true}"
    formControlName="phone"
    placeholder="{{'agents.add_form.phone_placeholder'|translate}}">
  <button class="add-actoin"
    type="submit"
    [disabled]="!addAgentForm.valid">
    {{'agents.add_form.action_label'|translate}}
  </button>
</form>
