import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { BookingService } from 'src/shared/service/backend/booking.service';
import { BoxOfficeEventDto } from 'src/shared/models/dto/BoxOfficeEventDto';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
})
export class EventDetailsComponent implements OnInit {
  public eventDetails: BoxOfficeEventDto;
  public eventId: string;

  constructor(private route: ActivatedRoute, private router: Router, private bookingService: BookingService) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        switchMap((params: ParamMap) => {
          this.eventId = params['id'];
          return this.bookingService.filterEventsBoxOfficeStatisticByDateV2(
            1,
            0,
            'ALL',
            null,
            null,
            null,
            null,
            null,
            null,
            'ASC',
            false,
            '',
            this.eventId,
          );
        }),
      )
      .subscribe(data => {
        this.eventDetails = data as any;
        this.router.navigate([`general/${this.eventId}`], { relativeTo: this.route });
      });
  }
}
