<div class="report">
  <div class="report-section report-preview">
    <h4 class="report-caption">
      {{ 'reports.cashier_report.report-daily-title' | translate }}
      <span class="report-date">{{ cashierWorkDay || maxDate | date: 'dd.MM.yyyy'}}</span>
    </h4>
    <table class="report-table"
      *ngIf="false; else emptyMsg">
      <tr>
        <th rowspan="2"></th>
        <th colspan="4">{{ 'reports.cashier_report.report_table_caption.bought' | translate }}</th>
        <th rowspan="2">{{ 'reports.cashier_report.report_table_caption.returned' | translate }}</th>
        <th rowspan="2">{{ 'reports.cashier_report.report_table_caption.total' | translate }}</th>
      </tr>
      <tr>
        <th>{{ 'reports.cashier_report.report_table_caption.cash' | translate }}</th>
        <th>{{ 'reports.cashier_report.report_table_caption.terminal' | translate }}</th>
        <th>{{ 'reports.cashier_report.report_table_caption.borzovyk' | translate }}</th>
        <th>{{ 'reports.cashier_report.report_table_caption.general' | translate }}</th>
      </tr>
      <tr>
        <td>{{ 'reports.cashier_report.report_table_row.own' | translate }}</td>
        <td>2</td>
        <td>3</td>
        <td>4</td>
        <td>5</td>
        <td>6</td>
        <td>7</td>
      </tr>
      <tr>
        <td>{{ 'reports.cashier_report.report_table_row.other' | translate }}</td>
        <td>2</td>
        <td>3</td>
        <td>4</td>
        <td>5</td>
        <td>6</td>
        <td>7</td>
      </tr>
      <tr>
        <td>{{ 'reports.cashier_report.report_table_row.general' | translate }}</td>
        <td>2</td>
        <td>3</td>
        <td>4</td>
        <td>5</td>
        <td>6</td>
        <td>7</td>
      </tr>
    </table>
  </div>
  <div class="report-section report-create-form">
    <form [formGroup]="formGroup">
      <div class="report-row">
        <p class="row-title">
          {{ 'reports.cashier_report.name_title' | translate }}
          <input type="text"
            readonly
            class="cell-value"
            value="{{ 'reports.cashier_report.document_name' | translate }}">
        </p>

        <p class="row-title">
          {{ 'reports.cashier_report.document_title' | translate }}
          <input type="text"
            name="docNumber"
            class="cell-value"
            formControlName="numberInvoice">
        </p>
      </div>
      <div class="report-row">
        <p class="row-title">
          {{ 'reports.cashier_report.date_title' | translate }}
          <input type="date"
            class="cell-value"
            [max]="maxDate | date:'yyyy-MM-dd'"
            name="docDate"
            formControlName="cashierWorkDay">
        </p>

        <p class="row-title">{{ 'reports.cashier_report.events_payment_label' | translate }}
          <select class="cell-value"
            name="eventsPaymentType"
            formControlName="type_of_payment">
            <option selected
              value="All">{{ 'reports.cashier_report.events_payment_all_option' | translate }}</option>
            <option value="CASH">{{ 'reports.cashier_report.events_payment_cash_option' | translate }}</option>
            <option value="CARD">{{ 'reports.cashier_report.events_payment_card_option' | translate }}</option>
          </select>
        </p>
      </div>
      <div class="report-row">
        <p class="row-title">{{ 'reports.cashier_report.events_type_label' | translate }}
          <select name="eventsType"
            class="cell-value"
            formControlName="ownEventsOnly">
            <option selected
              value="">{{ 'reports.cashier_report.events_type_all_option' | translate }}</option>
            <option [value]="false">{{ 'reports.cashier_report.events_type_not_own_option' | translate }}</option>
            <option [value]="true">{{ 'reports.cashier_report.events_type_own_option' | translate }}</option>
          </select>
        </p>

        <p *ngIf="ownEventsOnly === 'false'"
          class="row-title">{{ 'reports.cashier_report.events_organizer_label' | translate }}
          <select name="eventsType"
            class="cell-value"
            formControlName="organizers">
            <option selected
              value="">{{ 'reports.cashier_report.events_organizer_all_option' | translate }}</option>
          </select>
        </p>
      </div>

      <button [disabled]="formGroup.invalid"
        class="report-actoin"
        (click)="loadReport()">{{ 'reports.cashier_report.report_table_action' | translate }}</button>
    </form>
  </div>
</div>

<ng-template #emptyMsg>
  <h3 class="report-empty">За вказаний період результатів не знайдено</h3>
</ng-template>
