<div class="container">
  <!-- <div class="graph"><ngx-charts-line-chart [scheme]="colorScheme"[results]="list"[gradient]="gradient"[xAxis]="showXAxis"[yAxis]="showYAxis"[legend]="showLegend"[showXAxisLabel]="showXAxisLabel"[showYAxisLabel]="showYAxisLabel"[xAxisLabel]="xAxisLabel"[yAxisLabel]="yAxisLabel"[timeline]="showTimeline"[legendTitle]="'статуси'"[animations]="animations"[xAxisTickFormatting]="xAxisTickFormatting"[yAxisTickFormatting]="yAxisTickFormatting"[autoScale]="autoScale"(select)="onSelect($event)"(activate)="onActivate($event)"(deactivate)="onDeactivate($event)"></ngx-charts-line-chart></div> -->
  <div class="statistics_all">
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #2d9cdb">
          {{ 'accountant.event_statistics.general.quota' | translate }}
          <span *ngIf="infoAboutTickets.countAllTicket == 0">(0,00%)</span>
          <span *ngIf="infoAboutTickets.countAllTicket != 0">(100 %)</span>
        </p>
        <p class="down">{{ infoAboutTickets.priceAllTicket }} {{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets.countAllTicket }} {{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #27ae60">
          {{ 'accountant.event_statistics.general.sold' | translate }}
          <span *ngIf="+infoAboutTickets.priceBoughtTicket == 0">(0,00%)</span>
          <span *ngIf="+infoAboutTickets.priceBoughtTicket != 0">
            ({{ (infoAboutTickets.priceBoughtTicket / infoAboutTickets.priceAllTicket) * 100 | number : '.0-2' }}% )
          </span>
        </p>
        <p class="down">{{ infoAboutTickets.priceBoughtTicket }} {{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets.countBoughtTicket }} {{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #d7ae30">
          {{ 'accountant.event_statistics.general.online' | translate }}
          <span *ngIf="+infoAboutTickets.priceOnlineTicket == 0">(0,00%)</span>
          <span *ngIf="+infoAboutTickets.priceOnlineTicket != 0">
            ({{ (infoAboutTickets.priceOnlineTicket / infoAboutTickets.priceAllTicket) * 100 | number : '.0-2' }} %)
          </span>
        </p>
        <p class="down">{{ infoAboutTickets.priceOnlineTicket }} {{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets.countOnlineTicket }} {{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #5956f2">
          {{ 'accountant.event_statistics.general.cash' | translate }}
          <span *ngIf="+infoAboutTickets.priceCashTicket == 0">(0,00%)</span>
          <span *ngIf="+infoAboutTickets.priceCashTicket != 0">
            ({{ (infoAboutTickets.priceCashTicket / infoAboutTickets.priceAllTicket) * 100 | number : '.0-2' }} %)
          </span>
        </p>
        <p class="down">{{ infoAboutTickets?.priceCashTicket }} {{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets?.countCashTicket }} {{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #00deff">
          {{ 'accountant.event_statistics.general.terminal' | translate }}
          <span *ngIf="+infoAboutTickets.priceCardTicket == 0">(0,00%)</span>
          <span *ngIf="+infoAboutTickets.priceCardTicket != 0">
            ({{ (infoAboutTickets.priceCardTicket / infoAboutTickets.priceAllTicket) * 100 | number : '.0-2' }} %)
          </span>
        </p>
        <p class="down">{{ infoAboutTickets?.priceCardTicket }} {{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets?.countCardTicket }} {{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #9932b3">
          {{ 'accountant.event_statistics.general.borz' | translate }}
          <span *ngIf="+infoAboutTickets.priceVendorTicket == 0">(0,00%)</span>
          <span *ngIf="+infoAboutTickets.priceVendorTicket != 0">
            ({{ (infoAboutTickets.priceVendorTicket / infoAboutTickets.priceAllTicket) * 100 | number : '.0-2' }} %)
          </span>
        </p>
        <p class="down">{{ infoAboutTickets?.priceVendorTicket }} {{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets?.countVendorTicket }} {{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #cb2969">{{ 'accountant.event_statistics.general.returned' | translate }}</p>
        <p class="down">{{ infoAboutTickets?.priceRevertedTicket }} {{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets?.countRevertedTicket }} {{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <!-- <div class="item">
      <div class="item_block">
        <p class="up" style="background: #5956f2">
          {{ 'accountant.event_statistics.general.box_ofice' | translate }}
          <span *ngIf="infoAboutTickets.ticketOfflineCount == 0">(0,00%)</span>
          <span *ngIf="infoAboutTickets.ticketOfflineCount != 0">
            ({{ angularMath.round((100 * infoAboutTickets.ticketOfflineCount) / infoAboutTickets.maxTicketCount, 2) }}%)
          </span>
        </p>
        <p class="down">{{ infoAboutTickets.ticketOfflinePrice }}{{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets.ticketOfflineCount }}{{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #00deff">
          {{ 'accountant.event_statistics.general.reservation' | translate }}
          <span *ngIf="infoAboutTickets.ticketBookedCount == 0">(0%)</span>
          <span *ngIf="infoAboutTickets.ticketBookedCount != 0">
            ({{ angularMath.round((100 * infoAboutTickets.ticketBookedCount) / infoAboutTickets.maxTicketCount, 2) }}%)
          </span>
        </p>
        <p class="down">{{ infoAboutTickets.ticketBookedPrice }}{{ 'accountant.event_statistics.general.currency' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.tickets' | translate }}</p>
        <p class="down">{{ infoAboutTickets.ticketBookedCount }}{{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up" style="background: #9932b3">{{ 'accountant.event_statistics.general.bonus_count' | translate }}</p>
        <p class="down">{{ infoAboutTickets.bonusGetCount }}{{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.bonus_use' | translate }}</p>
        <p class="down">{{ infoAboutTickets.bonusSellCount }}{{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div>
    <div class="item">
      <div class="item_block">
        <p class="up">{{ 'accountant.event_statistics.general.promo' | translate }}</p>
        <p class="down">{{ infoAboutTickets.promoCodeCount }}{{ 'accountant.event_statistics.general.quantity' | translate }}</p>
      </div>
    </div> -->
  </div>
  <table>
    <thead>
      <tr>
        <td rowspan="2">{{ 'accountant.event_statistics.general.ticket_type' | translate }}</td>
        <td rowspan="2">{{ 'accountant.event_statistics.general.ticket_price' | translate }}</td>
        <td colspan="2">{{ 'accountant.event_statistics.general.ticket_quota' | translate }}</td>
        <td colspan="2">{{ 'accountant.event_statistics.general.ticket_reserved_amount' | translate }}</td>
        <td colspan="2">{{ 'accountant.event_statistics.general.sold' | translate }}</td>
        <td colspan="2">{{ 'accountant.event_statistics.general.returned' | translate }}</td>
        <td colspan="2">{{ 'accountant.event_statistics.general.available' | translate }}</td>
      </tr>
      <tr>
        <td>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</td>
        <td>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</td>
        <td>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</td>
        <td>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</td>
        <td>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</td>
        <td>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</td>

        <td>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</td>
        <td>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</td>
        <td>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</td>
        <td>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let one of statisticsInfo; trackBy: trackById; index as i"
        [ngStyle]="{ fontWeight: i === statisticsInfo.length - 1 ? 'bold' : 'normal' }"
      >
        <td>
          <span *ngIf="i !== statisticsInfo.length - 1" class="color_colorPrice" [ngStyle]="{ background: one.colorPrice }"></span>
          <span *ngIf="i === statisticsInfo.length - 1">{{ 'accountant.event_statistics.general.total' | translate }}</span>
        </td>
        <td>
          <span *ngIf="i !== statisticsInfo.length - 1">{{ (one.price | number : '.0-2') || '-' }}</span>
          <span *ngIf="i === statisticsInfo.length - 1">-</span>
        </td>
        <td>{{ one.countAllTicket || '-' }}</td>
        <td>{{ one.priceAllTicket | number : '.0-2' || '-' }}</td>
        <td>{{ one.countBookedTicket || '-' }}</td>
        <td>{{ one.priceBookedTicket | number : '.0-2' || '-' }}</td>
        <td>{{ one.countBoughtTicket || '-' }}</td>
        <td>{{ one.priceBoughtTicket | number : '.0-2' || '-' }}</td>

        <td>{{ one.countRevertTicket }}</td>
        <td>{{ one.priceRevertTicket | number : '.0-2' }}</td>
        <td>{{ one.countAvailableTicket }}</td>
        <td>{{ one.priceAvailableTicket | number : '.0-2' }}</td>
      </tr>
    </tbody>
  </table>
</div>
