import { ErrorStatus } from "./error.enum";
import { environment } from "../../../environments/environment";

export class ErrorMethod {

  constructor(private error: any) { }

  getMessage(): string {
    return this.getStatusFromNumber(this.getStatusString());
  }

  // mplsweb9922@gmail.com
  message(serviceName?: any, url?: any): ErrorMethod {
    let temp: any[] = [];
    if (!!url)
      if ((<string>url).split('http://').length > 1)
        url = (<string>url).split('http://')[1];
    if (!environment.production) {
      if (!localStorage.getItem("log") || !localStorage.getItem("log") || localStorage.getItem("log") == "") {
        localStorage.setItem("log", JSON.stringify([]));
      } else {
        temp = JSON.parse(localStorage.getItem("log"));
      }
      temp.push({
        name_service: (!serviceName ? "no name" : serviceName),
        url: (!url ? "no url" : url),
        status_message: this.getMessage(),
        status: this.error.status,
        date: new Date().toISOString(),
        message: this.error.message
      });
      localStorage.setItem("log", JSON.stringify(temp));
    }

    console.table([{
      name_service: (!serviceName ? "no name" : serviceName),
      url: (!url ? "no url" : url),
      status_message: this.getMessage(),
      status: this.error.status,
      date: new Date().toISOString(),
      message: this.error.message
    }]);
    return this;
  }

  private getStatusString(): number {
    if (!!this.error.error) {
      if (!!this.error.error.message) {
        for (let obj in Object.keys(ErrorStatus)) {
          if (this.error.error.message == obj)
            return parseInt(obj);
        }
      }
    }
    return -1;

  }

  private getStatusFromNumber(number: number): ErrorStatus | string {
    let i = 0;
    for (let obj in ErrorStatus) {
      if (i == number)
        return <ErrorStatus>obj;
      i++;
    }
    if (!!this.error.error) {
      if (!!this.error.error.message) {
        for (let obj in ErrorStatus) {
          if (obj == this.error.error.message)
            return <ErrorStatus>obj;
          i++;
        }
      }
    }
    return this.error.status;
  }


}
