import { IDictionary } from 'src/shared/interfaces/interfaces';
import { PlatformHall } from './PlatformHall';
import { TypeOfScheme, VerifiedState } from './enums/enums';
import { ImageD, User } from './User';
import { Ticket } from './Ticket';
import { Video } from './Video';
import { Category } from './Category';
import { Hall } from './Hall';
import { NetBoxOffice } from './NetBoxOffice';
import { EventEmailTemplate } from './event-email-template';
import { TicketTemplate } from './TicketTemplate';
import { PaySystemKey } from './PaySystemKey';
import { TicketGroupWithoutScheme } from './TicketGroupWithoutScheme';

export class Event {
  id: number;
  lastModified: string;
  hall: Hall;
  dataPublic: string;
  author: User;
  organizator: User;
  netBoxOffices: NetBoxOffice[] = [];
  // payOffices: PayOffice
  // bins: Bin[] = [];
  tickets: Ticket[] = [];
  similar: Event[] = [];
  ticketGroupWithOutSachems: TicketGroupWithoutScheme;
  eventEmailTemplate: EventEmailTemplate;
  eventEmailTemplateInvite: EventEmailTemplate;
  templateA4: TicketTemplate;
  templateTerm: TicketTemplate;
  templateTermInvite: TicketTemplate;
  templateA4Invite: TicketTemplate;
  sameEvent: Event[] = [];
  images: ImageD[] = [];
  videos: Video[] = [];
  promos: Video[] = [];
  eventCategory: Category[] = [];
  name: IDictionary;
  shortDescription: IDictionary;
  description: IDictionary;
  mainImage: ImageD;
  mainImageSmall: ImageD;
  ticketOrderTime: number;
  maxTicketInOrder: number;
  beginDate: string;
  beginSellDate: string;
  beginEndDate: string;
  available: boolean;
  verifiedState: VerifiedState;
  maxPrice: number;
  minPrice: number;
  platformHall: PlatformHall;
  keyWords: IDictionary;
  typeOfScheme: TypeOfScheme;
  urlSegment: string;
  slider: boolean;
  promo: boolean;
  paySystemKey: PaySystemKey;
  // inspectors: Inspector[] = [];
  sliderDateStart: string;
  sliderDateEnd: string;
  like: boolean;
  ticketOrderTimeBoxOffice: number;
}
