export class Config {
  static MY_SELECTED_SEAT: string = 'rgb(0, 0, 0)';
  static MY_SELECTED_SEAT_RED: string = 'rgb(255, 0, 0)';
  static DISABLE_SEAT: string = '#CCC';
  static disableTicketsColor: string = '#B6B6B6';
  static myTicketsColor: string = 'green';
  static PHONE_MASK = ['3', '8', ' ', '(', /\d/, /\d/, /\d/, ')', '-', /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  static EMAIL_MASK =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
}
