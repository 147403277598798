import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BookingService } from '../backend/booking.service';
import { Config } from '../../config/config';
import { Ticket } from '../../models/Ticket';
import { Bin } from '../../models/Bin';
import { TicketOrderWithOutSchemDto } from '../../models/dto/TicketOrderWithOutSchemDto';
import { Booking } from '../../models/Booking';
import { TimerBuilder } from '../utils/timer.builder';
import { SideInfo, SideInfoRxService } from '../../../app/source/side-info-bar/side-info-bar.component';
import { MainLoaderService } from './main-loader.service';
import { UserWithBoxOfficeDto } from '../../models/dto/UserWithBoxOfficeDto';

@Injectable({ providedIn: 'root' })
export class BinService {
  bin: Bin = new Bin();
  private _bin = new Subject<Bin>();
  bin$ = this._bin.asObservable();

  timeBuilder: TimerBuilder = new TimerBuilder();

  sum: number = 0;
  private _sum = new Subject<number>();
  sum$ = this._sum.asObservable();

  constructor(
    private _bookingService: BookingService,
    private _sideInfoRxService: SideInfoRxService,
    private _loaderService: MainLoaderService,
  ) {
    this.bin.ticketList = [];
    this._bin.next(this.bin);
  }

  getBinByOrderId(order: string, idEvent: string) {
    if (order) {
      this._bookingService.findBinByOrderId(order).subscribe(
        next => {
          this.bin.ticketList = next;
          this.bin.user = new UserWithBoxOfficeDto();
          this.bin.comment = '';
          console.warn(this.bin);
          if (this.bin.ticketList.length > 0) {
            this.countPriceBin(this.bin.ticketList);
            for (let i = 0; i < this.bin.ticketList.length; i++) {
              if (!!this.bin.ticketList[i].seat) {
                if (this.bin.ticketList[i].seat.fanzone) {
                  document.getElementById(this.bin.ticketList[i].seat.key).setAttribute('fanzone', 'true');
                } else {
                  document.getElementById(this.bin.ticketList[i].seat.key).setAttribute('ticketId', this.bin.ticketList[i].id + '');
                  document.getElementById(this.bin.ticketList[i].seat.key).style.fill = Config.MY_SELECTED_SEAT;
                  document.getElementById(this.bin.ticketList[i].seat.key).setAttribute('fanzone', 'false');
                }
              }
            }
          }
          // if (!this.bin.ticketList) {
          //   alert(!this.bin.ticketList + '-----')
          //   this.bin.ticketList = [];
          // }
          console.warn(this.bin);
          this._bin.next(this.bin);
        },
        error => {
          localStorage.removeItem('order' + idEvent);
          this._loaderService.endLoader();
          if (error.status == 410) {
            this._sideInfoRxService.add(new SideInfo().setInfo('Час корзини минув, попробуйте ще раз!').setDate().setError(''));
          }
        },
      );
    } else {
      this.bin.ticketList = [];
      this.bin.user = new UserWithBoxOfficeDto();
      this.bin.comment = '';
      this._bin.next(this.bin);
      this.countPriceBin(this.bin.ticketList);
    }
  }

  // Для веб-сокета пошук квитка

  searchTicketByIdWithout(id: number, arrayTickets: TicketOrderWithOutSchemDto[]): TicketOrderWithOutSchemDto {
    for (let i = 0; i < arrayTickets.length; i++) {
      if (arrayTickets[i].id == id) {
        return arrayTickets[i];
      }
    }
    return null;
  }

  addTicketInBin(booking: Booking) {
    this._bookingService.clientBooking(booking).subscribe(
      next => {
        this.bin.ticketList.push(next);
        this._bin.next(this.bin);
        const ticket = this.bin.ticketList.find(el => el.id == next.id);
        if (ticket) {
          this.countPriceBin(this.bin.ticketList);
          if (ticket?.seat?.id && !ticket.seat.fanzone) {
            document.getElementById(ticket.seat.key).style.fill = Config.MY_SELECTED_SEAT;
          }
        }
        this._loaderService.endLoader();
      },
      error => {
        this._loaderService.endLoader();
        if (error.status == 409) {
          this._sideInfoRxService.add(
            new SideInfo().setInfo('Цей квиток зайнятий іншим користувачем, спробуйте інший!').setDate().setError(''),
          );
        } else if (error.status == 423 || error.status == 410) {
          this._sideInfoRxService.add(new SideInfo().setInfo('Квиток заблоковано!').setDate().setError(''));
        } else if (error.status == 416) {
          this._sideInfoRxService.add(new SideInfo().setInfo('Забагато квитків в корзині!').setDate().setError(''));
        } else {
          this._sideInfoRxService.add(new SideInfo().setInfo('Невідома помилка!').setDate().setError(''));
          console.error(error);
        }
      },
    );
  }

  rmTicketInBin(id: number, order: string) {
    this.bin.ticketList = this.bin.ticketList.filter(value => value.id != id);
    if (this.bin.ticketList.length == 0) {
      // this.deleteBin(order);
    } else {
      this._loaderService.endLoader();
    }
    this.countPriceBin(this.bin.ticketList);
    this._bin.next(this.bin);
  }

  deleteBin(order: string) {
    this._bookingService.clientDeleteBin(order).subscribe(
      next => {
        this.countPriceBin(this.bin.ticketList);
        this._loaderService.endLoader();
      },
      error => {
        this._loaderService.endLoader();
        console.error(error);
      },
    );
  }

  countPriceBin(tickets: Ticket[]) {
    this.sum = 0;
    tickets.forEach(value => (this.sum += value.colorPrice.price));
    this._sum.next(this.sum);
  }

  deleteBinFromClient(id: string) {
    this.bin = new Bin();
    this._bin.next(this.bin);
    localStorage.removeItem('time' + id);
  }

  private addTicketToBin(ticket): void {
    this.bin.ticketList.push(ticket);
    this._bin.next(this.bin);
    const newTicket = this.bin.ticketList.find(el => el.id == ticket.id);
    if (newTicket) {
      this.countPriceBin(this.bin.ticketList);
      if (newTicket?.seat?.id && !newTicket.seat.fanzone) {
        document.getElementById(newTicket.seat.key).style.fill = Config.MY_SELECTED_SEAT;
      }
    }
    this._loaderService.endLoader();
  }

  private sanitizeError(error): void {
    this._loaderService.endLoader();
    if (error.status == 409) {
      this._sideInfoRxService.add(
        new SideInfo().setInfo('Цей квиток зайнятий іншим користувачем, спробуйте інший!').setDate().setError(''),
      );
    } else if (error.status == 423 || error.status == 410) {
      this._sideInfoRxService.add(new SideInfo().setInfo('Квиток заблоковано!').setDate().setError(''));
    } else if (error.status == 416) {
      this._sideInfoRxService.add(new SideInfo().setInfo('Забагато квитків в корзині!').setDate().setError(''));
    } else {
      this._sideInfoRxService.add(new SideInfo().setInfo('Невідома помилка!').setDate().setError(''));
      console.error(error);
    }
  }
}
