import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { GlobalImportModule } from './global-import.module';
import { ClientModule } from './client/client.module';
import { clientRoutes } from './client/client-routes';
import { MainLoaderComponent } from './source/main-loader/main-loader.component';
import { SideInfoBarComponent } from './source/side-info-bar/side-info-bar.component';
import { PaymentBarComponent } from './client/client-source/payment-bar/payment-bar.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import uk from '@angular/common/locales/uk';
import { CoreModule } from './core/core.module';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { uk_UA } from 'ng-zorro-antd/i18n';
import { NzTableModule } from 'ng-zorro-antd/table';

registerLocaleData(uk);

const routes: Routes = [
  {
    path: 'sign-in', component: SignInComponent
  },
  ...clientRoutes,
];

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    MainLoaderComponent,
    SideInfoBarComponent,
    PaymentBarComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ClientModule,
    CoreModule,
    RouterModule.forRoot(routes, { useHash: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GlobalImportModule,
    FormsModule,
    BrowserAnimationsModule,
    NzTableModule,
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: NZ_I18N, useValue: uk_UA }]
})
export class AppModule { }
