import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-record',
  templateUrl: './event-record.component.html',
  styleUrls: ['./event-record.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventRecordComponent implements OnInit {
  @Input()
  public eventItem: any;

  @Input()
  public displayMode: 'simple' | 'extended' = 'simple';

  constructor() {}

  ngOnInit(): void {}
}
