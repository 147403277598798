import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { IAuthTokenData, SignInService } from '../../shared/service/backend/sign.in.service';
import { UserDetailsService } from '../../shared/service/user-details-service.service';
import { SideInfo, SideInfoRxService } from '../source/side-info-bar/side-info-bar.component';
import { UserService } from '../../shared/service/backend/user.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements AfterViewInit {
  @ViewChild('login') login: ElementRef;
  @ViewChild('password') password: ElementRef;
  @ViewChild('googleBtn') googleBtn: ElementRef | undefined;

  constructor(
    private signInService: SignInService,
    private userDetailsService: UserDetailsService,
    private sideInfoRxService: SideInfoRxService,
    private userService: UserService,
  ) {}

  ngAfterViewInit() {
    this.signInService.initGoogleLogin(this.googleBtn?.nativeElement, {
      success: (tokenData: IAuthTokenData) => this.successLogin(tokenData),
      error: (error: any) => this.erorrLogin(error),
    });
  }

  loginSend() {
    this.userDetailsService.logout();
    this.signInService
      .sendCredentials({
        username: this.login.nativeElement.value,
        password: this.password.nativeElement.value,
      })
      .subscribe(
        next => this.successLogin(next),
        error => this.erorrLogin(error),
      );
  }

  erorrLogin(error: any) {
    this.userDetailsService.logout();
    this.login.nativeElement.style.border = '1px solid #CB2969';
    this.password.nativeElement.style.border = '1px solid #CB2969';
    console.error(error);
  }

  successLogin(tokenData: IAuthTokenData): void {
    this.login.nativeElement.style.border = '1px solid #bdbdbd';
    this.password.nativeElement.style.border = '1px solid #bdbdbd';
    this.userDetailsService.tokenParseInLocalStorage(tokenData);
    this.userService.getUserWithBoxAndNetBoxOffices().subscribe(
      next => {
        this.userDetailsService.login(next);
        localStorage.setItem('ROLE', next.role);
        if (next.role == 'CASHIER' || next.role == 'CASHIER_PLUS') {
          window.location.href = window.location.origin;
        } else {
          this.sideInfoRxService.add(new SideInfo().setInfo('У вас немає доступу!').setDate().setError('error'));
        }
      },
      error => {
        console.error(error);
        this.userDetailsService.logout();
      },
    );
  }
}
