import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Country } from '../../models/Country';
import { Region } from '../../models/Region';
import { IDictionary } from 'src/shared/interfaces/interfaces';
import { City } from '../../models/City';

@Injectable({ providedIn: 'root' })
export class LocationService {
  controller = '/location';

  constructor(private _httpClient: HttpClient) {}

  findAllCountryy(): Observable<Country[]> {
    return this._httpClient.get<Country[]>(this.controller + '/find-all-country').pipe(catchError(err => throwError(err)));
  }

  findAllCityy(): Observable<City[]> {
    return this._httpClient.get<City[]>(this.controller + '/find-all-city').pipe(catchError(err => throwError(err)));
  }

  findAllCountry(): Observable<Country[]> {
    return this._httpClient.get<Country[]>(this.controller + '/active/find-all-country').pipe(catchError(err => throwError(err)));
  }

  findAllCity(): Observable<City[]> {
    return this._httpClient.get<City[]>(this.controller + '/active/find-all-city').pipe(catchError(err => throwError(err)));
  }

  findAllRegionByCountryy(id: number): Observable<Region[]> {
    return this._httpClient.get<Region[]>(this.controller + '/find-all-region-by-country/' + id).pipe(catchError(err => throwError(err)));
  }

  findAllRegionByCountry(id: number): Observable<Region[]> {
    return this._httpClient
      .get<Region[]>(this.controller + '/active/find-all-region-by-country/' + id)
      .pipe(catchError(err => throwError(err)));
  }

  createRegion(o: Region): Observable<Region> {
    return this._httpClient.post<Region>(this.controller + '/create-region', JSON.stringify(o)).pipe(catchError(err => throwError(err)));
  }

  createCity(o: City): Observable<City> {
    return this._httpClient.post<City>(this.controller + '/create-city', JSON.stringify(o)).pipe(catchError(err => throwError(err)));
  }

  createCountry(o: IDictionary): Observable<Country> {
    return this._httpClient.post<Country>(this.controller + '/create-country', JSON.stringify(o)).pipe(catchError(err => throwError(err)));
  }

  changeStatusRegion(o: Region, status: boolean): Observable<Region> {
    return this._httpClient
      .get<Region>(this.controller + '/change-status-region/' + o.id + '/' + status)
      .pipe(catchError(err => throwError(err)));
  }

  changeStatusCity(o: City, status: boolean): Observable<City> {
    return this._httpClient
      .get<City>(this.controller + '/change-status-city/' + o.id + '/' + status)
      .pipe(catchError(err => throwError(err)));
  }

  changeStatusCountry(o: Country, status: boolean): Observable<Country> {
    return this._httpClient
      .get<Country>(this.controller + '/change-status-country/' + o.id + '/' + status)
      .pipe(catchError(err => throwError(err)));
  }

  findAllCityByRegionn(id: number): Observable<City[]> {
    return this._httpClient.get<City[]>(this.controller + '/find-all-city-by-region/' + id).pipe(catchError(err => throwError(err)));
  }

  findAllCityByRegion(id: number): Observable<City[]> {
    return this._httpClient.get<City[]>(this.controller + '/active/find-all-city-by-region/' + id).pipe(catchError(err => throwError(err)));
  }
}
