import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BinPriceWinCommissionDto } from 'src/shared/models/dto/BinPriceWinCommissionDto';
import { Ticket } from 'src/shared/models/Ticket';
import { TimerBuilder } from '../../../../../../shared/service/utils/timer.builder';
import { openCloseElementBlock, openCloseElementFlex } from '../../../../../../shared/service/utils/export-functions';

@Component({
  selector: 'app-vendor-bin',
  templateUrl: './vendor-bin.component.html',
  styleUrls: ['./vendor-bin.component.css']
})
export class VendorBinComponent implements OnInit {
  @Input()
  public tickets: any = [];

  @Input()
  public checkWithScheme: boolean;

  @Input()
  public priceCommission: BinPriceWinCommissionDto;

  @Input()
  public binPrice: number = 0;

  @Output()
  public unlockTicket: EventEmitter<{ ticket: Ticket, hall: string }> = new EventEmitter()

  promoCode = '';
  promoCodePrice = 0;
  wayStepRange = 0;

  timeBuilder = new TimerBuilder();

  constructor() { }

  ngOnInit(): void {
  }

  usePromoCode(top = null) { }

  removePromoCode(top = null) { }

  createVendorOrder(top = null) { }

  unlockSchemeTicketFromBin(ticket: Ticket) {
    if (ticket?.seat?.fanzone) {
      this.unlockTicket.emit({ ticket, hall: 'fanZone' });
    } else {
      this.unlockTicket.emit({ ticket, hall: 'default' });
    }
  }

  openCloseBinBlock(element: HTMLElement) {
    openCloseElementBlock(element);
  }

  openCloseBinFlex(element: HTMLElement) {
    openCloseElementFlex(element);
  }

  deleteTicketFromBinWithout(ticket) {
    this.unlockTicket.emit({ ticket, hall: 'noSchema' });
  }

}
