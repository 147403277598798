
import { Directive, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { timer as observableTimer, Subscription } from 'rxjs';

import { UserService } from '../../service/backend/user.service';

@Directive({
  selector: '[validEmail]',
})
export class ValidEmailDirective {
  /**
   * not valid or not found email
   */
  @Output() valid = new EventEmitter();
  /**
   * email present in system
   */
  @Output() presentInSystem = new EventEmitter();

  validation: boolean = false;

  timer: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _userService: UserService,
  ) {
    renderer.listen(this.el.nativeElement, 'input', (e) => {
      if (this.timer) {
        this.timer.unsubscribe();
        this.timer = null;
      }
      this.timer = observableTimer(1000, 0).subscribe(next => {
        this.startTime(e);
        if (this.timer) {
          this.timer.unsubscribe();
          this.timer = null;
        }
      });
    });
  }

  startTime(e) {
    this.elementValidation(e.target);
    this.valid.emit(null);
    this._userService.validEmail(e.target.value).subscribe(next => {
      this.elementValid(e.target);
    }, error => {
      if (error.status == 406) {
        this.elementNotValid(e.target);
      } else if (error.status == 409) {
        this.elementValid(e.target);
        this.presentInSystem.emit(true);
      } else {
        this.elementNotValid(e.target);
      }
      this.validation = false;
      e.target.readOnly = false;
    });
  }


  /**
   * if validation start
   * @param e
   */
  elementValidation(e: HTMLInputElement) {
    this.validation = true;
    // e.readOnly = true;
    this.valid.emit(null);
    // e.classList.remove('ng-valid');
    // e.classList.remove('ng-invalid');
  }

  /**
   * if validation end with status 406 email not valid
   * @param e
   */
  elementNotValid(e: HTMLInputElement) {
    this.validation = false;
    // e.readOnly = false;
    this.valid.emit(false);
    e.classList.add('ng-invalid');
    e.classList.remove('ng-valid');
  }

  /**
   * if validateion end with status 200 email successes
   * @param e
   */
  elementValid(e: HTMLInputElement) {
    this.validation = false;
    // e.readOnly = false;
    this.valid.emit(true);
    e.classList.add('ng-valid');
    e.classList.remove('ng-invalid');
  }

}
