import { IDictionary } from 'src/shared/interfaces/interfaces';
import { Category } from '../Category';
import { ImageD } from '../User';
import { Event } from '../Event';
import { PlatformHall } from '../PlatformHall';

export class BoxOfficeEventDto extends Event {
  abonementEvent: boolean;
  id: number;
  name: IDictionary;
  mainImageSmall: ImageD;
  eventCategory: Category[] = [];
  beginDate: string;
  platformHall: PlatformHall;
  minPrice: number;
  maxPrice: number;
  freeSeatsNumber: number;
  city: IDictionary;
  bookedTicketsNumber: number;
  boughtTicketsNumber: number;
  canRevertTicket: boolean;
  revertTicketFromNominal: number;
  boughtTicketsPrice?: number;
  revertedTicketsNumber?: number;
  revertedTicketsPrice?: number;
  isDiscountAvailable: boolean;
}
