import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IOnSelectTicketEvent } from '../../../events/event-action/event-action.component';

@Component({
  selector: 'app-event-no-schema',
  templateUrl: './event-no-schema.component.html',
  styleUrls: ['./event-no-schema.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventNoSchemaComponent {
  @Input() tickets: any[] = [];

  @Output() onSelectTicket: EventEmitter<IOnSelectTicketEvent> = new EventEmitter();

  isNoSchemaOrderModalVisible: boolean = false;
  orderTicketsNumber: number = 0;

  selectTicket(ticketGroupId: number): void {
    this.onSelectTicket.emit({ ticketGroupId });
  }
}
