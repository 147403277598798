import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-cg-reports-archive',
  templateUrl: './cg-reports-archive.component.html',
  styleUrls: ['./cg-reports-archive.component.css']
})
export class CgReportsArchiveComponent implements OnInit {
  public formGroup: FormGroup;
  public maxDate = Date.now();

  public selectedReports = [];

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      numberInvoice: [null, Validators.required],
      cashierWorkDay: [null, Validators.required],
      ownEventsOnly: '',
      type_of_payment: 'All',
    })
  }

  public onPrintSelect(isSelected: boolean): void {
    if (isSelected) {
      this.selectedReports.push('1');
    } else {
      this.selectedReports.splice(0, 1);
    }
  }

  public loadReport(): void { }
}
