import {Injectable} from '@angular/core';
import {Bin} from '../../../../shared/models/Bin';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentBarService {


  bin: Bin = new Bin();
  commission: any;
  private _bin = new Subject<Bin>();
  bin$ = this._bin.asObservable();
  private _send = new Subject<boolean>();
  send$ = this._send.asObservable();
  private _resive = new Subject<boolean>();
  resive$ = this._resive.asObservable();

  constructor() {

  }

  createPaymentRequest(bin: Bin, commission: any) {
    this.bin = bin;
    this.commission = commission;
    this._bin.next(bin);
  }

  sendPaymentRequest() {
    this._send.next(true);
  }

  resivePaymentRequest(b: boolean) {
    this._resive.next(b);
  }


}
