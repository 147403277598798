import { PlatformHall } from '../PlatformHall';
import { Video } from '../Video';
import { ImageD } from '../User';
import { IDictionary } from 'src/shared/interfaces/interfaces';

export class ClientPrevEventBaseDataDto {
  id: number;
  like: boolean;
  platformHall: PlatformHall;
  maxPrice: number;
  minPrice: number;
  name: IDictionary;
  dataBegin: string;
  beginSell: string;
  mainImage: ImageD;
  mainImageSmall: ImageD;
  promos: Video[] = [];
  sameEvent: ClientPrevEventBaseDataDto[] = [];
}
