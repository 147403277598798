import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ColorPrice } from '../../models/color-price';
import { IColorPrice } from 'src/app/client/client/events/event-action/event-action.component';

@Injectable({ providedIn: 'root' })
export class ColorPriceService {
  controller = '/color-price';

  constructor(private _httpClient: HttpClient) {}

  public getColorPriceForSchema(idEvent: number, idPartHall: number): Observable<ColorPrice[]> {
    return this._httpClient
      .get<ColorPrice[]>(this.controller + '/find-with-schema-by-part-hall-id-and-event-id/' + idEvent + '/' + idPartHall)
      .pipe(catchError(err => throwError(err)));
  }

  findWithOutSchemaByEventId(idEvent: number): Observable<ColorPrice[]> {
    return this._httpClient
      .get<ColorPrice[]>(this.controller + '/find-with-out-schema-by-part-hall-id-and-event-id/' + idEvent)
      .pipe(catchError(err => throwError(err)));
  }
  getColorPrices(eventId: number) {
    return this._httpClient.get<IColorPrice[]>(this.controller + '/find-by-event-id/' + eventId).pipe(catchError(err => throwError(err)));
  }
}
