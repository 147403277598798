<div class="container" *ngIf="invite">
  <div class="whiteContainer">
    <div class="filter_container_name">
      <p>{{user.firstName}} {{user.lastName}}</p>
    </div>
  </div>
  <div class="index_container_declaration">
    <div class="container-inline">
      <h2 class="main-h2">{{'reservs.reserv' | translate}}</h2>
      <p class="reserv-number text-style">№{{invite.order}}</p>
      <p class="reserv-number text-style">{{'reservation.date'|translate}}: {{invite.created|localdate}}</p>
    </div>
  </div>

  <div class="reserv-container">
    <div class="client-container">
      <div class="about-client">
        <p class="client-header text-style">{{'reservs.client' | translate}}</p>
        <p class="client-name text-style" *ngIf="invite.user">{{invite.user.firstName}} {{invite.user.lastName}}</p>
        <p class="client-about text-style" *ngIf="invite.user">{{invite.user.phone}}</p>
        <p class="client-about text-style" *ngIf="invite.user">{{invite.user.mail}}</p>
      </div>
    </div>
    <div class="container-reserves">
      <div class="white-container-reserv">
        <div class="reserves-tick">
          <div *ngFor="let one of invite.tickets; let i = index" class="reserv">
            <div class="reserv-done">
              <label style="display: block;cursor: pointer;width: 85%;margin: auto;">
                <input type="checkbox" id="check_active_{{one.id}}" name="city" class="check_active"
                       style="opacity: 0;width: 0;height: 0;display: block;"
                       (change)="changeTicketInBinForPrint(false, i, one.id)">
                <label for="check_active_{{one.id}}" class="label_wrapper">
                  <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#56CCF2" stroke-width="2"/>
                  </svg>
                </label>
              </label>
            </div>
            <div class="about-reserv">
              <p class="text-style tick-header">{{one.sector}}</p>
              <div class="tick-details" *ngIf="one.seatPosition">
                <div class="row">
                  <p class="details-tickets text-style">{{'reservs.row' | translate}}</p>
                  <div class="details text-style">{{one.row}}</div>
                </div>
                <div class="location" *ngIf="one.seatPosition">
                  <p class="details-tickets text-style">{{'reservs.location' | translate}}</p>
                  <div class="details text-style">{{one.seatPosition}}</div>
                </div>
                <div class="price">
                  <p class="details-tickets text-style">{{'reservs.price' | translate}}</p>
                  <div class="details text-style">{{one.price}}{{'money'|translate}}.</div>
                </div>
              </div>
            </div>
            <button class="butt-res">
              <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.79628 4.29655C9.79628 6.66207 8.21771 7.72414 6.26771 7.72414C4.31771 7.72414 2.73914 6.66207 2.73914 4.29655C2.73914 1.93103 4.31771 0 6.26771 0C8.21771 0 9.79628 1.93103 9.79628 4.29655Z"
                  fill="white"/>
                <path
                  d="M9.51786 7.67578C8.68214 8.35164 7.52143 8.68957 6.26786 8.68957C5.10714 8.68957 4.03929 8.39992 3.20357 7.82061C1.3 8.73785 0 10.7172 0 13.0344V13.5172C0 13.662 0 13.8551 0.0464286 13.9999H13C13 13.8551 13.0464 13.662 13.0464 13.5172V13.0344C13 10.6206 11.5607 8.54475 9.51786 7.67578Z"
                  fill="white"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="white-container-footer">
          <div class="selects">
            <label style="display: block;cursor: pointer;width: 85%;margin: auto;">
              <input type="checkbox" id="check_active_all" name="all" class="check_active"
                     style="opacity: 0;width: 0;height: 0;display: block;" (change)="changeTicketInBinForPrint(true)">
              <label for="check_active_all" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#56CCF2" stroke-width="2"/>
                </svg>
              </label>
              <span class="text-style text-select-all"> {{'reservs.selectall'|translate}}</span>
            </label>
          </div>
          <div class="butt-footer">
            <button class="btn btn-termo text-style"
                    (click)="printTickets('TERM')">{{'reservs.termo' | translate}}</button>
            <button class="btn btn-a text-style" (click)="printTickets('A4')">A4</button>
            <button class="btn btn-termo text-style"
                    (click)="printTickets('BIG')">{{'BIG' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-save">
      <div class="bttn-save">
        <button class="text-style save" routerLink="/invite">{{'reservs.save' | translate}}</button>
      </div>
    </div>
  </div>


</div>
