import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { IDictionary } from 'src/shared/interfaces/interfaces';

@Directive({
  selector: '[valueByLang]',
})
export class ValueByLang implements OnInit {
  @Input('dictionary')
  d: IDictionary;
  lang: string;

  constructor(private el: ElementRef, private _translateService: TranslateService, private renderer: Renderer2) {
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(
      next => {
        this.lang = next.lang;
        this.returnValue();
      },
      error => {
        console.error(error);
      },
    );
  }

  returnValue() {
    if (this.lang == 'ua') {
      this.el.nativeElement.innerText = this.d.valueUA;
    } else if (this.lang == 'ru') {
      this.el.nativeElement.innerText = this.d.valueRU;
    } else {
      this.el.nativeElement.innerText = this.d.valueEN;
    }
  }

  ngOnInit(): void {
    this.returnValue();
  }
}
