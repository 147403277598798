import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { LineChartComponent } from '@swimlane/ngx-charts';
import { EventService } from 'src/shared/service/backend/event.service';
import { MainLoaderService } from 'src/shared/service/rx/main-loader.service';
import { take } from 'rxjs/operators';

export class SerieCopy {
  name: Date;
  value: number;
}

export class GroupWrapperCopy {
  name: string;
  type: any;
  statusScan: any;
  series: SerieCopy[] = [];
}

export class EventTicketInfoDto {
  maxTicketCount: number;
  ticketCount: number;
  ticketSellCount: number;
  ticketOnlineCount: number;
  ticketOfflineCount: number;
  ticketBookedCount: number;
  promoCodeCount: number;
  bonusSellCount: number;
  bonusGetCount: number;
  ticketPrice: number;
  ticketSellPrice: number;
  ticketOnlinePrice: number;
  ticketOfflinePrice: number;
  ticketBookedPrice: number;
  priceVendorTicket?: string;
  countVendorTicket?: string;
  priceRevertedTicket?: string;
  countRevertedTicket?: string;
  priceCardTicket?: string;
  countCardTicket?: string;
  priceCashTicket?: string;
  countCashTicket?: string;
  countAllTicket?: number;
  priceAllTicket?: number;
  priceBoughtTicket?: string;
  countBoughtTicket?: number;
  priceHardLockTicket?: string;
  countHardLockTicket?: number;
  priceOnlineTicket?: string;
  countOnlineTicket?: number;
}

@Component({
  selector: 'app-event-general',
  templateUrl: './event-general.component.html',
  styleUrls: ['./event-general.component.css'],
})
export class EventGeneralComponent {
  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'дні';
  showYAxisLabel = true;
  yAxisLabel = 'суми з урахуванням усіх комісій';

  animations = true;
  param: string = 'vh';
  BOUGHT = '#27AE60';
  BOOKED = '#00DEFF';
  FREE = '#00ff54';
  PAID_BOOKED_WITH_PAY = '#00b5ff';
  waitingForPayment = '#0016ff';
  showTimeline: boolean = true;
  colorScheme = {
    domain: [this.FREE, this.BOUGHT, this.BOOKED, this.PAID_BOOKED_WITH_PAY],
  };
  // @ViewChild(LineChartComponent) _chart: LineChartComponent;
  list: GroupWrapperCopy[] = [];

  // line, area
  autoScale = true;

  statisticsInfo: any[] = [];

  infoAboutTickets: EventTicketInfoDto = new EventTicketInfoDto();

  constructor(
    private activateRoute: ActivatedRoute,
    private _loaderService: MainLoaderService,
    private _eventService: EventService,
    private ref: ChangeDetectorRef,
    private _translateService: TranslateService,
  ) {
    // this.ref.detach(); todo
    console.error = () => {};
    this._translateService.onLangChange.subscribe(
      next => {
        setTimeout(() => {
          this.ref.detectChanges();
        });
      },
      error => {
        console.error(error);
      },
    );
    this._loaderService.startLoader();
    this.activateRoute.params.pipe(take(1)).subscribe(param => {
      this.showTimeline = false;
      this._eventService.groupBinsByEvent(param['id']).subscribe(
        next => {
          this.parse(next);
        },
        error => {
          console.error(error);
        },
      );
      this._eventService.findAllParseEventColorPriceTicketDtoV2(param['id']).subscribe(
        next => {
          this.statisticsInfo = next;
          this._loaderService.endLoader();
          this.ref.detectChanges();
        },
        err => {
          this._loaderService.endLoader();
          console.error(err);
        },
      );
      this._loaderService.startLoader();
      this._eventService.finEventInfoDto(param['id']).subscribe(
        next => {
          this.infoAboutTickets = next;
          this._loaderService.endLoader();
          this.ref.detectChanges();
        },
        err => {
          this._loaderService.endLoader();
          console.error(err);
        },
      );
    });
  }

  parse(obj: any) {
    for (let i = 0; i < obj.length; i++) {
      let temp = new GroupWrapperCopy();
      if (obj[i].type == 'BOOKED_WITH_PAY') temp.name = 'заброньовано';
      else if (obj[i].type == 'BOOKED_WITHOUT_PAY') temp.name = 'користувач обирає квитки';
      else if (obj[i].type == 'BOUGHT') temp.name = 'оплачено';
      else if (obj[i].type == 'FREE') temp.name = 'запрошення';
      for (let j = 0; j < obj[i].series.length; j++) {
        let t = new SerieCopy();
        t.name = new Date(obj[i].series[j].name + 'T00:00:00.000Z');
        t.value = obj[i].series[j].value;
        temp.series.push(t);
      }
      this.list.push(temp);
    }
    this.showTimeline = true;
    this.ref.detectChanges();
  }

  trackById(index, obj) {
    return obj.id;
  }

  yAxisTickFormatting(s) {
    return s + ' грн';
  }

  xAxisTickFormatting(s) {
    let t = new Date(s);
    return t.toDateString().substr(4, t.toDateString().length);
  }

  onSelect(e): void {}
  onActivate(e): void {}
  onDeactivate(e): void {}
}
