<div class="container">
  <div class="whiteContainer">
    <div class="filter_container_name">
      <p>{{user.firstName}} {{user.lastName}}</p>
    </div>
  </div>
  <div class="index_container_declaration">
    <div class="container-inline">
      <svg [routerLink]="['..']"
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.0444 5.72693L16.0709 5.73273H4.69532L8.2714 2.14877C8.44652 1.9738 8.54257 1.73676 8.54257 1.48798C8.54257 1.2392 8.44652 1.00383 8.2714 0.828439L7.71496 0.271724C7.53999 0.0967479 7.30683 0 7.05818 0C6.8094 0 6.5761 0.096057 6.40113 0.271033L0.271036 6.40057C0.0953696 6.57624 -0.000687354 6.81023 3.70287e-06 7.05915C-0.000687354 7.30945 0.0953696 7.54358 0.271036 7.71897L6.40113 13.8491C6.5761 14.0239 6.80926 14.1201 7.05818 14.1201C7.30683 14.1201 7.53999 14.0238 7.71496 13.8491L8.2714 13.2923C8.44652 13.1176 8.54257 12.8843 8.54257 12.6356C8.54257 12.3869 8.44652 12.1659 8.2714 11.9911L4.65496 8.38708H16.0571C16.5695 8.38708 17 7.9455 17 7.43342V6.64603C17 6.13396 16.5568 5.72693 16.0444 5.72693Z"
          fill="#3473AE" />
      </svg>
      <h2 class="main-h2">{{'reservs.reserv' | translate}}</h2>
      <p class="reserv-number text-style">№{{bin.order}}</p>
      <p class="reserv-number text-style">{{'reservation.date'|translate}}: {{bin.created|localdate}}</p>
      <p class="reserv-number text-style"
        *ngIf="bin.event">{{'reservation.eventName'|translate}}:
        <span valueByLang
          [dictionary]="bin.event.name"></span>
      </p>
      <!--<p class="reserv-number text-style">{{'reservation.eventPlace'|translate}}:-->
      <!--<span valueByLang [dictionary]="bin.event.platformHall.city.dictionary"></span>,-->
      <!--<span valueByLang [dictionary]="bin.event.platformHall.name"></span>-->
      <!--</p>-->
    </div>
  </div>
  <div class="about-client">
    <div class="about"
      *ngIf="bin.user">
      <p class="client-header text-style">{{'reservs.client' | translate}}</p>
      <p class="client-name text-style">{{bin.user.firstName}} {{bin.user.lastName}}</p>
      <p class="client-about text-style">{{bin.user.phone}}</p>
      <p class="client-about text-style">{{bin.user.mail}}</p>
    </div>
  </div>
  <div class="about-cont">
    <div class="white-reserv">
      <div class="reserves-tick">
        <div class="ticket-container"
          *ngFor="let one of bin.tickets; let i = index">
          <div class="reserv">
            <div class="reserv-done">
              <label style="display: none;cursor: pointer;width: 85%;margin: auto;"
                [ngClass]="{'display_block':stay == 2}">
                <input type="checkbox"
                  id="check_active_{{i}}"
                  name="city"
                  class="check_active"
                  style="opacity: 0;width: 0;height: 0;display: block;"
                  (change)="changeTicketInBinForPrint(false, i, one.id)">
                <label for="check_active_{{i}}"
                  class="label_wrapper">
                  <svg style="display: none;"
                    width="11"
                    height="14"
                    viewBox="0 0 11 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 3.88889L3.85714 10L9 0"
                      transform="translate(1 1)"
                      stroke="#56CCF2"
                      stroke-width="2" />
                  </svg>
                </label>
              </label>
            </div>
            <div class="about-reserv">
              <p class="text-style tick-header">{{one.sector}}</p>
              <div class="tick-details">
                <div class="row"
                  *ngIf="one.seatPosition">
                  <p class="details-tickets text-style">{{'reservs.row' | translate}}</p>
                  <div class="details text-style">{{one.row}}</div>
                </div>
                <div class="location"
                  *ngIf="one.seatPosition">
                  <p class="details-tickets text-style">{{'reservs.location' | translate}}</p>
                  <div class="details text-style">{{one.seatPosition}}</div>
                </div>
                <div class="price">
                  <p class="details-tickets text-style">{{'reservs.price' | translate}}</p>
                  <div class="details text-style">{{one.price}} {{'money'|translate}}.</div>
                </div>
              </div>
            </div>
            <button class="butt-res"
              id="changeCol_{{i}}"
              [ngClass]="{'backgr-gray': (!one.firstName) || (!one.lastName)}"
              (click)="delailsPop('detPop_' + i);"
              title="{{'reservs.named_tickets_info'|translate}}">
              <svg width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.79628 4.29655C9.79628 6.66207 8.21771 7.72414 6.26771 7.72414C4.31771 7.72414 2.73914 6.66207 2.73914 4.29655C2.73914 1.93103 4.31771 0 6.26771 0C8.21771 0 9.79628 1.93103 9.79628 4.29655Z"
                  fill="white" />
                <path
                  d="M9.51786 7.67578C8.68214 8.35164 7.52143 8.68957 6.26786 8.68957C5.10714 8.68957 4.03929 8.39992 3.20357 7.82061C1.3 8.73785 0 10.7172 0 13.0344V13.5172C0 13.662 0 13.8551 0.0464286 13.9999H13C13 13.8551 13.0464 13.662 13.0464 13.5172V13.0344C13 10.6206 11.5607 8.54475 9.51786 7.67578Z"
                  fill="white" />
              </svg>
            </button>
          </div>
          <div class="client-details"
            id="detPop_{{i}}">
            <div class="client-details-form"
              [ngClass]="{'disable': stay == 2}">
              <form>
                <input type="text"
                  class="input-client text-style"
                  [(ngModel)]="one.firstName"
                  [ngModelOptions]="{standalone: true}"
                  required
                  placeholder="{{'reservation.name' | translate}}">
                <input type="text"
                  class="input-client text-style"
                  [(ngModel)]="one.lastName"
                  [ngModelOptions]="{standalone: true}"
                  required
                  placeholder="{{'reservation.lastName' | translate}}">
              </form>
            </div>
          </div>
        </div>
      </div>
      <p class="client-name text-style text-right"
        *ngIf="stay!=2">До сплати: {{bin.totalPrice}}грн.</p>
      <div class="white-container-footer">
        <!--form-->
        <div class="cont-form"
          *ngIf="stay==0 || stay==1">
          <form *ngIf="bin.user">
            <label class="labels">
              <input style="width: 200px;"
                disabled
                readonly
                type="text"
                name="phone"
                required
                minlength="18"
                placeholder="{{'reservation.phone' | translate}}"
                class="text-style"
                value="{{bin.user.firstName}}">
            </label>
            <label class="labels">
              <input style="width: 200px;"
                disabled
                readonly
                type="text"
                name="phone"
                required
                minlength="18"
                placeholder="{{'reservation.phone' | translate}}"
                class="text-style"
                value="{{bin.user.lastName}}">
            </label>
            <label class="labels">
              <input style="min-width: 35%"
                type="text"
                name="email"
                [(ngModel)]="bin.user.mail"
                required
                [pattern]="EMAIL_PATTERN"
                #mail
                placeholder="Email"
                class="text-style">
            </label>
            <label class="labels">
              <input style="width: 200px;"
                disabled
                readonly
                type="text"
                name="phone"
                required
                minlength="18"
                [textMask]="{mask: PHONE_PATTERN, guide: false, modelClean: true}"
                #phone
                placeholder="{{'reservation.phone' | translate}}"
                class="text-style"
                value="{{bin.user.phone}}">
            </label>
          </form>
        </div>
        <button class="saves-btn text-style pointer"
          *ngIf="stay==0"
          (click)="changeWay(1)">{{'reservation.save' | translate}}</button>
        <div class="buttons"
          *ngIf="stay==1">
          <button class="btns btn-cash text-style pointer"
            (click)="sendBuyBin('CASH')">{{'reservation.cash' | translate}}</button>
          <button class="btns btn-term text-style pointer"
            (click)="sendBuyBin('CARD')">{{'reservation.term' | translate}}</button>
        </div>
        <div class="selects"
          *ngIf="stay==2">
          <label style="display: block;cursor: pointer;width: 85%;margin: auto;">
            <input type="checkbox"
              id="check_active_all"
              name="all"
              class="check_active"
              style="opacity: 0;width: 0;height: 0;display: block;"
              (change)="changeTicketInBinForPrint(true)">
            <label for="check_active_all"
              class="label_wrapper">
              <svg style="display: none;"
                width="11"
                height="14"
                viewBox="0 0 11 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 3.88889L3.85714 10L9 0"
                  transform="translate(1 1)"
                  stroke="#56CCF2"
                  stroke-width="2" />
              </svg>
            </label>
            <span class="text-style text-select-all"> {{'reservs.selectall'|translate}}</span>
          </label>
        </div>
        <div *ngIf="stay==2"
          class="cont-btns">
          <button class="btn btn-termo text-style pointer"
            (click)="printTickets('TERM')">{{'reservs.termo' | translate}}</button>
          <button class="btn btn-a text-style pointer"
            (click)="printTickets('A4')">A4</button>
          <button class="btn btn-termo text-style pointer"
            (click)="printTickets('BIG')">BIG</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-ready"
    *ngIf="stay==2">
    <button class="ready text-style"
      routerLink="/">{{'reservation.ready' | translate}}</button>
  </div>
</div>
