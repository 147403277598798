import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { AddVendorOptions, LockFromSchemeTicketOption, Vendor, VendorBuyBinOptions } from '../../../shared/types';
import { Bin } from 'src/shared/models/Bin';
import { IBin } from 'src/app/client/client/events/event-action/event-action.component';

export interface VendorProcessDto {
  binId?: number;
  idEvent: number;
  idSeat: number;
  idTicket: number;
}

export interface VendorFanzoneDto {
  binId?: number;
  fanzoneName: string;
  limit: number;
  seatId: string;
}

export interface VendorWithoutDto {
  binId?: number;
  ticketGroupWithOutSchemeId: number;
  limit: number;
}

export interface IPagination<T> {
  content: T[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: any;
  totalElements: number;
  totalPages: number;
}

@Injectable({ providedIn: 'root' })
export class VendorsService {
  constructor(private httpClient: HttpClient) {}

  selectTickets(binId: number, ticketsIds: number[], isLock: boolean) {
    return this.httpClient
      .post(`/vendors/${isLock ? 'lock' : 'unlock'}-ticket?binId=${binId}${ticketsIds.map(id => '&ticketId=' + id).join('')}`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
  // unlockTicket(binId: number, ticketsIds: number[]) {
  //   return this.httpClient
  //     .post(`/vendors/unlock-ticket?binId=${binId}&ticketId=${ticketId}`, {})
  //     .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  // }

  getVendorBinDetails(binId: string) {
    return this.httpClient.get<IBin>(`/vendors/find-bin-by-id/${binId}`).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
  getShortDtoVendorList(size = 20) {
    return this.httpClient
      .get<{ content: any[] }>(`/vendors/find-all-vendors-short-dto?size=${size}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
  getVendorsBins(vendorId: number, page: number, binStatus: 'ARCHIVE' | 'ACTIVE') {
    return this.httpClient
      .get<{ content: any[]; totalElements: number }>(
        `/vendors/find-vendors-bin-dto/${vendorId}?size=10&sort=last_modified,DESC&page=${page}&binStatusFilter=${binStatus}`,
      )
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  createVendor(vendor: AddVendorOptions) {
    return this.httpClient.post<void>('/vendors', vendor).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  getVendorsDetailed(page: number) {
    return this.httpClient
      .get<IPagination<Vendor>>(`/vendors/find-vendors-detailed-dto?size=20&page=${page}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  removeOrderByBin(order: string): Observable<void> {
    return this.httpClient.delete<void>(`/vendors/bin/${order}`).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  buyBinByVendor(options: VendorBuyBinOptions): Observable<void> {
    let params = new HttpParams();
    params = params.set('vendorId', String(options.vendorId));
    params = params.set('binId', String(options.orderId));
    params = params.set('typeOfPayment', String(options.typeOfPayment));

    return this.httpClient
      .post<void>('/vendors/buy-bin-vendor', {}, { params })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  createVendorOrder(binId: number): Observable<string> {
    let params = new HttpParams();
    params = params.set('binId', String(binId));

    return this.httpClient.post('/vendors/finish-bin-updates', {}, { params, responseType: 'text' });
  }

  //  getTicketsForNoSchema(eventId): Observable<any> {
  //   return this.httpClient.get(`/color-price/find-with-out-schema-by-part-hall-id-and-event-id/${eventId}`)
  // }

  controller = '/vendors';

  save(obj: Vendor) {
    return this.httpClient.post(this.controller, obj).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  finishCreatingBin(binId: string) {
    return this.httpClient
      .post(this.controller + '/finish-bin-updates', {}, { params: new HttpParams().set('binId', binId) })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  findAll(page: number, size: number) {
    return this.httpClient
      .get(this.controller + '/findAll', { params: new HttpParams().set('page', page.toString()).set('size', size.toString()) })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  findAllByEvent(page: number, size: number, eventId) {
    return this.httpClient
      .get(this.controller + '/findAllByEvent/' + eventId, {
        params: new HttpParams().set('page', page.toString()).set('size', size.toString()),
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  findOne(val: string) {
    return this.httpClient.get(this.controller + '/' + val).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  delete(val: string) {
    return this.httpClient.delete(this.controller + '/' + val).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  deleteBin(val: string) {
    return this.httpClient.delete(this.controller + '/bin/' + val).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  update(obj: Vendor, id) {
    return this.httpClient
      .put(this.controller + '/' + id, JSON.stringify(obj))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  lock(data) {
    return this.httpClient
      .post(this.controller + '/lock', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  unLock(data) {
    return this.httpClient
      .post(this.controller + '/unlock', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  lockFanzone(data) {
    return this.httpClient
      .post(this.controller + '/lockFanzone', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  unLockFanzone(data) {
    return this.httpClient
      .post(this.controller + '/unlockFanzone', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  lockWithout(data) {
    return this.httpClient
      .post(this.controller + '/ticketWithoutScheme/lock', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  unLockWithout(data) {
    return this.httpClient
      .post(this.controller + '/ticketWithoutScheme/unlock', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  createBin(eventId: number, vendorId: number): Observable<any> {
    return this.httpClient
      .post(
        this.controller + '/createBin',
        {},
        {
          params: new HttpParams().set('eventId', eventId.toString()).set('vendorId', vendorId.toString()),
          responseType: 'text',
        },
      )
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  findBin(order: string) {
    return this.httpClient
      .get(this.controller + '/find-bin-by-order-id/' + order)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  updateBin(binId: string, newVendorId: number, oldVendorId: number) {
    return this.httpClient.post(
      this.controller + '/update-bin-vendor',
      {},
      {
        params: new HttpParams()
          .set('oldVendorId', oldVendorId.toString())
          .set('newVendorId', newVendorId.toString())
          .set('binId', binId.toString()),
      },
    );
  }

  // Refactored hall
  // bookSeat(options): Observable<any> {
  //   return this.httpClient
  //     .post(`${this.controller}/booking-new`, options)
  //     .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  // }
}
