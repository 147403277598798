import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserDetailsService } from '../../service/user-details-service.service';

@Injectable({
  providedIn: 'root',
})
export class AdminCanActive implements CanActivate {

  constructor(private _userDetailsService: UserDetailsService, private _router: Router, @Inject(PLATFORM_ID) private platformId: Object) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._userDetailsService.checkAuth()) {
      if (localStorage.getItem('ROLE') == 'CASHIER' || localStorage.getItem('ROLE') == 'CASHIER_PLUS') {
        return true;
      } else {
        this._router.navigateByUrl('/sign-in');
        return false;
      }
    } else {
      this._router.navigateByUrl('/sign-in');
      return false;
    }
  }
}
