import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { User } from '../../../../../shared/models/User';
import { Bin } from '../../../../../shared/models/Bin';
import { PdfService } from '../../../../../shared/service/backend/pdf.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeOfPayment } from '../../../../../shared/models/enums/enums';
import { BinOrderPayBoxOfficeRequestDto } from '../../../../../shared/models/dto/BinOrderPayBoxOfficeRequestDto';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { Config } from '../../../../../shared/config/config';
import { NgModel } from '@angular/forms';
import { SideInfo, SideInfoRxService } from '../../../../source/side-info-bar/side-info-bar.component';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';

@Component({
  selector: 'app-reserve-container-one',
  templateUrl: './reserve-container-one.component.html',
  styleUrls: ['./reserve-container-one.component.css'],
  providers: [PdfService, BookingService]
})
export class ReserveContainerOneComponent implements OnInit {

  stay: number = 0;

  bin: Bin = new Bin();

  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  @ViewChild('mail') mail: ElementRef;
  @ViewChild('phone') phone: ElementRef;

  EMAIL_PATTERN = Config.EMAIL_MASK;
  PHONE_PATTERN = Config.PHONE_MASK;

  constructor(private _userDetailService: UserDetailsService, private _pdfService: PdfService,
    private _router: Router, private _loaderService: MainLoaderService,
    private _bookingService: BookingService, private _activatedRoute: ActivatedRoute,
    private _sideInfoRxService: SideInfoRxService) {
    this.user = this._userDetailService.getUser();
    this._userDetailService.user$.subscribe(next => {
      this.user = next;
    }, error => {
      console.error(error);
    });
    this._activatedRoute.params.subscribe(next => {
      this._bookingService.findByBinId(next['id']).subscribe(next => {
        this.bin = next;
      }, error => {
        console.error(error);
      });
    }, error => {
      console.error(error);
    });
  }

  ngOnInit() {
  }

  changeWay(i: number, mail?: HTMLInputElement, phone?: HTMLInputElement) {
    if (this.stay == 0) {
      if (this.phone.nativeElement.classList.contains('ng-invalid')) {
        return;
      }
    }
    this.stay = i;
    this.setChecked();
  }

  setChecked() {
    if ((<HTMLInputElement>document.getElementById('check_active_all'))) {
      (<HTMLInputElement>document.getElementById('check_active_all')).checked = true;
      this.changeTicketInBinForPrint(true);
    } else {
      setTimeout(() => {
        this.setChecked();
      }, 1000);
    }
  }

  sendBuyBin(type: TypeOfPayment) {
    this._loaderService.startLoader();
    let tempBin: BinOrderPayBoxOfficeRequestDto = new BinOrderPayBoxOfficeRequestDto();
    tempBin.typeOfPayment = type;
    tempBin.order = this.bin.order;
    tempBin.user = this.bin.user as any;
    this.bin.tickets.forEach(value => {
      if (value.firstName && value.lastName) {
        tempBin.tickets.push(value);
      }
    });
    tempBin.comment = '';
    this._bookingService.clientOrderBookedBuyBin(tempBin).subscribe(next => {
      // this.orderString = '';
      this._loaderService.endLoader();
      this.changeWay(2);
      this._sideInfoRxService.add(new SideInfo().setInfo('Замовлення успішно куплено!').setDate());
    }, error => {
      this._loaderService.endLoader();
      this._sideInfoRxService.add(new SideInfo().setInfo('Сталась помилка!').setDate().setError(''));
      console.error(error);
    });
  }

  ticketsForPrint: number[] = [];

  changeTicketInBinForPrint(all: boolean, i?: number, idTicket?: number) {
    if (all) {
      this.ticketsForPrint = [];
      this.bin.tickets.forEach((value, index) => {
        if ((<HTMLInputElement>document.getElementById('check_active_all')).checked) {
          this.ticketsForPrint.push(value.id);
          (<HTMLInputElement>document.getElementById('check_active_' + index)).checked = true;
        } else {
          this.ticketsForPrint = [];
          (<HTMLInputElement>document.getElementById('check_active_' + index)).checked = false;
        }
      });
    } else {
      if ((<HTMLInputElement>document.getElementById('check_active_' + i)).checked == false) {
        (<HTMLInputElement>document.getElementById('check_active_' + i)).checked = false;
        this.ticketsForPrint = this.ticketsForPrint.filter(value => value != idTicket);
        if (this.ticketsForPrint.length == 0 || (this.ticketsForPrint.length < this.bin.tickets.length)) {
          (<HTMLInputElement>document.getElementById('check_active_all')).checked = false;
        }
      } else {
        (<HTMLInputElement>document.getElementById('check_active_' + i)).checked = true;
        this.ticketsForPrint.push(idTicket);
        if (this.ticketsForPrint.length == this.bin.tickets.length) {
          (<HTMLInputElement>document.getElementById('check_active_all')).checked = true;
        }
      }
    }
  }

  printTickets(typePrint: 'TERM' | 'A4' | 'BIG') {
    this._loaderService.startLoader();
    if (this.ticketsForPrint.length == 0) {
      this._sideInfoRxService.add(new SideInfo().setInfo('Позначте які квитки для друку!').setDate().setError(''));
      this._loaderService.endLoader();
      return;
    }
    if (typePrint == 'TERM') {
      this._pdfService.generatePdfTerm(this.ticketsForPrint).subscribe(next => {
        let mediaType = 'application/pdf';
        let blob = new Blob([next], { type: mediaType });
        this.printDocument('ticket-document', URL.createObjectURL(blob));
        this._loaderService.endLoader();
        // if (!confirm('Бажаєте ще друкувати?')) {
        //   this._router.navigateByUrl('/reserve');
        // }
      }, error => {
        this._loaderService.endLoader();
        this._sideInfoRxService.add(new SideInfo().setInfo('Сталася помилка!').setDate().setError(''));
        console.error(error);
      });
    } else if (typePrint == 'BIG') {
      this._pdfService.generateBigPdfTerm(this.ticketsForPrint).subscribe(next => {
        let mediaType = 'application/pdf';
        let blob = new Blob([next], { type: mediaType });
        this.printDocument('ticket-document', URL.createObjectURL(blob));
        this._loaderService.endLoader();
        // if (!confirm('Бажаєте ще друкувати?')) {
        //   this._router.navigateByUrl('/');
        // }
      }, error => {
        this._loaderService.endLoader();
        console.error(error);
      });
    } else {
      this._pdfService.generatePdfA4(this.ticketsForPrint).subscribe(next => {
        let mediaType = 'application/pdf';
        let blob = new Blob([next], { type: mediaType });
        this.printDocument('ticket-document', URL.createObjectURL(blob));
        this._loaderService.endLoader();
        // if (!confirm('Бажаєте ще друкувати?')) {
        //   this._router.navigateByUrl('/reserve');
        // }
      }, error => {
        this._loaderService.endLoader();
        this._sideInfoRxService.add(new SideInfo().setInfo('Сталася помилка!').setDate().setError(''));
        console.error(error);
      });
    }
  }

  printDocument(documentId, path) {
    // let doc: any = document.getElementById(documentId);
    // doc.src = path;
    // this.printEmb(doc);
    this.printPdf(path);
    //Wait until PDF is ready to print
  }

  printPdf(url) {
    let iframe;
    iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = url;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }


  delailsPop(id: string) {
    document.getElementById(id).classList.toggle('display-flex');
  }

  changeColor(id: string) {
    document.getElementById(id).classList.toggle('backgr-gray');
  }

}
