import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BinStatus, CollectionStatus } from '../../models/enums/enums';

@Injectable({ providedIn: 'root' })
export class ExcelBuilderService {
  private controller: string = '/excel-builder';

  constructor(private _httpClient: HttpClient) {}

  createTicketRevertHistoryExcel(page: number, size: number, sort: 'ASC' | 'DESC', form?: HTMLFormElement) {
    let body = `?page=${page}&size=${size}&sort=date,${sort}&`;
    let data = new FormData(form);
    body += this.parseFormData(data, 'paymasters');
    body += this.parseFormData(data, 'eventId');
    body += this.parseFormData(data, 'platformHallsId');
    body += this.parseFormData(data, 'dateFrom');
    body += this.parseFormData(data, 'dateTo');
    return this._httpClient.get(this.controller + '/box-office/create-ticket-revert-history-excel' + body, { responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = `Історія_Поверення_Квитків_${new Date().toISOString().slice(0, 10)}.xls`;
        a.click();
        return value;
      }),
      catchError(err => throwError(err)),
    );
  }

  createCollectionExcel(page: number, size: number, status: CollectionStatus, form?: HTMLFormElement): Observable<any> {
    let body = `?page=${page}&size=${size}&status=${status}&`;
    if (form) {
      let data = new FormData(form);
      body += this.parseFormData(data, 'paymasters');
      body += this.parseFormData(data, 'dateFrom');
      body += this.parseFormData(data, 'dateTo');
    }
    return this._httpClient.get(this.controller + '/box-office/create-collection-excel' + body, { responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = 'Інкасація.xls';
        a.click();
        return value;
      }),
      catchError(error => throwError(error)),
    );
  }

  createBinHistoryBoxOfficeExcel(
    page: number,
    size: number,
    statusBin: BinStatus,
    sort: 'ASC' | 'DESC',
    options: Record<string, string | number> = {},
  ): Observable<Blob> {
    let params = new HttpParams();
    const { type_of_payment, sales_channel, dateFrom, dateTo, event_id, platform_hall, user_id } = options as any;

    params = params.set('page', String(page));
    params = params.set('size', String(size));
    params = statusBin ? params.set('statusBin', String(statusBin)) : params;
    params = sort ? params.set('sort', 'created,' + String(sort)) : params;

    [type_of_payment, sales_channel, event_id, platform_hall, user_id]
      .filter(el => el && el.value && el.value !== 'null')
      .forEach(el => {
        params = params.set('type_of_payment', String(el.value));
      });
    [dateFrom, dateTo]
      .filter(el => el && el.value && el.value !== 'null')
      .forEach(el => {
        params = params.set('type_of_payment', String(el.value) + 'T23:59');
      });

    return this._httpClient
      .get(`${this.controller}/box-office/create-bin-history-box-office-excel`, { params, responseType: 'blob' })
      .pipe(catchError(err => throwError(err)));
  }

  parseFormData(data, name): string {
    let body = '';
    for (let i = 0; i < data.getAll(name).length; i++) {
      if (data.getAll(name)[i] + '' != 'null' && data.getAll(name)[i] + '' != '') body += name + '=' + data.getAll(name)[i] + '&';
    }
    return body;
  }

  public generateEventsReport(options): Observable<any> {
    let params = new HttpParams();
    for (const key in options) {
      if (options[key]) {
        params = params.set(key, options[key]);
      }
    }
    return this._httpClient.get('/excel-report-builder/events-report-new', { responseType: 'blob', params }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = 'Report.xls';
        a.click();
        return value;
      }),
      catchError(error => throwError(error)),
    );
  }

  public getReportFromExcel(options): Observable<any> {
    let params = new HttpParams();
    for (const key in options) {
      if (options[key] && options[key] !== 'null') {
        params = params.set(key, options[key]);
      }
    }
    return this._httpClient.get('/excel-report-builder/events-report', { params });
  }
}
