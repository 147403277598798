import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Bin } from '../../models/Bin';
import { Booking } from '../../models/Booking';
import { Ticket } from '../../models/Ticket';
import { TicketOrderWithOutSchemDto } from '../../models/dto/TicketOrderWithOutSchemDto';
import { BinStatus, EventFilterType } from '../../models/enums/enums';
import { Page } from '../../models/Page';
import { BoxOfficeEventDto } from '../../models/dto/BoxOfficeEventDto';
import { BinOrderPayBoxOfficeRequestDto } from '../../models/dto/BinOrderPayBoxOfficeRequestDto';
import { PlatformHall } from '../../models/PlatformHall';
import { BinHistoryBoxOfficeDto } from '../../models/dto/BinHistoryBoxOfficeDto';
import { UserWithBoxOfficeDto } from '../../models/dto/UserWithBoxOfficeDto';
import { LockOption } from 'src/shared/types/interfaces/event-lock.interfaces';

@Injectable({ providedIn: 'root' })
export class BookingService {
  controller = '/box-office-booking/v1';

  constructor(private _httpClient: HttpClient) {}

  clientBooking(bin: Booking): Observable<any> {
    return this._httpClient.post(this.controller + '/client/booking', JSON.stringify(bin)).pipe(catchError(err => throwError(err)));
  }

  public bookSeat(bookOption: LockOption): Observable<any> {
    return this._httpClient.post(this.controller + '/client/booking-new', bookOption).pipe(catchError(err => throwError(err)));
  }

  clientCreateBin(id: number): Observable<any> {
    return this._httpClient
      .post(
        this.controller + '/client/create-bin',
        {},
        {
          params: new HttpParams().set('id', id + ''),
          responseType: 'text',
        },
      )
      .pipe(catchError(err => throwError(err)));
  }

  clientDeleteBin(order: string): Observable<any> {
    return this._httpClient
      .delete<any>(this.controller + '/client/delete-bin', { params: new HttpParams().set('order', order) })
      .pipe(catchError(err => throwError(err)));
  }

  boxDeleteBin(order: string, reason: string): Observable<any> {
    return this._httpClient
      .delete<any>(this.controller + '/box-office/delete-bin', {
        params: new HttpParams().set('order', order).set('text', reason),
      })
      .pipe(catchError(err => throwError(err)));
  }

  findBinByOrderId(order: string): Observable<Ticket[]> {
    return this._httpClient.get<Ticket[]>(this.controller + '/find-bin-by-order-id/' + order).pipe(catchError(err => throwError(err)));
  }

  clientOrderInviteBin(bin: Bin): Observable<any> {
    return this._httpClient
      .post('/v1/client/order-invite-bin', JSON.stringify(bin), { responseType: 'text' })
      .pipe(catchError(err => throwError(err)));
  }

  clientOrderBuyBin(bin: any): Observable<any> {
    return this._httpClient.post(this.controller + '/client/order-buy-bin', JSON.stringify(bin)).pipe(catchError(err => throwError(err)));
  }

  clientOrderBookedBuyBin(bin: BinOrderPayBoxOfficeRequestDto): Observable<any> {
    return this._httpClient
      .post(this.controller + '/client/order-booked-buy-bin', JSON.stringify(bin))
      .pipe(catchError(err => throwError(err)));
  }

  finishedOrderBuy(data: string, sign: string, url: string): Observable<any> {
    return this._httpClient
      .post(url, new FormData(), { params: new HttpParams().set('data', data).set('sign', sign) })
      .pipe(catchError(err => throwError(err)));
  }

  findByOrder(order: string, idEvent: number, id: number): Observable<any> {
    return this._httpClient
      .get(this.controller + `/client/find-by-order/${order}/${idEvent}/${id}`)
      .pipe(catchError(err => throwError(err)));
  }

  clientPrice(order: string): Observable<any> {
    return this._httpClient.get(this.controller + `/client/price/${order}`).pipe(catchError(err => throwError(err)));
  }

  clientPriceWithBookedCommission(order: string, promo?: string): Observable<any> {
    return this._httpClient.get(this.controller + `/client/price-with-booked-commission/${order}`).pipe(catchError(err => throwError(err)));
  }

  clientOrderBookedBin(bin: BinOrderPayBoxOfficeRequestDto): Observable<any> {
    return this._httpClient
      .post(this.controller + '/client/order-booked-bin', JSON.stringify(bin))
      .pipe(catchError(err => throwError(err)));
  }

  onlinefindAllFreeTicketByEventIdWithOutSchema(id: number): Observable<TicketOrderWithOutSchemDto[]> {
    return this._httpClient
      .get<TicketOrderWithOutSchemDto[]>(this.controller + '/client/online/find-all-free-ticket-by-event-id-with-out-schema/' + id)
      .pipe(catchError(err => throwError(err)));
  }

  getAllAvailableTicketsInPartHall(eventId: number, partId: number): Observable<Ticket[]> {
    return this._httpClient
      .get<Ticket[]>(this.controller + '/client/online/find-all-free-ticket-by-event-id-and-part-hall-id/' + eventId + '/' + partId)
      .pipe(catchError(err => throwError(err)));
  }

  getAllPayedTicketsInPartHall(eventId: number, partId: number): Observable<Ticket[]> {
    return this._httpClient
      .get<Ticket[]>(
        this.controller + '/client/online/find-all-booked-with-pay-ticket-by-event-id-and-part-hall-id/' + eventId + '/' + partId,
      )
      .pipe(catchError(err => throwError(err)));
  }

  getAllBookedTicketsInPartHall(eventId: number, partId: number): Observable<Ticket[]> {
    return this._httpClient
      .get<Ticket[]>(this.controller + '/client/online/find-all-booked-auto-ticket-by-event-id-and-part-hall-id/' + eventId + '/' + partId)
      .pipe(catchError(err => throwError(err)));
  }

  public getInvitesByEvent(eventId: string): Observable<Bin[]> {
    return this._httpClient
      .get<Bin[]>(`/bin/find-bin-invite-dto/${eventId}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  filterEventsBoxOfficeStatistic(
    size: number,
    page: number,
    eventFilterType: EventFilterType,
    cityId: number,
    platformHallId: number,
    categoryId: number,
    month: number,
    dateFrom: string,
    dateTo: string,
    sort: 'ASC' | 'DESC',
    eventStatus: 'ACTIVE' | 'ARCHIVE' = 'ACTIVE',
  ): Observable<Page<BoxOfficeEventDto>> {
    let body = `?size=${size}&page=${page}&sort=name,${sort}&`;
    body += this.parse(eventFilterType, 'eventFilterType');
    body += this.parse(cityId, 'cityId');
    body += this.parse(platformHallId, 'platformHallId');
    body += this.parse(categoryId, 'categoryId');
    body += this.parse(eventStatus, 'eventStatus');
    if (month != undefined) {
      body += this.parse(month + 1, 'month');
    }
    if (!dateTo || dateTo.trim() != '') {
      body += this.parse(dateTo, 'dateTo', true);
    }
    if (!dateFrom || dateFrom.trim() != '') {
      body += this.parse(dateFrom, 'dateFrom', true);
    }
    return this._httpClient
      .get<Page<BoxOfficeEventDto>>(this.controller + '/filter-events-box-office-statistic' + body)
      .pipe(catchError(err => throwError(err)));
  }

  filterEventsBoxOfficeStatisticByDate(
    size: number,
    page: number,
    eventFilterType: EventFilterType,
    cityId: number,
    platformHallId: number,
    categoryId: number,
    month: number,
    dateFrom: string,
    dateTo: string,
    sort: 'ASC' | 'DESC',
    byDate: boolean,
  ): Observable<Page<BoxOfficeEventDto>> {
    let body = `?size=${size}&page=${page}&sort=name,${sort}&byDate=${byDate}&`;
    body += this.parse(eventFilterType, 'eventFilterType');
    body += this.parse(cityId, 'cityId');
    body += this.parse(platformHallId, 'platformHallId');
    body += this.parse(categoryId, 'categoryId');
    if (month != undefined) {
      body += this.parse(month + 1, 'month');
    }
    if (!dateTo || dateTo.trim() != '') {
      body += this.parse(dateTo, 'dateTo', true);
    }
    if (!dateFrom || dateFrom.trim() != '') {
      body += this.parse(dateFrom, 'dateFrom', true);
    }
    return this._httpClient
      .get<Page<BoxOfficeEventDto>>(this.controller + '/filter-events-box-office-statistic-by-date' + body)
      .pipe(catchError(err => throwError(err)));
  }

  filterEventsBoxOfficeStatisticByDateV2(
    size: number,
    page: number,
    eventFilterType: EventFilterType,
    cityId: number,
    platformHallId: number,
    categoryId: number,
    month: number,
    dateFrom: string,
    dateTo: string,
    sort: 'ASC' | 'DESC',
    byDate: boolean,
    researcher?: string,
    eventId?: string,
  ): Observable<Page<BoxOfficeEventDto>> {
    let body = `?size=${size}&page=${page}&sort=name,${sort}&byDate=${byDate}&`;
    body += this.parse(eventFilterType, 'eventFilterType');
    body += this.parse(cityId, 'cityId');
    body += this.parse(platformHallId, 'platformHallId');
    body += this.parse(categoryId, 'categoryId');
    if (researcher) {
      body += this.parse(researcher, 'researcher');
    }
    if (month != undefined) {
      body += this.parse(month + 1, 'month');
    }
    if (!dateTo || dateTo.trim() != '') {
      body += this.parse(dateTo, 'dateTo', true);
    }
    if (!dateFrom || dateFrom.trim() != '') {
      body += this.parse(dateFrom, 'dateFrom', true);
    }
    if (eventId) {
      body += '&eventId=' + eventId;
    }

    return this._httpClient
      .get<Page<BoxOfficeEventDto>>('/box-office-statistic/v2/filter-events-box-office-statistic-by-date' + body)
      .pipe(catchError(err => throwError(err)));
  }
  parse(value, name, type: boolean = false): string {
    let body = '';
    if (typeof value == 'number' && value == 0) {
      value = '0';
    }
    if (value == '' || value == undefined || value == 'undefined' || value == null) {
      return body;
    }
    if (type) {
      body += name + '=' + value + 'T23:59&';
      return body;
    }
    body += name + '=' + value + '&';
    return body;
  }

  findAllAvailablePlatformHallsForUser(page: number, count: number, form?: HTMLFormElement): Observable<Page<PlatformHall>> {
    let body = `?page=${page}&size=${count}&`;
    let data = new FormData(form);
    body += this.parseFormData(data, 'cityId');
    body += this.parseFormData(data, 'categoryId');
    body += this.parseFormData(data, 'platformHallId');
    body += this.parseFormData(data, 'researcher');
    return this._httpClient
      .get<Page<PlatformHall>>(`${this.controller}/net-box/find-all-available-for-user` + body)
      .pipe(catchError(err => throwError(err)));
  }

  filterForInvitationFree(page: number, size: number, research: string): Observable<Page<any>> {
    return this._httpClient
      .get<Page<any>>(this.controller + '/box-office/filter-for-invitation-free', {
        params: new HttpParams()
          .set('page', page + '')
          .set('size', size + '')
          .set('researcher', research),
      })
      .pipe(catchError(err => throwError(err)));
  }

  filterForBooked(page: number, size: number, research: string): Observable<Page<any>> {
    let params = new HttpParams();
    params = params.set('page', String(page));
    params = params.set('size', String(size));
    params = research ? params.set('researcher', String(research)) : params;

    return this._httpClient
      .get<Page<any>>(`${this.controller}/box-office/filter-for-invitation-booked?sort=created,DESC`, { params })
      .pipe(catchError(err => throwError(err)));
  }

  findByBinId(id: string): Observable<Bin> {
    return this._httpClient.get<Bin>(this.controller + '/box-office/find-by-bin-id?binId=' + id).pipe(catchError(err => throwError(err)));
  }

  findAllCashiersByBoxOffice(page: number, size: number): Observable<Page<UserWithBoxOfficeDto>> {
    return this._httpClient
      .get<Page<UserWithBoxOfficeDto>>(
        this.controller + '/manager/box-office/find-all-cashiers-by-box-office?page=' + page + '&size=' + size,
      )
      .pipe(catchError(err => throwError(err)));
  }

  filterBinBoughtTicket(
    page: number,
    size: number,
    statusBin: BinStatus,
    sort: 'ASC' | 'DESC',
    options: Record<string, string | number> = {},
  ): Observable<Page<BinHistoryBoxOfficeDto>> {
    let params = new HttpParams();
    const { type_of_payment, sales_channel, dateFrom, dateTo, event_id, platform_hall, ownEventsOnly, organizators, user_id } = options;

    params = params.set('page', String(page));
    params = params.set('size', String(size));
    params = statusBin ? params.set('statusBin', String(statusBin)) : params;
    params = sort ? params.set('sort', 'created,' + String(sort)) : params;
    params =
      type_of_payment && type_of_payment !== '0' && type_of_payment !== 'null'
        ? params.set('typeOfPayment', String(type_of_payment))
        : params;
    params = sales_channel ? params.set('sales_channel', String(sales_channel)) : params;
    params = dateFrom ? params.set('dateFrom', String(dateFrom) + 'T00:00') : params;
    params = dateTo ? params.set('dateTo', String(dateTo) + 'T23:59') : params;
    params = !!+event_id ? params.set('event_id', String(event_id)) : params;
    params = !!+platform_hall ? params.set('platformHall', String(platform_hall)) : params;
    params = ownEventsOnly && String(ownEventsOnly) !== '0' ? params.set('ownEventsOnly', String(ownEventsOnly)) : params;
    params = user_id ? params.set('user_id', String(user_id)) : params;
    params = organizators ? params.set('organizators', String(organizators)) : params;

    return this._httpClient
      .get<Page<BinHistoryBoxOfficeDto>>(`${this.controller}/filter-bin-bought-ticket`, { params })
      .pipe(catchError(err => throwError(err)));
  }

  filterEventsBoxOfficeStatisticResearcher(researcher: string, lang: string, sort: 'ASC' | 'DESC'): Observable<Page<BoxOfficeEventDto>> {
    return this._httpClient
      .get<Page<BoxOfficeEventDto>>(this.controller + `/filter-events-box-office-statistic-researcher`, {
        params: new HttpParams().set('researcher', researcher).set('lang', lang).set('sort', sort),
      })
      .pipe(catchError(err => throwError(err)));
  }

  filterEventsBoxOfficeStatisticResearcherbyDate(
    researcher: string,
    lang: string,
    sort: 'ASC' | 'DESC',
    byDate: boolean,
  ): Observable<Page<BoxOfficeEventDto>> {
    return this._httpClient
      .get<Page<BoxOfficeEventDto>>(this.controller + `/filter-events-box-office-statistic-researcher-by-date`, {
        params: new HttpParams()
          .set('researcher', researcher)
          .set('lang', lang)
          .set('sort', sort)
          .set('byDate', byDate + ''),
      })
      .pipe(catchError(err => throwError(err)));
  }

  getOneEvent(id: number): Observable<BoxOfficeEventDto> {
    return this._httpClient
      .get<BoxOfficeEventDto>(this.controller + '/get-one-event?eventId=' + id)
      .pipe(catchError(err => throwError(err)));
  }

  filterBookedCashie(page: number, size: number, id: number): Observable<any> {
    let body = `?page=${page}&size=${size}&`;
    if (id) {
      body += `event_id=${id}`;
    }
    return this._httpClient.get(this.controller + '/box-office/filter-booked-cashier' + body).pipe(catchError(err => throwError(err)));
  }

  usePromoCode(code, idBin, isAdd?: boolean): Observable<any> {
    // return this._httpClient
    //   .post('/promo-code/kasa/valid/' + idEvent, {}, { params: new HttpParams().set('binId', idBin).set('code', code) })
    //   .pipe(catchError(err => throwError(err)));

    return this._httpClient.post(
      `/v2/update-promocode/${idBin}`,
      {},
      { params: new HttpParams().set('addPromoCode', !isAdd ? 'true' : 'false').set('promoCode', code) },
    );
  }

  parseFormData(data, name, typeDate: boolean = false): string {
    let body = '';
    for (let i = 0; i < data.getAll(name).length; i++) {
      if (data.getAll(name)[i] + '' != 'null' && data.getAll(name)[i] + '' != '') {
        if (typeDate) {
          body += name + '=' + data.getAll(name)[i] + 'T23:59&';
        } else {
          body += name + '=' + data.getAll(name)[i] + '&';
        }
      }
    }
    return body;
  }
}

/*
 * ${object.created} - дата події
 * ${object.timeUnlock} - дата покупки
 * ${object.binStatus} - статус квитка
 * ${object.price} - ціна
 * ${object.barcode} - баркод
 * ${object.row} - ряд
 * ${object.seatPosition} - місце
 * ${object.sector} - сектор
 * ${object.event.name.valueUA} - назва події
 * ${object.event.mainImageSmall.id} - фото події
 * ${object.ticketGroupWithoutScheme.comment} - коментар до запрошення
 * ${object.ticketGroupWithoutScheme.name.valueUA} - назва сектору в безсхемному залі
 * */
