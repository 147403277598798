import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-report-type-modal',
  templateUrl: './report-type-modal.component.html',
  styleUrls: ['./report-type-modal.component.css']
})
export class ReportTypeModalComponent implements OnInit {
  public reportParamsForm: FormGroup;
  public reportOwnerForm: FormGroup;
  public reportItemForm: FormGroup;


  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.reportItemForm = this.fb.group({
      isSelected: false,
      type: null,
    });

    this.reportOwnerForm = this.fb.group({
      cash: this.reportItemForm,
      card: this.reportItemForm,
      vendor: this.reportItemForm,
    });

    this.reportParamsForm = this.fb.group({
      ownParams: this.reportOwnerForm,
      foreignParams: this.reportOwnerForm,
    });
  }


}
