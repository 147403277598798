
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CollectionBaseDto } from '../../models/dto/CollectionBaseDto';
import { Page } from '../../models/Page';
import { CollectionInfoDto } from '../../models/dto/CollectionInfoDto';
import { CollectionInfoWrapperDto } from '../../models/dto/CollectionInfoWrapperDto';
import { CollectionInfoRevertWrapperDto } from '../../models/dto/CollectionInfoRevertWrapperDto';
import { CollectionStatus } from '../../models/enums/enums';

@Injectable({ providedIn: 'root' })
export class ColectionService {

  controller = '/collection';

  constructor(private _httpClient: HttpClient) { }

  createBoxOffice(): Observable<any> {
    return this._httpClient.post(this.controller + '/box-office/create-box-office', {}).pipe(catchError(err => throwError((err))));
  }

  filterCollection(page: number, size: number, status: CollectionStatus, form?: HTMLFormElement): Observable<Page<CollectionBaseDto>> {
    let body = `?page=${page}&size=${size}&status=${status}&sort=date,DESC&`;
    if (form) {
      let data = new FormData(form);
      body += this.parseForm(data, 'paymasters');
      body += this.parseForm(data, 'dateFrom', true);
      body += this.parseForm(data, 'dateTo', true);
    }
    return this._httpClient.get<Page<CollectionBaseDto>>(this.controller + '/box-office/filter-collection' + body).pipe(
      catchError(err => throwError(err)));
  }

  findByUserNotCollection(): Observable<CollectionInfoDto> {
    return this._httpClient.get<CollectionInfoDto>(this.controller + '/box-office/find-by-user-not-collection').pipe(
      catchError(err => throwError(err)));
  }

  findOne(id: number): Observable<CollectionInfoWrapperDto> {
    return this._httpClient.get<CollectionInfoWrapperDto>(this.controller + '/box-office/find-one/' + id).pipe(
      catchError(err => throwError(err)));
  }

  findOneRevert(id: number): Observable<CollectionInfoRevertWrapperDto> {
    return this._httpClient.get<CollectionInfoRevertWrapperDto>(this.controller + '/box-office/find-one-revert/' + id).pipe(
      catchError(err => throwError(err)));
  }

  parseForm(data, name, typeDate: boolean = false): string {
    let body = '';
    for (let i = 0; i < data.getAll(name).length; i++) {
      if (data.getAll(name)[i] + '' != 'null' && data.getAll(name)[i] + '' != '') {
        if (typeDate) {
          body += name + '=' + data.getAll(name)[i] + 'T23:59&';
        } else {
          body += name + '=' + data.getAll(name)[i] + '&';
        }
      }
    }
    return body;
  }
}
