import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { BookingService } from 'src/shared/service/backend/booking.service';
import { BoxOfficeService } from 'src/shared/service/backend/box-office.service';
import { EventService } from 'src/shared/service/backend/event.service';
import { ExcelBuilderService } from 'src/shared/service/backend/excel-builder.service';
import { LocationService } from 'src/shared/service/backend/location.service';
import { NetBoxOfficeService } from 'src/shared/service/backend/net-box-office.service';
import { UserService } from 'src/shared/service/backend/user.service';
import { MainLoaderService } from 'src/shared/service/rx/main-loader.service';

export class DataResultCalendar {
  dateFrom: string = '';
  dateTo: string = '';
  customFormat = false;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  user: any;
  pageable$: any;
  boxOffice: any;
  cashiers: any;
  events: any;
  filterForm: FormGroup;
  organizers: any[] = [];
  cities: any[] = [];
  filteredEvents: Event[] = [];
  eventPage: number = 0;
  allNetBoxes: any[] = [];
  filteredBoxes: any[] = [];
  filteredCashiers: any[] = [];
  paySystemKeys: any[] = [];
  isLoadData: boolean;
  enableReportDate: boolean = false;
  enableEventDate: boolean = true;

  public generalSeller = null;
  public generalEvent = null;
  public detailReport = [];

  public paymentOptions: Record<string, string | null>[] = [
    { value: 'CASH', label: 'Готівка' },
    { value: 'CARD', label: 'Термінал' },
  ];

  additionalOptions = [
    {
      value: 'true',
      viewValue: 'Від першого проданого',
      isOpenDateToSelection: true,
    },
  ];

  onlineOptions = [
    {
      name: 'Сайт',
      value: true,
    },
    {
      name: 'Каса',
      value: false,
    },
  ];

  sortOptions = [
    {
      name: 'По події',
      value: 'BY_EVENT',
    },
    {
      name: 'По організатору',
      value: 'BY_SELLER',
    },
  ];
  public dateFormat: string = 'dd-MM-yyyy';
  public reportDate: Date[] = [new Date(), new Date()];
  public eventDate: Date[] = [new Date(), new Date()];

  public get onlineStatus() {
    return this.filterForm.get('isOnline').value;
  }

  public get sortOptionType() {
    return this.filterForm.get('sortOptionType').value;
  }

  calendarResult: DataResultCalendar = new DataResultCalendar();
  calendarStartEventResult: DataResultCalendar = new DataResultCalendar();
  total: any;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    private _userService: UserService,
    private _locationService: LocationService,
    private _eventService: EventService,
    private _netBoxOfficeService: NetBoxOfficeService,
    private _boxOfficeService: BoxOfficeService,
    private _excelBuilderService: ExcelBuilderService,
    private _bookingService: BookingService,
    private loaderService: MainLoaderService,
  ) {}

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      eventId: [null],
      organizerId: [null],
      cityId: [null],
      paySystemKey: [null],
      netBoxOfficeId: [null],
      boxOfficeId: [null],
      cashierId: [null],
      sortOptionType: ['BY_SELLER'],
      typeOfPayment: [null],
      isOnline: [null],
    });
    combineLatest([
      this._bookingService.filterEventsBoxOfficeStatisticByDate(
        999,
        0,
        'ALL',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'ASC',
        false,
      ),
      this._userService.findAllUsers('SPOPNSOR'),
      this._locationService.findAllCity(),
      this._eventService.findAllShortDto(this.eventPage, 100),
      this._netBoxOfficeService.findAll(),
      this._boxOfficeService.findAllBoxFilter(999, 0),
      this._boxOfficeService.filterCashierResearch(999, 0),
      this._boxOfficeService.findAllActiveWithCountEvent(),
    ])
      .pipe(take(1))
      .subscribe(([eventList, organizers, cities, events, allNetBoxes, allBoxes, allCashiers, paySystemKeys]) => {
        this.events = eventList.content;
        this.organizers = organizers;
        this.cities = cities;
        this.filteredEvents = events.content;
        this.allNetBoxes = allNetBoxes;
        this.filteredBoxes = allBoxes.content;
        this.filteredCashiers = allCashiers.content;
        this.paySystemKeys = paySystemKeys;
        this.isLoadData = true;
      });
    this.resetFilter();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  applyFilter(event) {
    event.preventDefault();
  }

  getReportDate() {
    if (this.enableReportDate) {
      const eventStart = this.formatDateForApi(this.reportDate[0]);
      const eventEnd = this.formatDateForApi(this.reportDate[1], true);
      return { eventStart, eventEnd };
    } else {
      return null;
    }
  }

  getEventDate() {
    if (this.enableEventDate) {
      const soldStart = this.formatDateForApi(this.eventDate[0]);
      const soldEnd = this.formatDateForApi(this.eventDate[1], true);
      return { soldStart, soldEnd };
    } else {
      return null;
    }
  }

  private formatDateForApi(date: Date, endDay: boolean = false): string {
    let [day, month, year] = [('0' + date.getDate()).slice(-2), ('0' + (date.getMonth() + 1)).slice(-2), date.getFullYear()];
    return endDay ? `${year}-${month}-${day}T23:59` : `${year}-${month}-${day}T00:00`;
  }

  getEventStartDate(data) {
    this.calendarStartEventResult = data;
  }

  loadBoxOffices() {
    this.loaderService.startLoader();
    if (this.filterForm.value['netBoxOfficeId'] && this.filterForm.value['netBoxOfficeId'] !== 'null') {
      this._boxOfficeService.filterBoxOffice(+this.filterForm.value['netBoxOfficeId'], 0, 999).subscribe(next => {
        this.filteredBoxes = next.content;
        this.filterForm.patchValue({
          box_office_id: '',
          cashier_id: '',
        });
        this.loaderService.endLoader();
      });
    } else {
      this._boxOfficeService.findAllBoxFilter(999, 0).subscribe(res => {
        this.filteredBoxes = res.content;
        this.loaderService.endLoader();
      });
    }
  }

  loadCashiers() {
    if (this.filterForm.value['boxOfficeId']) {
      this._boxOfficeService.filterCashierBoxofficeResearch(+this.filterForm.value['boxOfficeId']).subscribe(next => {
        this.filteredCashiers = next;
        this.filterForm.patchValue({
          cashier_id: '',
        });
      });
    }
  }

  resetFilter() {
    // this._dropDownService.callComponentMethod();
    this.filterForm.controls['eventId'].setValue(null);
    this.filterForm.controls['paySystemKey'].setValue(null);
    this.filterForm.controls['netBoxOfficeId'].setValue(null);
    this.filterForm.controls['boxOfficeId'].setValue(null);
    this.filterForm.controls['cashierId'].setValue(null);
    this.filterForm.controls['typeOfPayment'].setValue(null);
    this.filterForm.controls['sortOptionType'].setValue('BY_SELLER');
    this.calendarResult = new DataResultCalendar();
    this.calendarStartEventResult = new DataResultCalendar();

    this.generalSeller = null;
    this.generalEvent = null;
  }

  public onReportGenerate(): void {
    const reportDate = this.getReportDate();
    const eventDate = this.getEventDate();
    let options = { ...this.filterForm.value };

    if (reportDate) {
      options = { ...options, ...reportDate };
    }
    if (eventDate) {
      options = { ...options, ...eventDate };
    }

    this._excelBuilderService
      .generateEventsReport(options)
      .pipe(take(1))
      .subscribe(res => {});
  }

  public onReportStatusChange(event): void {
    const eventStatus = event.target.value ?? null;

    if (eventStatus === 'false') return;

    this.filterForm.patchValue({
      netBoxOfficeId: null,
      boxOfficeId: null,
      cashierId: null,
    });
  }

  public displayTable() {
    const reportDate = this.getReportDate();
    const eventDate = this.getEventDate();
    let options = { ...this.filterForm.value };

    if (reportDate) {
      options = { ...options, ...reportDate };
    }
    if (eventDate) {
      options = { ...options, ...eventDate };
    }

    this._excelBuilderService
      .getReportFromExcel(options)
      .pipe(take(1))
      .subscribe(res => {
        if (this.sortOptionType === 'BY_SELLER') {
          this.generalSeller = res?.generalReportInfo;
          this.generalEvent = null;
        } else {
          this.generalEvent = res?.generalReportInfo;
          this.generalSeller = null;
        }

        this.detailReport = res?.detailedReportInfoMap;
      });
  }

  public sortOptionTypeChange(): void {
    this.generalSeller = null;
    this.generalEvent = null;
  }
}
