import { Component, OnInit } from '@angular/core';

import { MainSettingConfig, MainSettingsService } from '../../../shared/service/rx/main-settings.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  isOpenSettings: boolean = false;
  mainSettingConfig: MainSettingConfig;
  constructor(private _mainSettingsService: MainSettingsService) {
    this._mainSettingsService.settingConfig$.subscribe(next => {
      this.mainSettingConfig = next;
    }, error => {
      console.error(error);
    });
    this.mainSettingConfig = this._mainSettingsService.getConfig();
  }

  ngOnInit() {
  }

}
