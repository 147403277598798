<div class="info-container">
  <div class="event-info">
    <img class="event-picture" [src]="eventItem?.image?.path || eventItem?.mainImageSmall?.path | img" alt="Event Image" />
    <div class="event-info-section">
      <h2 class="event-info-caption">{{ eventItem?.name.valueUA }}</h2>
      <div class="event-organizer-row">
        <i class="event-organizer-icon" nz-icon nzType="team" nzTheme="outline"></i>
        <p class="date_registration">
          {{ eventItem?.detailedInfo?.organizator?.firstName }} {{ eventItem?.detailedInfo?.organizator?.lastName }}
        </p>
      </div>
    </div>
    <div class="event-info-section">
      <p class="other-info">{{ eventItem?.beginDate | date : 'dd.MM.yyyy, H:mm' }}</p>
      <p class="other-info" *ngIf="eventItem?.platformHall">
        {{ eventItem?.platformHall.city.dictionary.valueUA }} , {{ eventItem?.platformHall.name.valueUA }}
      </p>
      <p class="other-info">{{ eventItem?.minPrice }} - {{ eventItem?.maxPrice }} грн</p>
    </div>
  </div>
  <div class="tickets-info">
    <div class="container-side">
      <table>
        <tr>
          <th>
            {{ 'accountant.event_statistics.general.registered' | translate }}
          </th>
          <th>
            {{ 'accountant.event_statistics.general.sold' | translate }}
          </th>

          <th>
            {{ 'accountant.event_statistics.general.available' | translate }}
          </th>
          <th>
            {{ 'accountant.event_statistics.general.returned' | translate }}
          </th>
        </tr>
        <tr>
          <td>
            <span class="result-metric">{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}:</span>
            {{
              eventItem?.boxOfficeEventBaseTicketInfo?.countAllTicket ||
                eventItem?.detailedInfo?.countAllTicket ||
                eventItem?.countAllTicket ||
                '-'
            }}
          </td>
          <td>
            <span class="result-metric">{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}:</span>
            {{
              eventItem?.boxOfficeEventBaseTicketInfo?.countBoughtTicket ||
                eventItem?.detailedInfo?.countBoughtTicket ||
                eventItem?.countBoughtTicket ||
                '-'
            }}
          </td>

          <td>
            <span class="result-metric">{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}:</span>
            {{
              eventItem?.boxOfficeEventBaseTicketInfo?.countAvailableTicket ||
                eventItem?.detailedInfo?.countFreeTicket ||
                eventItem?.countFreeTicket ||
                '-'
            }}
          </td>
          <td>
            <span class="result-metric">{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}:</span>
            {{ eventItem?.boxOfficeEventBaseTicketInfo?.countRevertedTicket || eventItem?.detailedInfo?.countRevertedTicket || '-' }}
          </td>
        </tr>
        <tr>
          <td>
            <span class="result-metric">{{ 'accountant.event_statistics.general.ticket_amount' | translate }}:</span>
            {{
              eventItem?.boxOfficeEventBaseTicketInfo?.priceAllTicket ||
                eventItem?.detailedInfo?.priceAllTicket ||
                eventItem?.priceAllTicket ||
                '-'
            }}
          </td>
          <td>
            <span class="result-metric">{{ 'accountant.event_statistics.general.ticket_amount' | translate }}:</span>
            {{
              eventItem?.boxOfficeEventBaseTicketInfo?.priceBoughtTicket ||
                eventItem?.detailedInfo?.priceBoughtTicket ||
                eventItem?.priceBoughtTicket ||
                '-'
            }}
          </td>

          <td>
            <span class="result-metric">{{ 'accountant.event_statistics.general.ticket_amount' | translate }}:</span>
            {{ eventItem?.boxOfficeEventBaseTicketInfo?.priceAvailableTicket || eventItem?.priceFreeTicket || '-' }}
          </td>
          <td>
            <span class="result-metric">{{ 'accountant.event_statistics.general.ticket_amount' | translate }}:</span>
            {{ eventItem?.boxOfficeEventBaseTicketInfo?.priceRevertedTicket || eventItem?.revertedTicketsPrice || '-' }}
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="displayMode === 'extended'" class="action-button">
    <a [routerLink]="['/accountant/events-statistics', eventItem.id]" class="action-button">
      {{ 'accountant.event_statistics.action_button' | translate }}
    </a>
  </div>
</div>
