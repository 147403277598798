import {Component, OnInit} from '@angular/core';
import {Bin} from '../../../../shared/models/Bin';
import {PaymentBarService} from './payment-bar.service';

@Component({
  selector: 'app-payment-bar',
  templateUrl: './payment-bar.component.html',
  styleUrls: ['./payment-bar.component.css']
})
export class PaymentBarComponent implements OnInit {
  bin: Bin;
  outPrice: number = 0;

  commission: {
    price: number,
    priceWithOutCommission: number,
    commission: number,
    bookedCommission: number,
    bookedCommissionPrice: number,
    commissionPrice: number
  };
  status = null;

  constructor(private _paymentBarService: PaymentBarService) {
    // this.test();
    _paymentBarService.bin$.subscribe(value => {
      this.bin = value;
      this.commission = _paymentBarService.commission;
    });
    _paymentBarService.resive$.subscribe(value => {
      this.status = value;
      if (this.status) {

      }

    });
  }
close(){
  this.commission = null;
  this.bin = null;
}
  test() {
    this.bin = new Bin();
    this.commission = {
      price: 10.00,
      priceWithOutCommission: 10.00,
      commission: 0.1,
      bookedCommission: 0.1,
      bookedCommissionPrice: 10,
      commissionPrice: 10
    };
  }

  send() {
    this._paymentBarService.sendPaymentRequest();
  }

  collectedPrice() {
    this.outPrice = parseInt((<HTMLInputElement>event.target).value) - this.commission.price;
  }

  ngOnInit() {
  }

}
