import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { Page } from 'src/shared/models/Page';
import { BoxOfficeService } from 'src/shared/service/backend/box-office.service';
import { NetBoxOfficeService } from 'src/shared/service/backend/net-box-office.service';
import { ReportsService } from 'src/shared/service/backend/reports.service';
import { MainLoaderService } from 'src/shared/service/rx/main-loader.service';

enum ReportTypeEnum {
  KG9 = 'KG9',
  KG10 = 'KG10',
  KG10D = 'KG10D',
  KG11 = 'KG11',
}

interface NetBoxItem {
  id: number;
  name: {
    valueEN: string;
    valueRU: string;
    valueUA: string;
  };
}

interface ReportItem {
  type: ReportTypeEnum;
  name: string;
}

@Component({
  selector: 'app-reports-by-event',
  templateUrl: './reports-by-event.component.html',
  styleUrls: ['./reports-by-event.component.css'],
})
export class ReportsByEventComponent implements OnInit {
  public reportForm: FormGroup;
  public netBoxList: NetBoxItem[] = [];
  public cashierList: NetBoxItem[] = [];

  public readonly AVAILABLE_REPORTS: ReportItem[] = [
    { type: ReportTypeEnum.KG9, name: 'КГ-9' },
    { type: ReportTypeEnum.KG10, name: 'КГ-10' },
    { type: ReportTypeEnum.KG10D, name: 'КГ-10 Д' },
    { type: ReportTypeEnum.KG11, name: 'КГ-11' },
  ];

  private eventId: number;

  private readonly DATA_PAGE: number = 0;
  private readonly DATA_SIZE: number = 99;

  public get currentReportType(): ReportTypeEnum {
    return this.reportForm.get('reportType').value;
  }

  private get currentNetBoxId(): number {
    return this.reportForm.get('netBox').value;
  }

  public get trackNoDate(): number {
    return this.reportForm.get('trackNoDate').value;
  }

  constructor(
    private reportsService: ReportsService,
    private mainLoaderService: MainLoaderService,
    private activateRoute: ActivatedRoute,
    private netBoxOfficeService: NetBoxOfficeService,
    private boxOfficeService: BoxOfficeService,
    private fb: FormBuilder,
  ) {}

  public ngOnInit() {
    this.activateRoute.params.pipe(take(1)).subscribe(param => (this.eventId = param['id']));

    this.netBoxOfficeService
      .findAllNetBixOfficesByEvent(this.eventId, this.DATA_PAGE, this.DATA_SIZE)
      .pipe(take(1))
      .subscribe((netBoxList: Page<NetBoxItem>) => (this.netBoxList = netBoxList.content));

    this.initForm();
  }

  public changeNetwork(): void {
    this.boxOfficeService
      .filterBoxOffice(this.currentNetBoxId, this.DATA_PAGE, this.DATA_SIZE)
      .pipe(take(1))
      .subscribe((cashierList: Page<NetBoxItem>) => (this.cashierList = cashierList.content));
  }

  public generateReport(): void {
    let { reportType, reportNumber, dateFrom, dateTo, cashier } = this.reportForm.value;
    let reportStream: Observable<any>;
    let newReportStream: Observable<any>;

    dateFrom = this.trackNoDate ? '' : this.formatDateForApi(dateFrom);
    dateTo = this.trackNoDate ? '' : this.formatDateForApi(dateTo, true);

    switch (reportType) {
      case ReportTypeEnum.KG10:
        newReportStream = this.reportsService.newCreateKgTenReportExcel(this.eventId, dateFrom, dateTo, reportNumber);
        // reportStream = this.reportsService.createKgTenReportExcel(this.eventId, dateFrom, dateTo, reportNumber);
        break;
      case ReportTypeEnum.KG10D:
        newReportStream = this.reportsService.newCreateKgTenReportExcelD(this.eventId, dateFrom, dateTo, reportNumber);
        // reportStream = this.reportsService.createKgTenReportExcelD(this.eventId, dateFrom, dateTo, reportNumber);
        break;
      case ReportTypeEnum.KG11:
        newReportStream = this.reportsService.newCreateKgElevenReportExcel(this.eventId, cashier, dateFrom, dateTo, reportNumber);
        // reportStream = this.reportsService.createKgElevenReportExcel(this.eventId, cashier, dateFrom, dateTo, reportNumber);
        break;
      default:
        newReportStream = this.reportsService.newCreateKgNineReportExcel(this.eventId, dateFrom, dateTo, reportNumber);
        // reportStream = this.reportsService.createKgNineReportExcel(this.eventId, dateFrom, dateTo, reportNumber);
        break;
    }

    newReportStream.subscribe();
    reportStream.pipe(tap(() => this.mainLoaderService.endLoader())).subscribe();
  }

  public initForm(): void {
    this.reportForm = this.fb.group({
      reportType: [ReportTypeEnum.KG9],
      reportNumber: [null, Validators.required],
      dateFrom: [new Date(), Validators.required],
      dateTo: [new Date(), Validators.required],
      netBox: [null],
      cashier: [null],
      trackNoDate: false,
    });
  }

  private formatDateForApi(date: Date, endDay: boolean = false): string {
    let [day, month, year] = date.toLocaleDateString().split('.');
    return endDay ? `${year}-${month}-${day}T23:59` : `${year}-${month}-${day}T00:00`;
  }
}
