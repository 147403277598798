<div class="containers">
  <div class="ticket">
    <img class="ticket_image" [src]="one.mainImageSmall?.path | img" alt="" />
    <div class="ticket_name">
      <div class="ticket_name_event">
        <p class="ticket_event_about item_text_event">
          <span *ngFor="let cat of one.eventCategory">
            <span *ngIf="lang == 'ua'">{{ cat.name.valueUA }}</span>
            <span *ngIf="lang == 'ru'">{{ cat.name.valueRU }}</span>
            <span *ngIf="lang == 'en'">{{ cat.name.valueEN }}</span>
          </span>
        </p>
        <p class="item_text_event ticket_event_about_border">
          <span *ngIf="lang == 'ua'">{{ one.platformHall.city.dictionary.valueUA }}</span>
          <span *ngIf="lang == 'ru'">{{ one.platformHall.city.dictionary.valueRU }}</span>
          <span *ngIf="lang == 'en'">{{ one.platformHall.city.dictionary.valueEN }}</span>
        </p>
      </div>
      <p class="ticket_events_name" *ngIf="lang == 'ua'">{{ one.name.valueUA }}</p>
      <p class="ticket_events_name" *ngIf="lang == 'ru'">{{ one.name.valueRU }}</p>
      <p class="ticket_events_name" *ngIf="lang == 'en'">{{ one.name.valueEN }}</p>
      <p class="ticket_events_title" *ngIf="lang == 'ua'">{{ one.platformHall.name.valueUA }}</p>
      <p class="ticket_events_title" *ngIf="lang == 'ru'">{{ one.platformHall.name.valueRU }}</p>
      <p class="ticket_events_title" *ngIf="lang == 'en'">{{ one.platformHall.name.valueEN }}</p>
    </div>
    <div class="item">
      <p class="item_text">{{ 'ticket.date' | translate }}</p>
      <div class="item_text_center">
        <div>
          <p class="ticket_date ticket_text item_center">{{ one.beginDate | localdate }}</p>
          <p class="ticket_time ticket_text item_center">{{ one.beginDate | localtime }}</p>
        </div>
      </div>
    </div>
    <div class="item">
      <p class="item_text">{{ 'ticket.kilk' | translate }}</p>
      <div class="item_text_center">
        <p class="ticket_text ticket_kilk item_center">{{ one.freeSeatsNumber }}</p>
      </div>
    </div>

    <div class="item">
      <p class="item_text">{{ 'ticket.kupleno' | translate }}</p>
      <div class="item_text_multirow">
        <div class="amount-row">
          <span class="data-label">{{ 'ticket.quantity' | translate }}:</span>
          <p class="ticket_text ticket_bron_kupleno item_center">{{ one.boughtTicketsNumber }}</p>
        </div>
        <div class="amount-row">
          <span class="data-label">{{ 'ticket.amount' | translate }}:</span>
          <p class="ticket_text ticket_bron_kupleno item_center">{{ one.boughtTicketsPrice }}</p>
        </div>
      </div>
    </div>
    <div class="item">
      <p class="item_text">{{ 'ticket.returns' | translate }}</p>
      <div class="item_text_multirow">
        <div class="amount-row">
          <span class="data-label">{{ 'ticket.quantity' | translate }}:</span>
          <p class="ticket_text ticket_bron_kupleno item_center">{{ one.revertedTicketsNumber }}</p>
        </div>
        <div class="amount-row">
          <span class="data-label">{{ 'ticket.amount' | translate }}:</span>
          <p class="ticket_text ticket_bron_kupleno item_center">{{ one.revertedTicketsPrice }}</p>
        </div>
      </div>
    </div>

    <div class="item">
      <p class="item_text">{{ 'ticket.revert_coef' | translate }}</p>
      <div class="item_text_center">
        <p class="ticket_text ticket_bron_kupleno item_center">{{ one.revertTicketFromNominal * 100 }}%</p>
      </div>
    </div>
    <button (click)="returnTicket()" class="return pointer" [ngClass]="{ 'red-no-active': !one.canRevertTicket }">
      {{ 'ticket.return' | translate }}
    </button>
  </div>
</div>
