import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { MainSettingsService } from '../shared/service/rx/main-settings.service';
import { MainLoaderService } from '../shared/service/rx/main-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  load: 'ON' | 'OFF';
  title = 'sold-out-pay-office-client';

  a = {};


  constructor(
    private _mainLoaderService: MainLoaderService,
    private _mainConfigService: MainSettingsService,
    private translateService: TranslateService,
  ) {
    this.load = this._mainLoaderService.load;
    this._mainConfigService.getConfig();
    this._mainLoaderService.load$.subscribe(next => {
      this.load = next;
    }, error => {
      console.error(error);
    });

    translateService.addLangs(['en', 'ua']);
    translateService.setDefaultLang('ua');
    translateService.use('ua');
  }
}
