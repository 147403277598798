import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { BookingService } from '../../../../../../shared/service/backend/booking.service';

@Component({
  selector: 'app-sails-by-month',
  templateUrl: './sails-by-month.component.html',
  styleUrls: ['./sails-by-month.component.css'],
})
export class SailsByMonthComponent implements OnInit {
  events;
  loading = false;
  total = 1;
  pageSize = 10;
  pageIndex = 1;
  readonly monthInYear: string[] = [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень',
  ];

  sort: 'ASC' | 'DESC' = 'ASC';

  constructor(private bookingService: BookingService) {}

  ngOnInit(): void {
    this.getEvents(this.pageIndex, this.pageSize);
  }

  getEvents(page: number, pageSize: number) {
    this.bookingService
      .filterEventsBoxOfficeStatisticByDate(
        pageSize,
        page - 1,
        'ALL',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.sort,
        false,
      )

      .subscribe(data => {
        this.loading = false;
        this.events = data.content;
        this.total = data.totalElements;
      });
  }
  onQueryParamsChange(event) {
    this.loading = true;
    this.getEvents(event.pageIndex, event.pageSize);
  }
}
