import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { UserDetailsService } from '../../../../../../shared/service/user-details-service.service';
import { UserWithBoxOfficeDto } from '../../../../../../shared/models/dto/UserWithBoxOfficeDto';

@Component({
  selector: 'app-reports-wrapper',
  templateUrl: './reports-wrapper.component.html',
  styleUrls: ['./reports-wrapper.component.css']
})

export class ReportsWrapperComponent implements OnInit {
  public user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  constructor(private userDetailsService: UserDetailsService) { }

  ngOnInit() {
    this.user = this.userDetailsService.getUser();
    this.userDetailsService.user$.pipe(take(1)).subscribe(user => this.user = user);
  }
}
