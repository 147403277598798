import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { VendorWithoutDto } from './vendors.service';
import { LockOption } from 'src/shared/types/interfaces/event-lock.interfaces';
import { Ticket } from 'src/shared/models/Ticket';
import { IFreeTicket } from 'src/app/client/client/events/event-action/event-action.component';

@Injectable({ providedIn: 'root' })
export class HardLockService {
  controler = '/hardlock/ticket/';

  constructor(private httpClient: HttpClient) {}

  findAll(): Observable<any> {
    return this.httpClient.get(this.controler + 'getAll').pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  createBin(eventId: number): Observable<string> {
    return this.httpClient.post(this.controler + 'createBin/' + eventId, {}, { responseType: 'text' }).pipe(take(1));
  }

  findAllByEvent(id, page, size): Observable<any> {
    return this.httpClient
      .get(this.controler + 'event/' + id, {
        params: new HttpParams().set('page', page).set('size', size),
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  findOneBin(binId): Observable<any> {
    return this.httpClient.get(this.controler + 'bin/' + binId).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  findOneBinWithout(binId): Observable<any> {
    return this.httpClient
      .get(this.controler + 'bin/without-scheme/' + binId)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  lockTickets(ticketsArray: number[], fanzonesArray: any[]): Observable<any> {
    if (ticketsArray.length || fanzonesArray.length) {
      const data = {
        ticketsIds: ticketsArray,
        fanzoneRequestDtos: fanzonesArray,
      };
      return this.httpClient
        .post(this.controler + 'lock', JSON.stringify(data))
        .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
    }
  }

  editLockTickets(binId, ticketsArray: number[], fanzonesArray: any[]): Observable<any> {
    if (ticketsArray.length || fanzonesArray.length) {
      const data = {
        binId: +binId,
        ticketsIds: ticketsArray,
        fanzoneRequestDtos: fanzonesArray,
      };
      return this.httpClient
        .post(this.controler + 'lock', JSON.stringify(data))
        .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
    }
  }

  unlockTickets(data: any): Observable<any> {
    return this.httpClient
      .post(this.controler + 'unlock', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  lockWithoutTickets(data: VendorWithoutDto): Observable<any> {
    return this.httpClient
      .post(this.controler + 'ticketWithoutScheme/lock', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  public bookSeat(option: LockOption): Observable<any> {
    return this.httpClient.post(`${this.controler}booking`, option);
  }

  public bookSeatWithoutSchema(option: LockOption): Observable<any> {
    return this.httpClient.post(`${this.controler}ticketWithoutScheme/booking`, option);
  }

  unlockWithoutTickets(data: VendorWithoutDto): Observable<any> {
    return this.httpClient
      .post(this.controler + 'ticketWithoutScheme/unlock', JSON.stringify(data))
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  deleteBin(binId): Observable<any> {
    return this.httpClient
      .delete(this.controler + 'unlock' + `?binId=${binId}`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  findAllFreeTicketByEventIdAndPartHallId(eventId: number, partId: number): Observable<any> {
    return this.httpClient
      .get(this.controler + 'free-ticket/' + eventId + '/' + partId)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  findFreeTicketsByEventId(eventId: number): Observable<IFreeTicket[]> {
    return this.httpClient.get<IFreeTicket[]>(`/event/client/online/find-all-free-tickets-by-event-id/${eventId}`);
  }

  public saveLockedBin(binId: string): Observable<any> {
    return this.httpClient
      .post<void>(`${this.controler}finish-bin-updates/${binId}`, {})
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
}
