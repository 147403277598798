
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ImageD, User } from '../../models/User';
import { UserWithBoxOfficeDto } from '../../models/dto/UserWithBoxOfficeDto';
import { Page } from '../../../shared/models/Page';


@Injectable({ providedIn: 'root' })
export class UserService {

  controller = '/user';

  constructor(private _httpClient: HttpClient) { }

  create(user: User): Observable<any> {
    return this._httpClient.post(this.controller + '/client/registration', JSON.stringify(user)).pipe(catchError(err => throwError(err)));
  }

  getUserWithBoxAndNetBoxOffices(): Observable<UserWithBoxOfficeDto> {
    return this._httpClient.get<UserWithBoxOfficeDto>
      (this.controller + '/get-user-with-box-and-net-box-offices')
      .pipe(
        catchError(err => throwError(err))
      );
  }

  lock(user: User): Observable<any> {
    return this._httpClient.post(this.controller + '/lock', JSON.stringify(user)).pipe(catchError(err => throwError(err)));
  }

  updateImage(user: ImageD): Observable<any> {
    return this._httpClient.post(this.controller + '/update-image', JSON.stringify(user)).pipe(catchError(err => throwError(err)));
  }

  addImage(user: ImageD): Observable<any> {
    return this._httpClient.post(this.controller + '/add-image', JSON.stringify(user)).pipe(catchError(err => throwError(err)));
  }

  changePassword(user: User): Observable<any> {
    return this._httpClient.post(this.controller + '/change-password', JSON.stringify(user)).pipe(catchError(err => throwError(err)));
  }

  equalsPassword(user: User): Observable<boolean> {
    return this._httpClient.post<boolean>(this.controller + '/equals-password', JSON.stringify(user)).pipe(catchError(err => throwError(err)));
  }

  findOne(id: number): Observable<User> {
    return this._httpClient.get<User>(this.controller + '/find-one/' + id).pipe(catchError(err => throwError(err)));
  }
  validEmail(email: string): Observable<any> {
    return this._httpClient.get(`${this.controller}/client/valid-email`, { params: new HttpParams().set("email", email) }).pipe(catchError(err => throwError(err)));
  }

  findAllUsers(role: string): Observable<any> {
    return this._httpClient.get(`${this.controller}/find-users-by-role/?role=${role}`).catch(err => throwError(err));
  }

  findAllByFilter(research: HTMLInputElement, before: HTMLInputElement,
    after: HTMLInputElement, role: HTMLSelectElement,
    available: HTMLSelectElement, lock: HTMLSelectElement, size: number, page: number,
    sort: string, direction: string): Observable<User[]> {
    return this._httpClient
      .get<User[]>(`${this.controller}/find-all-filter/${direction}/${sort}/${page}/${size}${this.generator(research, before, after, role, available, lock)}`);
  }

  findAllBySimpleFilter(size: number, page: number, sort: string, direction: string, role: string): Observable<Page<User>> {
    let params = new HttpParams();

    params = size ? params.set('size', String(size)) : params;
    params = page ? params.set('page', String(page)) : params;
    params = sort ? params.set('sort', String(sort)) : params;
    params = direction ? params.set('direction', String(direction)) : params;
    params = role ? params.set('role', String(role)) : params;

    return this._httpClient.get<Page<User>>(`${this.controller}/find-all-filter`, { params })
      .pipe(catchError(err => throwError(err)));
  }

  private generator(research: HTMLInputElement, before: HTMLInputElement,
    after: HTMLInputElement, role: HTMLSelectElement,
    available: HTMLSelectElement, lock: HTMLSelectElement): string {
    let url = '?';
    if (lock.value != 'null') {
      if (url != '?')
        url += '&';
      url += 'lock=' + lock.value;
    }
    if (available.value != 'null') {
      if (url != '?')
        url += '&';
      url += 'available=' + available.value;
    }
    if (before.value != '') {
      if (url != '?')
        url += '&';
      url += 'before=' + before.value.replace('T', ' ') + ':00.000';
    }
    if (after.value != '') {
      if (url != '?')
        url += '&';
      url += 'after=' + after.value.replace('T', ' ') + ':00.000';
    }
    if (role.value != 'null') {
      if (url != '?')
        url += '&';
      url += 'role=' + role.value;
    }
    if (research.value != '') {
      if (url != '?')
        url += '&';
      url += 'research=' + research.value;
    }
    return url == '?' ? '' : url;
  }

  updateUser(user: User): Observable<any> {
    return this._httpClient.post(this.controller + '/user/update-user', JSON.stringify(user)).pipe(catchError(err => throwError(err)));
  }

  notificationEmail(id: number): Observable<any> {
    return this._httpClient.get(this.controller + '/add-notification-email/' + id).pipe(catchError(err => throwError(err)));
  }

  // findBookedBinByByPrincipal(): Observable<BinWithTileLockDto[]> {
  //   return this._httpClient.get(this.controller + '/find-all-booked-bin').catch(err => Observable.throw(err));
  // }

  resetPassword(email: string): Observable<any> {
    return this._httpClient.post(this.controller + '/client/reset-password?email=' + email, new FormData()).pipe(catchError(err => throwError(err)));
  }

  resetPasswordConfirm(user: User): Observable<any> {
    return this._httpClient.post(this.controller + '/client/reset-password-confirm', JSON.stringify(user)).pipe(catchError(err => throwError(err)));
  }

  // findAllBookedBin(bool: boolean): Observable<BinWithTileLockDto[]> {
  //   return this._httpClient.get(this.controller + '/find-all-booked-bin/' + bool + '').catch(err => Observable.throw(err));
  // }
}
