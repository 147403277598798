<div class="hall-container">
  <div class="btn">
    <div (click)="onZoom('in')" class="plus" style="cursor: zoom-in" #plus>
      <span style="cursor: zoom-in">+</span>
    </div>
    <div (click)="center()" class="plus" style="cursor: move">
      <img style="width: 16px; height: auto" src="../../../../../assets/Maps-Define-Location-icon.png" alt="" />
    </div>
    <div (click)="onZoom('out')" class="plus" style="cursor: zoom-out" #minus>
      <span style="cursor: zoom-out">-</span>
    </div>
  </div>
  <div class="container_svg" (mouseenter)="addEventListenerForZoomByScroll()" (mouseleave)="rmEventListenerForZoomByScroll()">
    <div
      #svgtag
      *ngIf="partHallPattern; else noSvg"
      class="svgtaginner"
      id="svgtaginnerid"
      [ngClass]="{
        'svgtaginner-transition': !stop,
        disable: render != 'ready',
        'ready-render-hall': render == 'ready'
      }"
      [ngStyle]="{
        transform: 'matrix(' + zoomLevel + ', 0, 0,' + zoomLevel + ', 0, 0)'
      }"
      [innerHTML]="partHallPattern | safeHtml"
    ></div>

    <ng-template #noSvg>
      <div class="spinner-wrapper">
        <nz-spin nzSimple></nz-spin>
      </div>
    </ng-template>
  </div>
  <div *ngIf="isMultiHall" class="back-arrow" (click)="displayCurrentSector('general')">➮</div>
</div>

<!-- Seat info tooltip on hover -->
<ng-container *ngIf="hoveredTicket$ | async as ticket">
  <div class="block_info_seat" [style.top.px]="ticket.pageY" [style.left.px]="ticket.pageX">
    <ng-container *ngIf="ticket?.hoveredTicket?.seat?.fanzone; else regularTicket">
      <p>
        {{ 'buy.tickets_bin.countTicketsFan' | translate }} -
        <strong>{{ ticket?.fanzoneSeats }}</strong>
      </p>
      <p>
        {{ 'buy.tickets_bin.price' | translate }} -
        <strong>{{ ticket?.hoveredTicket?.colorPrice?.price }}грн</strong>
      </p>
    </ng-container>
    <ng-template #regularTicket>
      <p>
        {{ 'buy.tickets_bin.seat' | translate }} -
        <strong>{{ ticket.ticketPosition }}</strong>
      </p>
      <p>
        {{ 'buy.tickets_bin.row' | translate }} -
        <strong>{{ ticket.ticketRow }}</strong>
      </p>
      <p>
        {{ 'buy.tickets_bin.sector' | translate }} -
        <strong>{{ ticket.ticketSector }}</strong>
      </p>
      <p>
        {{ 'buy.tickets_bin.price' | translate }} -
        <strong>{{ ticket?.hoveredTicket?.colorPrice?.price || ticket.price }}грн</strong>
      </p>
    </ng-template>
  </div>
</ng-container>
