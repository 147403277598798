import { Component, OnInit } from '@angular/core';

import { MainSettingConfig, MainSettingsService } from '../../../../shared/service/rx/main-settings.service';
import { UserDetailsService } from '../../../../shared/service/user-details-service.service';
import { BookingService } from '../../../../shared/service/backend/booking.service';
import { Page } from '../../../../shared/models/Page';
import { UserWithBoxOfficeDto } from '../../../../shared/models/dto/UserWithBoxOfficeDto';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {

  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  cashiers: Page<UserWithBoxOfficeDto>;

  tab: number = 0;

  config: MainSettingConfig = new MainSettingConfig();

  cashiersPage: number = 0;
  cashiersCount: number = 20;

  constructor(private _mainSettingService: MainSettingsService, private _userDetailsService: UserDetailsService,
    private _bookingService: BookingService) {
    this.config = this._mainSettingService.getConfig();
    this._mainSettingService.settingConfig$.subscribe(next => {
      this.config = next;
    }, error => {
      console.error(error);
    });
    this.user = this._userDetailsService.getUser();
    this._userDetailsService.user$.subscribe(next => {
      this.user = next;
    }, error => {
      console.error(error);
    });
    this._bookingService.findAllCashiersByBoxOffice(this.cashiersPage, this.cashiersCount).subscribe(next => {
      this.cashiers = next;
    }, error => {
      console.error(error);
    });
  }

  changeSetting(name: string, value: string) {
    this._mainSettingService.changeFieldOfObj(name, value);
  }

  ngOnInit() {
  }

}
