import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'localDayOfTheWeek' })
export class LocalDateTimeToDayOfWeek implements PipeTransform {


  transform(value: string, ...args): any {
    if (!value)
      return '';
    else {
      let str = new Date(value);
      return str.getDay();
    }
  }
}
