import { Pipe, PipeTransform } from '@angular/core';
import { Url } from '../config/url';
import { ImageServiceService } from '../service/backend/image.service';


@Pipe({
  name: 'img',
  pure: false
})
export class ImagePipe implements PipeTransform {
  private cachedData: any = null;
  private cachedUrl = '';
  private id: number;

  constructor(private _imageServiceService: ImageServiceService) {
  }

  image: string = Url.image + '/file/';

  transform(value: any, ...args): any {
    if (!value)
      return;
    else
      return this.image + value;
  }

}
