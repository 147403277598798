<div class="container">
  <div class="whiteContainer">
    <div class="filter_container_name">
      <p *ngIf="user">{{ user.firstName }} {{ user.lastName }}</p>
    </div>
    <form class="filter_container_form" #mainForm>
      <label style="width: 22%" class="search_svg_container">
        <svg class="search_svg" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.7272 17.0788L13.2881 12.4355C14.3889 11.1154 15.0091 9.45702 15.0463 7.73428C15.0835 6.01155 14.5354 4.32761 13.4925 2.96072C12.4497 1.59383 10.9746 0.625792 9.31089 0.216573C7.6472 -0.192646 5.89454 -0.0185567 4.34251 0.710073C2.79047 1.4387 1.53196 2.67827 0.774929 4.22393C0.0178962 5.7696 -0.192349 7.52887 0.178934 9.21103C0.550217 10.8932 1.48081 12.3976 2.81693 13.4756C4.15306 14.5536 5.81475 15.1407 7.52744 15.1399C9.0714 15.1441 10.5784 14.6651 11.8397 13.7692L16.3116 18.4454C16.4027 18.5421 16.5125 18.619 16.6342 18.6714C16.7559 18.7238 16.887 18.7505 17.0194 18.75C17.2117 18.7497 17.3997 18.6927 17.56 18.5859C17.7204 18.4791 17.846 18.3273 17.9214 18.1493C17.9968 17.9714 18.0186 17.775 17.984 17.5847C17.9495 17.3944 17.8602 17.2185 17.7272 17.0788ZM7.52744 1.96762C8.62794 1.96762 9.70374 2.29595 10.6188 2.9111C11.5338 3.52624 12.247 4.40056 12.6682 5.42351C13.0893 6.44645 13.1995 7.57207 12.9848 8.65802C12.7701 9.74398 12.2401 10.7415 11.462 11.5244C10.6838 12.3073 9.69233 12.8405 8.61297 13.0565C7.53361 13.2725 6.41482 13.1617 5.39808 12.738C4.38135 12.3142 3.51233 11.5967 2.90092 10.6761C2.28951 9.75545 1.96317 8.67309 1.96317 7.56586C1.96425 6.08145 2.55083 4.65815 3.5941 3.60851C4.63737 2.55888 6.05203 1.96871 7.52744 1.96762Z"
            fill="#C4C4C4"
          />
        </svg>
        <input
          id="researcher"
          type="text"
          class="{{ configSetting.color }}_main-input"
          #researcher
          timeInput
          [time]="500"
          (timeInput)="loadEventWithResearcher(researcher.value)"
          style="padding: 7px 15px; padding-left: 34px !important"
        />
      </label>
      <label style="width: 19%">
        <select
          class="{{ configSetting.color }}_main-select"
          [(ngModel)]="filterCity"
          [ngModelOptions]="{ standalone: true }"
          (change)="changeFilterCity(filterCity)"
        >
          <option value="undefined" disabled>{{ 'return.city' | translate }}</option>
          <option value="undefined">{{ 'events.city' | translate }}</option>
          <option *ngFor="let one of allCities" [ngValue]="one.id" valueByLang [dictionary]="one.dictionary"></option>
        </select>
      </label>
      <label style="width: 19%">
        <select
          class="{{ configSetting.color }}_main-select"
          [(ngModel)]="filterCategory"
          [ngModelOptions]="{ standalone: true }"
          (change)="loadEventWithFilter()"
        >
          <option value="undefined" disabled>{{ 'return.category' | translate }}</option>
          <option value="undefined">{{ 'events.category' | translate }}</option>
          <option *ngFor="let one of categories" [ngValue]="one.id" valueByLang [dictionary]="one.name"></option>
        </select>
      </label>
      <label style="width: 19%">
        <select
          class="{{ configSetting.color }}_main-select"
          [(ngModel)]="filterPlatformHall"
          [ngModelOptions]="{ standalone: true }"
          (change)="loadEventWithFilter()"
        >
          <option value="undefined" disabled>{{ 'return.ploschadka' | translate }}</option>
          <option value="undefined">{{ 'events.ploschadka' | translate }}</option>
          <option *ngFor="let one of platformHalls" [ngValue]="one.id" valueByLang [dictionary]="one.name"></option>
        </select>
      </label>
      <label style="width: 19%; position: relative" class="label_btn">
        <div style="width: 100%; height: 100%" (click)="openCloseBlock(calendar)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.125 1.25H13.375V0H12.125V1.25H3.875V0H2.625V1.25H1.875C0.841125 1.25 0 2.09113 0 3.125V14.125C0 15.1589 0.841125 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V3.125C16 2.09113 15.1589 1.25 14.125 1.25ZM14.75 14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.53038 14.75 1.25 14.4696 1.25 14.125V5.875H14.75V14.125ZM14.75 4.625H1.25V3.125C1.25 2.78038 1.53038 2.5 1.875 2.5H2.625V3.75H3.875V2.5H12.125V3.75H13.375V2.5H14.125C14.4696 2.5 14.75 2.78038 14.75 3.125V4.625Z"
              fill="#727272"
            />
            <path d="M3.625 7.1875H2.375V8.4375H3.625V7.1875Z" fill="#727272" />
            <path d="M6.125 7.1875H4.875V8.4375H6.125V7.1875Z" fill="#727272" />
            <path d="M8.625 7.1875H7.375V8.4375H8.625V7.1875Z" fill="#727272" />
            <path d="M11.125 7.1875H9.875V8.4375H11.125V7.1875Z" fill="#727272" />
            <path d="M13.625 7.1875H12.375V8.4375H13.625V7.1875Z" fill="#727272" />
            <path d="M3.625 9.6875H2.375V10.9375H3.625V9.6875Z" fill="#727272" />
            <path d="M6.125 9.6875H4.875V10.9375H6.125V9.6875Z" fill="#727272" />
            <path d="M8.625 9.6875H7.375V10.9375H8.625V9.6875Z" fill="#727272" />
            <path d="M11.125 9.6875H9.875V10.9375H11.125V9.6875Z" fill="#727272" />
            <path d="M3.625 12.1875H2.375V13.4375H3.625V12.1875Z" fill="#727272" />
            <path d="M6.125 12.1875H4.875V13.4375H6.125V12.1875Z" fill="#727272" />
            <path d="M8.625 12.1875H7.375V13.4375H8.625V12.1875Z" fill="#727272" />
            <path d="M11.125 12.1875H9.875V13.4375H11.125V12.1875Z" fill="#727272" />
            <path d="M13.625 9.6875H12.375V10.9375H13.625V9.6875Z" fill="#727272" />
          </svg>
          <span class="calendar_text">{{ 'events.calendar_filter' | translate }}</span>
        </div>
        <div class="container_pop_up_calendar" style="display: none" #calendar>
          <span
            *ngFor="let one of loadRelevantMonths()"
            (click)="filterMonth = one; loadEventWithFilterMonth()"
            [ngClass]="{ active: filterMonth == one }"
          >
            {{ 'month_naz_vidminok.' + one | translate }}
          </span>
        </div>
      </label>
      <label style="width: 25%; margin-bottom: 0 !important">
        <input
          type="date"
          [(ngModel)]="filterDateFrom"
          [ngModelOptions]="{ standalone: true }"
          (change)="setDefaultDataToInput(false); loadEventWithFilterDate()"
          class="{{ configSetting.color }}_main-input"
          style="padding: 4.5px 15px; border: 0.5px solid #e5e5e5 !important"
        />
      </label>
      <label style="width: 25%; margin-bottom: 0 !important">
        <input
          type="date"
          [(ngModel)]="filterDateTo"
          [ngModelOptions]="{ standalone: true }"
          (change)="setDefaultDataToInput(true); loadEventWithFilterDate()"
          class="{{ configSetting.color }}_main-input"
          style="padding: 4.5px 15px; border: 1px solid #e5e5e5 !important"
        />
      </label>
      <div
        style="width: 15%; margin-bottom: 0 !important; padding: 0 !important"
        class="label_btn {{ configSetting.color }}_label_btn_long"
      >
        <div
          (click)="openCloseBlock(alphabet)"
          style="
            padding: 6px 15px;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span class="select_text">{{ 'events.alphabet' | translate }}</span>
          <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 10L0.870835 0.249999L12.1292 0.25L6.5 10Z" fill="#000000" />
          </svg>
        </div>
        <div class="container_pop_up_alphabet" #alphabet style="display: none">
          <label style="width: 49%; cursor: pointer; border-right: 1px solid #c4c4c4; margin-bottom: 0; text-align: center">
            <input type="radio" name="alphabet" (change)="sort = 'ASC'; loadEventWithFilter()" [checked]="sort == 'ASC'" />
            {{ 'aZ' | translate }}
          </label>
          <label style="width: 49%; cursor: pointer; margin-bottom: 0; text-align: center">
            <input type="radio" name="alphabet" (change)="sort = 'DESC'; loadEventWithFilter()" [checked]="sort == 'DESC'" />
            {{ 'zA' | translate }}
          </label>
        </div>
      </div>
      <div
        style="width: 15%; margin-bottom: 0 !important; padding: 0 !important"
        class="label_btn {{ configSetting.color }}_label_btn_long"
      >
        <select class="event-status" (change)="onEventStatusChange($event)">
          <option value="ACTIVE">Активні</option>
          <option value="ARCHIVE">Архівні</option>
        </select>
      </div>
      <label style="width: 19%; margin-bottom: 0 !important" class="label_btn label_btn_reset" (click)="resetFilter()">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.8782 5.42088L14.3242 2.73345C14.1549 2.4232 13.7894 2.261 13.4062 2.40264L11.6502 3.10814C11.3166 2.86802 10.966 2.66402 10.6033 2.50039L10.3362 0.6375C10.2909 0.274125 9.97572 0 9.60311 0H6.48643C6.11386 0 5.79863 0.274125 5.75401 0.631125L5.48626 2.4997C5.13493 2.65907 4.78995 2.86025 4.44145 3.10814L2.68059 2.40055C2.34202 2.27023 1.93827 2.41686 1.76966 2.72498L0.212737 5.41732C0.0257369 5.73464 0.102934 6.1462 0.393362 6.3757L1.88156 7.53877C1.85818 7.76332 1.84686 7.95952 1.84686 8.14582C1.84686 8.33212 1.85818 8.52832 1.88086 8.75288L0.391934 9.91668C0.107881 10.1412 0.0299869 10.5393 0.211309 10.8708L1.76538 13.5589C1.93468 13.8685 2.30088 14.0307 2.68408 13.889L4.44003 13.1835C4.77365 13.4236 5.12428 13.6276 5.48695 13.7912L5.75401 15.6534C5.79863 16.0175 6.11383 16.2916 6.48643 16.2916H8.92736C9.05981 16.2916 9.18024 16.218 9.24117 16.1011C9.30209 15.9842 9.29286 15.8433 9.21779 15.7349C8.67591 14.9586 8.38904 14.0483 8.38904 13.1041C8.38904 12.6636 8.45349 12.2223 8.58099 11.7923C8.61356 11.6818 8.59091 11.5628 8.51936 11.4721C8.44781 11.3822 8.33804 11.334 8.22256 11.3376C8.18431 11.3397 8.14676 11.3446 8.05043 11.3567C6.28881 11.3567 4.85586 9.92375 4.85586 8.16213C4.85586 6.4005 6.28881 4.96682 8.05043 4.96682C9.81206 4.96682 11.245 6.39977 11.2386 8.2202C11.2337 8.25775 11.2287 8.29527 11.2266 8.33352C11.2209 8.44897 11.2712 8.55947 11.3612 8.63032C11.4518 8.70187 11.5715 8.72313 11.6813 8.69195C12.3705 8.48652 13.0498 8.43764 13.8191 8.5772C13.9133 8.5942 14.0167 8.5517 14.096 8.49289C14.1747 8.43409 14.2271 8.32502 14.2377 8.22727C14.2391 8.21452 14.2427 8.15714 14.2427 8.14439C14.2427 7.92409 14.2271 7.71657 14.2087 7.53734L15.6969 6.375C15.981 6.15118 16.0589 5.75307 15.8782 5.42088Z"
            fill="#72002D"
          />
        </svg>
        <span class="calendar_text">{{ 'events.reset_filter' | translate }}</span>
      </label>
    </form>
  </div>
  <div class="container_tick_ret_dark" id="tickRetOpen">
    <div class="bg" (click)="openAndCloseTickRet('tickRetOpen')"></div>
    <div class="tickRet">
      <div class="container_tick_ret_block">
        <div class="container_tick_ret_close">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            (click)="openAndCloseTickRet('tickRetOpen')"
            class="click_open"
          >
            <path d="M1 1.00195L10.0012 10.0031" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.3213 1.00195L1.31836 10.0014" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <form class="form_barcod" [formGroup]="returnForm">
          <div class="returned-options">
            <div class="returned-option">
              <input id="ticketReturn" type="radio" [value]="true" formControlName="isReturnedTicket" />
              <label for="ticketReturn">Повернути квиток</label>
            </div>
            <div class="returned-option">
              <input id="orderReturn" type="radio" [value]="false" formControlName="isReturnedTicket" />
              <label for="orderReturn">Повернути замовлення</label>
            </div>
          </div>
          <ng-container *ngIf="isReturnedTicket; else orderReturn">
            <p class="return-label">{{ 'return.enterTicketBarcode' | translate }}</p>
            <input formControlName="returnedTicketNumber" autofocus appAutofocus type="text" class="barcod" placeholder="123456789" />
            <textarea
              style="resize: none"
              formControlName="returnedTicketReason"
              class="barcod"
              placeholder="{{ 'return.why' | translate }}"
            ></textarea>
          </ng-container>
          <ng-template #orderReturn>
            <p class="return-label">{{ 'return.enterOrder' | translate }}</p>
            <input formControlName="returnedOrderNumber" autofocus appAutofocus type="text" class="barcod" placeholder="123456789" />
            <textarea
              style="resize: none"
              formControlName="returnedOrderReason"
              class="barcod"
              placeholder="{{ 'return.why' | translate }}"
            ></textarea>
          </ng-template>
          <button class="btn_next" (click)="getPriceOneTicket('tickRetOpen', 'tickRetOpenNext')">
            {{ 'return.next' | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="container_tick_ret_dark" id="tickRetOpenNext">
    <div class="bg" (click)="openAndCloseTickRet('tickRetOpenNext')"></div>
    <div class="tickRetNext">
      <div class="container_tick_ret_block_next">
        <div class="container_tick_ret_close">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            (click)="openAndCloseTickRet('tickRetOpenNext')"
          >
            <path d="M1 1.00195L10.0012 10.0031" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.3213 1.00195L1.31836 10.0014" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="container_tick_ret_text">
          <p>{{ 'return.answer' | translate }}</p>
        </div>
        <p class="container_tick_ret_text" style="align-items: center">
          <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.6914 12.8261V22.4318H5.26409V12.8261H23.6914V12.8261ZM25.2597 11.2578H3.6958V24.0001H25.2597V11.2578Z"
              fill="#828282"
            />
            <path
              d="M19.3789 11.2578L21.5863 13.4652C21.5696 13.5465 21.5373 13.622 21.5373 13.7083C21.5373 14.411 22.1067 14.9795 22.8095 14.9795C22.8958 14.9795 22.9703 14.9472 23.0516 14.9315L25.26 17.1389V11.2578H19.3789Z"
              fill="#828282"
            />
            <path
              d="M20.8494 18.901C21.5521 18.901 22.1217 18.3314 22.1217 17.6287C22.1217 16.9261 21.5521 16.3564 20.8494 16.3564C20.1468 16.3564 19.5771 16.9261 19.5771 17.6287C19.5771 18.3314 20.1468 18.901 20.8494 18.901Z"
              fill="#828282"
            />
            <path
              d="M14.4776 20.9759C16.6029 20.9759 18.3258 19.4777 18.3258 17.6295C18.3258 15.7814 16.6029 14.2832 14.4776 14.2832C12.3523 14.2832 10.6294 15.7814 10.6294 17.6295C10.6294 19.4777 12.3523 20.9759 14.4776 20.9759Z"
              fill="#828282"
            />
            <path
              d="M8.10674 18.9C8.80885 18.9 9.37803 18.3308 9.37803 17.6287C9.37803 16.9266 8.80885 16.3574 8.10674 16.3574C7.40463 16.3574 6.83545 16.9266 6.83545 17.6287C6.83545 18.3308 7.40463 18.9 8.10674 18.9Z"
              fill="#828282"
            />
            <path
              d="M3.6958 17.1389L5.90316 14.9315C5.98452 14.9482 6.05999 14.9805 6.14625 14.9805C6.84903 14.9805 7.41754 14.411 7.41754 13.7083C7.41754 13.622 7.38519 13.5475 7.36853 13.4662L9.57687 11.2578H3.6958V17.1389V17.1389Z"
              fill="#828282"
            />
            <path
              d="M7.41852 21.5498C7.41852 20.847 6.84903 20.2785 6.14625 20.2785C6.05999 20.2785 5.9855 20.3108 5.90414 20.3265L3.6958 18.1191V24.0002H9.57687L7.36951 21.7928C7.38617 21.7115 7.41852 21.635 7.41852 21.5498Z"
              fill="#828282"
            />
            <path
              d="M22.8095 20.2775C22.1067 20.2775 21.5382 20.847 21.5382 21.5498C21.5382 21.636 21.5706 21.7105 21.5872 21.7919L19.3789 24.0002H25.26V18.1191L23.0526 20.3265C22.9713 20.3098 22.8958 20.2775 22.8095 20.2775Z"
              fill="#828282"
            />
            <path
              d="M18.1063 8.97715C18.8084 8.97715 19.3775 8.40798 19.3775 7.70586C19.3775 7.00375 18.8084 6.43457 18.1063 6.43457C17.4041 6.43457 16.835 7.00375 16.835 7.70586C16.835 8.40798 17.4041 8.97715 18.1063 8.97715Z"
              fill="#828282"
            />
            <path
              d="M2.22598 11.2573C2.22598 10.4458 2.88466 9.78708 3.69625 9.78708H4.5L4.53626 9.66162L15.8838 3.44729L16.7512 3.70017C16.7757 3.77859 16.7846 3.86092 16.8267 3.9364C17.1639 4.55391 17.9363 4.77837 18.5538 4.44119C18.6283 4.40002 18.6793 4.33631 18.741 4.2824L19.6104 4.53724L21.5874 8.14626L21.3336 9.0147C21.2561 9.03822 21.1738 9.04704 21.0983 9.08821C20.8111 9.24504 20.62 9.50185 20.519 9.78708H24.2741L21.7394 5.1577H21.7404L18.9145 0L13.7548 2.82487H13.7558L5.15868 7.53267L0 10.3575L2.22598 14.4223V11.2573Z"
              fill="#828282"
            />
            <path
              d="M10.9105 7.83353C10.0568 8.3001 9.44219 9.00779 9.09717 9.78801H16.1868C16.1299 9.48906 16.0447 9.19598 15.8937 8.92055C15.0047 7.29934 12.7748 6.81317 10.9105 7.83353Z"
              fill="#828282"
            />
          </svg>
          <span style="color: #828282; font-size: 18px; font-weight: bold; display: inline-block; vertical-align: top; margin-left: 10px">
            {{ priceOneTicket }}{{ 'money' | translate }}
          </span>
        </p>
        <div class="container_tick_ret_block_btn">
          <div class="container_tick_btn">
            <button class="container_tick_ret_btn container_tick_btn_yes pointer" (click)="confirmReturn()">
              {{ 'return.yes' | translate }}
            </button>
            <button class="container_tick_ret_btn container_tick_btn_no pointer" (click)="discardReturn()">
              {{ 'return.no' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="index_container">
    <div class="index_container_declaration">
      <h2 class="main-h2">{{ 'return.title' | translate }}</h2>
    </div>
    <div class="returnedTickets" *ngIf="events">
      <app-returned-tickets-one
        *ngFor="let one of events.content; trackBy: trackById"
        [one]="one"
        (retTic)="returnOneInfo.idEvent = $event; openAndCloseTickRet('tickRetOpen')"
      ></app-returned-tickets-one>
      <div class="container_pageable">
        <app-pageable
          *ngIf="events"
          [page]="events"
          (openPage)="open($event)"
          (countOnPage)="changeCount($event)"
          [range]="4"
        ></app-pageable>
      </div>
    </div>
  </div>
</div>
