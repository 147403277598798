import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { PlatformHall } from '../../../../../shared/models/PlatformHall';
import { TicketsRevertService } from '../../../../../shared/service/backend/tickets-revert.service';
import { HallService } from '../../../../../shared/service/backend/hall.service';
import { ExcelBuilderService } from '../../../../../shared/service/backend/excel-builder.service';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { RevertBookingTicketInfoDto } from '../../../../../shared/models/dto/RevertBookingTicketInfoDto';

class BinDate {
  date: string;
  bins: RevertBookingTicketInfoDto[] = [];
}

class Pageable {
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: number;
  first: number;
  numberOfElements: number;
}

@Component({
  selector: 'app-history-booked-canceled',
  templateUrl: './history-booked-canceled.component.html',
  styleUrls: ['./history-booked-canceled.component.css'],
})
export class HistoryBookedCanceledComponent implements OnInit {

  bins: BinDate[] = [];
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  lang: string;

  @ViewChild('mainForm') mainForm: ElementRef;

  pageable: Pageable = new Pageable();

  page: number = 0;
  count: number = 20;

  platforms: PlatformHall[] = [];

  constructor(private _ticketsRevertService: TicketsRevertService, private _userDetailsService: UserDetailsService,
    private _translateService: TranslateService, private _hallService: HallService,
    private _excelBuilderService: ExcelBuilderService) {
    this.user = this._userDetailsService.getUser();
    this._userDetailsService.user$.subscribe(next => {
      this.user = next;
    }, error => {
      console.error(error);
    });
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(next => {
      this.lang = next.lang;
    }, error => {
      console.error(error);
    });
    this._ticketsRevertService.filterTicketRevertBoxOfficeWithCalceledBook(this.page, this.count, 'DESC').subscribe(next => {
      this.groupEventByDate(next);
      this.pageable = next;
    }, error => {
      console.error(error);
    });
    this._hallService.findAllPlatformHallsAvailable().subscribe(next => {
      this.platforms = next;
    }, error => {
      console.error(error);
    });
  }

  filter() {
    this.page = 0;
    this.loadBins();
  }

  open(i) {
    this.page = i;
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.loadBins();
  }

  changeCount(i) {
    this.count = i;
    this.page = 0;
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.loadBins();
  }

  loadBins() {
    this._ticketsRevertService.filterTicketRevertBoxOfficeWithCalceledBook(this.page, this.count, 'DESC', this.mainForm.nativeElement).subscribe(next => {
      this.groupEventByDate(next);
      this.pageable = next;
    }, error => {
      console.error(error);
    });
  }

  resetFilter() {
    this.page = 0;
    this.mainForm.nativeElement.reset();
    this.loadBins();
  }

  groupEventByDate(arr) {
    this.bins = [];
    let tempArr: BinDate = new BinDate();
    arr.content.forEach((value, index) => {
      if (index == 0) {
        tempArr.date = value.date;
        tempArr.bins.push(value);
        if (arr.content.length == 1) {
          this.bins.push(tempArr);
        }
      } else {
        if (new Date(value.date).toLocaleDateString() == new Date(tempArr.date).toLocaleDateString()) {
          tempArr.bins.push(value);
          if (index == (arr.content.length - 1)) {
            this.bins.push(tempArr);
          }
        } else {
          this.bins.push(tempArr);
          tempArr = new BinDate();
          tempArr.date = value.date;
          tempArr.bins.push(value);
          if (index == (arr.content.length - 1)) {
            this.bins.push(tempArr);
          }
        }
      }
    });
  }

  nextPage() {
    this.page++;
    this.loadBins();
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  prevPage() {
    this.page--;
    this.loadBins();
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  ngOnInit() {
  }

  createExcel() {
    this._excelBuilderService.createTicketRevertHistoryExcel(this.page, this.count, 'DESC', this.mainForm.nativeElement).subscribe(next => {
    }, error => {
      console.error(error);
    });
  }

  openCloseDetails(id: string, ids: string) {
    document.getElementById(id).classList.toggle('display-flex');
    document.getElementById(ids).classList.toggle('tranforms');
  }

}
