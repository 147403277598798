<div class="date-wrapper">
  <label class="date-label">Період для звіту:</label>

  <nz-range-picker
    nzSize="large"
    [nzDisabled]="!enableEventDate"
    [nzFormat]="dateFormat"
    [nzDefaultPickerValue]="eventDate"
    [nzAllowClear]="false"
    [(ngModel)]="eventDate"
  ></nz-range-picker>

  <div class="date-label">
    <label>Врахувати період для звіту:</label>
    <nz-switch nzSize="small" [(ngModel)]="enableEventDate"></nz-switch>
  </div>
</div>
<div class="date-wrapper">
  <label class="date-label">Період події:</label>

  <nz-range-picker
    nzSize="large"
    [nzDisabled]="!enableReportDate"
    [nzFormat]="dateFormat"
    [nzDefaultPickerValue]="reportDate"
    [nzAllowClear]="false"
    [(ngModel)]="reportDate"
  ></nz-range-picker>

  <div class="date-label">
    <label>Врахувати період події:</label>
    <nz-switch nzSize="small" [(ngModel)]="enableReportDate"></nz-switch>
  </div>
</div>
<form class="form" [formGroup]="filterForm" (submit)="applyFilter($event)">
  <div class="form-row">
    <div class="form-item">
      <label class="main-label">Події</label>
      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Введіть назву події" formControlName="eventId">
        <ng-container *ngFor="let event of events">
          <nz-option [nzLabel]="event.name?.valueUA" [nzValue]="event.id"></nz-option>
        </ng-container>
      </nz-select>
    </div>
    <div class="form-item">
      <label class="main-label">Організатор</label>
      <select formControlName="organizerId">
        <option [value]="null">Всі</option>
        <option *ngFor="let organizer of organizers" [value]="organizer.id">{{ organizer.firstName + ' ' + organizer.lastName }}</option>
      </select>
    </div>
    <div class="form-item">
      <label class="main-label">Сортувати:</label>
      <select formControlName="sortOptionType" (change)="sortOptionTypeChange()">
        <option value="BY_SELLER">По продавцях</option>
        <option value="BY_EVENT">По події</option>
      </select>
    </div>
    <div class="form-item">
      <label class="main-label">Місто</label>
      <select formControlName="cityId">
        <option [value]="null">Всі</option>
        <option *ngFor="let city of cities" [value]="city.id">{{ city.dictionary.valueUA }}</option>
      </select>
    </div>
    <div class="form-item">
      <label class="main-label">Спосіб оплати</label>
      <select formControlName="isOnline" (change)="onReportStatusChange($event)">
        <option [value]="null">Всі</option>
        <option value="true">Сайт</option>
        <option value="false">Каса</option>
      </select>
    </div>
  </div>
  <div *ngIf="onlineStatus === 'false'" class="form-row additional-row">
    <div class="form-item">
      <label class="main-label">Тип оплати</label>
      <select formControlName="typeOfPayment">
        <option [value]="null">Всі</option>
        <option *ngFor="let option of paymentOptions" [value]="option.value">{{ option.label }}</option>
      </select>
    </div>
    <div class="form-item">
      <label class="main-label">Мережа кас</label>
      <select formControlName="netBoxOfficeId" (change)="loadBoxOffices()">
        <option [value]="null">Всі</option>
        <option *ngFor="let option of allNetBoxes" [value]="option.id">{{ option.name.valueUA }}</option>
      </select>
    </div>
    <div class="form-item">
      <label class="main-label">Каса</label>
      <select formControlName="boxOfficeId" (change)="loadCashiers()">
        <option [value]="null">Всі</option>
        <option *ngFor="let option of filteredBoxes" [value]="option.id">{{ option.name.valueUA }}</option>
      </select>
    </div>
    <div class="form-item">
      <label class="main-label">Касир</label>
      <select formControlName="cashierId">
        <option [value]="null">Всі</option>
        <option *ngFor="let option of filteredCashiers" [value]="option.id">{{ option.firstName + ' ' + option.lastName }}</option>
      </select>
    </div>
  </div>
</form>
<div class="actions-row">
  <button type="button" style="margin-left: auto" (click)="displayTable()" class="action action-confirm">Показати</button>
  <button type="button" (click)="onReportGenerate()" class="action action-confirm">Скачати EXCEL</button>
  <button class="action action-reset" type="reset" (click)="resetFilter()">Скинути</button>
</div>

<ng-container *ngIf="generalSeller || generalEvent">
  <ng-container *ngIf="sortOptionType === 'BY_SELLER'; else generalByType">
    <p class="table-caption">Зведені дані по продавцях</p>
    <table *ngIf="generalSeller">
      <thead>
        <tr>
          <th rowspan="2">Назва</th>
          <th colspan="2">{{ 'accountant.event_statistics.general.sold' | translate }}</th>
          <th colspan="2">{{ 'accountant.event_statistics.general.returned' | translate }}</th>
          <th rowspan="2">Знижка</th>
          <th colspan="2">{{ 'accountant.event_statistics.general.total' | translate }}</th>
        </tr>
        <tr>
          <th>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of generalSeller; last as isLast">
          <td [class.total-row]="isLast">{{ isLast ? 'Разом' : item?.sortOptionName }}</td>
          <td [class.total-row]="isLast">{{ item?.count }}</td>
          <td [class.total-row]="isLast">{{ item?.sum }}</td>
          <td [class.total-row]="isLast">{{ item?.countReverted }}</td>
          <td [class.total-row]="isLast">{{ item?.sumReverted }}</td>
          <td [class.total-row]="isLast">{{ item?.discount }}</td>
          <td [class.total-row]="isLast">{{ item?.totalCount }}</td>
          <td [class.total-row]="isLast">{{ item?.totalSum }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-template #generalByType>
    <p class="table-caption">Зведені дані по подіях</p>
    <table>
      <thead>
        <tr>
          <th rowspan="2">Подія</th>
          <th rowspan="2">Дата</th>
          <th colspan="2">{{ 'accountant.event_statistics.general.sold' | translate }}</th>
          <th colspan="2">{{ 'accountant.event_statistics.general.returned' | translate }}</th>
          <th rowspan="2">Знижка</th>
          <th colspan="2">{{ 'accountant.event_statistics.general.total' | translate }}</th>
        </tr>
        <tr>
          <th>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_quantity' | translate }}</th>
          <th>{{ 'accountant.event_statistics.general.ticket_amount' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of generalEvent; last as isLast">
          <td [class.total-row]="isLast">{{ isLast ? 'Разом' : item?.sortOptionName }}</td>
          <td [class.total-row]="isLast">{{ item?.eventDate | date : 'dd.MM.yy' }}</td>
          <td [class.total-row]="isLast">{{ item?.count }}</td>
          <td [class.total-row]="isLast">{{ item?.sum }}</td>
          <td [class.total-row]="isLast">{{ item?.countReverted }}</td>
          <td [class.total-row]="isLast">{{ item?.sumReverted }}</td>
          <td [class.total-row]="isLast">{{ item?.discount }}</td>
          <td [class.total-row]="isLast">{{ item?.totalCount }}</td>
          <td [class.total-row]="isLast">{{ item?.totalSum }}</td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</ng-container>
