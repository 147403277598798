import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { ColectionService } from '../../../../../shared/service/backend/colection.service';
import { Page } from '../../../../../shared/models/Page';
import { CollectionBaseDto } from '../../../../../shared/models/dto/CollectionBaseDto';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { CollectionInfoDto } from '../../../../../shared/models/dto/CollectionInfoDto';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { CollectionStatus } from '../../../../../shared/models/enums/enums';
import { ExcelBuilderService } from '../../../../../shared/service/backend/excel-builder.service';
import { SideInfo, SideInfoRxService } from '../../../../source/side-info-bar/side-info-bar.component';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';

@Component({
  selector: 'app-collection-container',
  templateUrl: './collection-container.component.html',
  styleUrls: ['./collection-container.component.css'],
})
export class CollectionContainerComponent implements OnInit {

  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  step: number = 0;
  page: number = 0;
  count: number = 9;

  popUpMain: any;

  collections: Page<CollectionBaseDto>;

  mainInfo: CollectionInfoDto = new CollectionInfoDto();

  @ViewChild('mainForm') mainForm: ElementRef;

  lang: string;

  cashiers: Page<UserWithBoxOfficeDto>;

  status: CollectionStatus = 'CONFIRM';

  constructor(private _userDetailService: UserDetailsService, private _collectionService: ColectionService,
    private _mainLoaderService: MainLoaderService, private _translateService: TranslateService,
    private _bookingService: BookingService, private _excelBuilderService: ExcelBuilderService,
    private _sideInfoRxService: SideInfoRxService) {
    this.user = this._userDetailService.getUser();
    this._userDetailService.user$.subscribe(next => {
      this.user = next;
    }, error => {
      console.error(error);
    });
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(next => {
      this.lang = next.lang;
    }, error => {
      console.error(error);
    });
    this._mainLoaderService.startLoader();
    this._collectionService.filterCollection(this.page, this.count, this.status).subscribe(next => {
      this.collections = next;
      this._mainLoaderService.endLoader();
    }, error => {
      this._mainLoaderService.endLoader();
      console.error(error);
    });
    this._collectionService.findByUserNotCollection().subscribe(next => {
      this.mainInfo = next;
    }, error => {
      console.error(error);
    });
    this._bookingService.findAllCashiersByBoxOffice(0, 999).subscribe(next => {
      this.cashiers = next;
    }, error => {
      console.error(error);
    });
  }

  loadCollections() {
    this._mainLoaderService.startLoader();
    this._collectionService.filterCollection(this.page, this.count, this.status, this.mainForm.nativeElement)
      .subscribe(next => {
        this._mainLoaderService.endLoader();
        this.collections = next;
      }, error => {
        this._mainLoaderService.endLoader();
        console.error(error);
      });
  }

  openPage(i) {
    this.page = i;
    this.loadCollections();
  }

  changeCount(i) {
    this.count = i;
    this.page = 0;
    this.loadCollections();
  }

  resetFilter() {
    this.page = 0;
    this.mainForm.nativeElement.reset();
    this.loadCollections();
  }

  ngOnInit() {
  }

  downLoad() {
    this._excelBuilderService.createCollectionExcel(this.page, this.count, this.status, this.mainForm.nativeElement).subscribe(next => {

    }, error => {
      console.error(error);
    });
  }

  openClosePopUp(id: string, status: boolean, type?: number, idCollection?: number) {
    if (status) {
      this._mainLoaderService.startLoader();
      if (type) {
        this._collectionService.findOne(idCollection).subscribe(next => {
          this.popUpMain = next;
          this._mainLoaderService.endLoader();
        }, error => {
          this._mainLoaderService.endLoader();
          console.error(error);
        });
      } else {
        this._collectionService.findOneRevert(idCollection).subscribe(next => {
          this.popUpMain = next;
          this.popUpMain.allPrice = next.priceMinus;
          this._mainLoaderService.endLoader();
        }, error => {
          this._mainLoaderService.endLoader();
          console.error(error);
        });
      }
    }
    document.getElementById(id).classList.toggle('display-flex');
  }

  collectBox() {
    this._mainLoaderService.startLoader();
    this._collectionService.createBoxOffice().subscribe(next => {
      this._collectionService.filterCollection(this.page, this.count, this.status).subscribe(next => {
        this.collections = next;
        this._mainLoaderService.endLoader();
        this._sideInfoRxService.add(new SideInfo().setInfo('Інкасація пройшла успішно!').setDate());
      }, error => {
        this._mainLoaderService.endLoader();
        console.error(error);
      });
      this._collectionService.findByUserNotCollection().subscribe(next => {
        this.mainInfo = next;
      }, error => {
        console.error(error);
      });
    }, error => {
      this._mainLoaderService.endLoader();
      if (error.status == 404) {
        this._sideInfoRxService.add(new SideInfo().setInfo('Немає що інкасувати!').setDate().setError(''));
        return;
      }
      this._sideInfoRxService.add(new SideInfo().setInfo('Інкасація пройшла не успішно!').setDate().setError(''));
      console.error(error);
    });
  }

  togggleDetails(id: string, it: string) {
    document.getElementById(id).classList.toggle('display-flex');
    document.getElementById(it).classList.toggle('transform');
  }

  openClosePop(id: string) {
    document.getElementById(id).classList.toggle('display-flex');
  }

}
