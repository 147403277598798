import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { Page } from '../../models/Page';
import { TicketRevertHistoryDto } from '../../models/dto/TicketRevertHistoryDto';
import { RevertBookingTicketInfoDto } from '../../models/dto/RevertBookingTicketInfoDto';

@Injectable({ providedIn: 'root' })
export class TicketsRevertService {
  controller = '/ticket-revert';

  constructor(private _httpClient: HttpClient) {}

  revertTicket(idEvent: number, idTicket: string | string[], text: string): Observable<any> {
    return this._httpClient
      .post(this.controller + `/box-office`, new FormData(), {
        params: new HttpParams()
          .set('idEvent', idEvent + '')
          .set('barcode', idTicket + '')
          .set('text', text + ''),
      })
      .pipe(catchError(err => throwError(err)));
  }

  filterTicketRevertBoxOffice(
    page: number,
    size: number,
    sort: 'ASC' | 'DESC',
    form?: HTMLFormElement,
  ): Observable<Page<TicketRevertHistoryDto>> {
    let body = `?page=${page}&size=${size}&sort=date,${sort}&`;
    let data = new FormData(form);
    body += this.parseFormData(data, 'paymasters');
    body += this.parseFormData(data, 'eventId');
    body += this.parseFormData(data, 'platformHallsId');
    body += this.parseFormData(data, 'dateFrom', true);
    body += this.parseFormData(data, 'dateTo', true, true);
    return this._httpClient
      .get<Page<TicketRevertHistoryDto>>(this.controller + '/box-office/filter-ticket-revert-box-office' + body)
      .pipe(catchError(err => throwError(err)));
  }

  filterTicketRevertBoxOfficeWithCalceledBook(
    page: number,
    size: number,
    sort: 'ASC' | 'DESC',
    form?: HTMLFormElement,
  ): Observable<Page<RevertBookingTicketInfoDto>> {
    let body = `?page=${page}&size=${size}&sort=date,${sort}&`;
    let data = new FormData(form);
    body += this.parseFormData(data, 'paymasters');
    body += this.parseFormData(data, 'eventId');
    body += this.parseFormData(data, 'platformHallsId');
    body += this.parseFormData(data, 'dateFrom', true);
    body += this.parseFormData(data, 'dateTo', true, true);
    return this._httpClient
      .get<Page<RevertBookingTicketInfoDto>>(this.controller + '/box-office/filter-revert-ticket' + body)
      .pipe(catchError(err => throwError(err)));
  }

  getPrice(eventId: number, ticket: string): Observable<number> {
    return this._httpClient
      .get<number>(this.controller + '/price?idEvent=' + eventId + '&order=' + ticket)
      .pipe(catchError(err => throwError(err)));
  }

  getOrderPrice(eventId: number, orderId: string): Observable<number> {
    let params = new HttpParams();

    params = params.set('idEvent', String(eventId));
    params = params.set('order', String(orderId));

    return this._httpClient.get<number>(`${this.controller}/byOrder`, { params }).pipe(catchError(err => throwError(err)));
  }

  parseFormData(data, name, typeDate: boolean = false, isDateTo?: boolean): string {
    let body = '';
    for (let i = 0; i < data.getAll(name).length; i++) {
      if (data.getAll(name)[i] + '' != 'null' && data.getAll(name)[i] + '' != '') {
        if (typeDate) {
          body += name + '=' + data.getAll(name)[i] + (isDateTo ? 'T23:59&' : 'T00:00&');
        } else {
          body += name + '=' + data.getAll(name)[i] + '&';
        }
      }
    }
    return body;
  }

  public returnOrder(options): Observable<any> {
    return this._httpClient.post(`${this.controller}/by-order`, options).pipe(catchError(err => throwError(err)));
  }

  public returnOrderId(options): Observable<any> {
    let params = new HttpParams();
    params = params.set('idEvent', String(options.idEvent));
    params = params.set('order', options.order);
    params = params.set('text', options.text);

    return this._httpClient.post(`${this.controller}/by-order`, {}, { params }).pipe(catchError(err => throwError(err)));
  }

  public returnTicketId(options): Observable<any> {
    let params = new HttpParams();
    params = params.set('idEvent', String(options.idEvent));
    params = params.set('barcode', options.order);
    params = params.set('text', options.text);

    return this._httpClient.post(`${this.controller}/box-office`, {}, { params }).pipe(catchError(err => throwError(err)));
  }
}
