<div class="container">
  <div class="section-item">
    <div class="cg-table">
      <h4>
        Статистика за період:
        <span>{{ dateRange[0] | date:dateFormat }} - {{ dateRange[1] | date:dateFormat }}</span>
      </h4>
      <nz-table
        #basicTable
        [nzData]="kgPreviewData"
        [nzShowPagination]="false"
        [nzPageSize]="500"
        nzSize="small"
        [nzScroll]="{y: '200px'}"
        [nzBordered]="true"
      >
        <thead>
          <tr>
            <th rowspan="2">Тип</th>
            <th colspan="2">Всього</th>
            <th colspan="2">Готівка</th>
            <th colspan="2">Термінал</th>
            <th colspan="2">Вендор</th>
            <th colspan="2">Повернуто</th>
          </tr>
          <tr style="font-size: 10px">
            <th>К-сть</th>
            <th>Сума</th>
            <th>К-сть</th>
            <th>Сума</th>
            <th>К-сть</th>
            <th>Сума</th>
            <th>К-сть</th>
            <th>Сума</th>
            <th>К-сть</th>
            <th>Сума</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data; let i=index;">
            <td>{{ statisticRows[i] }}</td>
            <td>{{ data?.allStatistic?.count }}</td>
            <td>{{ data?.allStatistic?.sum }}</td>
            <td>{{ data?.cashStatistic?.count }}</td>
            <td>{{ data?.cashStatistic?.sum }}</td>
            <td>{{ data?.cardStatistic?.count }}</td>
            <td>{{ data?.cardStatistic?.sum }}</td>
            <td>{{ data?.vendorStatistic?.count }}</td>
            <td>{{ data?.vendorStatistic?.sum }}</td>
            <td>{{ data?.revertedStatistic?.count }}</td>
            <td>{{ data?.revertedStatistic?.sum }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
    <div class="cg-form">
      <nz-range-picker
        nzSize="large"
        [nzFormat]="dateFormat"
        [nzDefaultPickerValue]="dateRange"
        [nzAllowClear]="false"
        [(ngModel)]="dateRange"
      ></nz-range-picker>

      <div class="criteria-row">
        <nz-select class="criteria-select" nzPlaceHolder="Організатор" [(ngModel)]="eventType" nzSize="large">
          <nz-option nzLabel="Всі" [nzValue]="null"></nz-option>
          <nz-option nzLabel="Власні" [nzValue]="true"></nz-option>
          <nz-option nzLabel="Чужі" [nzValue]="false"></nz-option>
        </nz-select>
        <nz-select class="criteria-select" nzPlaceHolder="Тип оплати" [(ngModel)]="paymentType" nzSize="large">
          <nz-option nzLabel="Всі" [nzValue]="null"></nz-option>
          <nz-option nzLabel="Готівка" nzValue="CASH"></nz-option>
          <nz-option nzLabel="Термінал" nzValue="CARD"></nz-option>
        </nz-select>
      </div>

      <div class="check-actions">
        <label nz-checkbox class="check-item" [(ngModel)]="isVendor">Врахувати контрагента</label>
        <label nz-checkbox class="check-item" [(ngModel)]="isReverted">Врахувати повернення</label>
      </div>
      <label nz-checkbox class="cg-check" [(ngModel)]="isRevertCounted">Зведений звіт</label>

      <div class="actions-group">
        <nz-button-group class="cg-actions-block">
          <button class="action-button" (click)="loadKGTableData()" nz-button>
            <i class="action-icon" nz-icon nzType="form" nzTheme="outline"></i>
            Сформувати КГ-13
          </button>
          <button class="action-button" nz-button (click)="loadReport()">
            Завантажити КГ-13
            <i class="action-icon" nz-icon nzType="file-excel" nzTheme="outline"></i>
          </button>
        </nz-button-group>

        <div class="cg-actions">
          <nz-button-group class="cg-actions-block">
            <button class="action-button" nz-button (click)="loadSellsData()">
              <i class="action-icon" nz-icon nzType="form" nzTheme="outline"></i>
              Сформувати дані
            </button>
            <button class="action-button" nz-button [disabled]="true">
              Завантажити дані
              <i class="action-icon" nz-icon nzType="file-excel" nzTheme="outline"></i>
            </button>
          </nz-button-group>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h4>
      Статистика за період:
      <span>{{ dateRange[0] | date:dateFormat }} - {{ dateRange[1] | date:dateFormat }}</span>
    </h4>
    <nz-table #lowerTable [nzData]="sellsPreviewData" [nzShowPagination]="true" [nzPageSize]="6" nzSize="small" [nzBordered]="true">
      <thead>
        <tr>
          <th rowspan="2">Подія</th>
          <th rowspan="2">Дата події</th>
          <th rowspan="2">Організатор</th>
          <th colspan="2">Готівка</th>
          <th colspan="2">Термінал</th>
          <th colspan="2">Вендор</th>
          <th colspan="2">Повернення</th>
        </tr>
        <tr style="font-size: 10px">
          <th>К-сть</th>
          <th>Сума</th>
          <th>К-сть</th>
          <th>Сума</th>
          <th>К-сть</th>
          <th>Сума</th>
          <th>К-сть</th>
          <th>Сума</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of lowerTable.data">
          <td>{{ data?.eventName }}</td>
          <td>{{ data?.eventDate | date:dateFormat }}</td>
          <td>{{ data?.organizator }}</td>
          <td>{{ data?.cashStatistic?.count }}</td>
          <td>{{ data?.cashStatistic?.sum }}</td>
          <td>{{ data?.cardStatistic?.count }}</td>
          <td>{{ data?.cardStatistic?.sum }}</td>
          <td>{{ data?.vendorStatistic?.count }}</td>
          <td>{{ data?.vendorStatistic?.sum }}</td>
          <td>{{ data?.revertedStatistic?.count }}</td>
          <td>{{ data?.revertedStatistic?.sum }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
