import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Config } from '../../../../../../shared/config/config';
import { AgentOptions } from '../../types/interfaces';
@Component({
  selector: 'app-agent-add',
  templateUrl: './agent-add.component.html',
  styleUrls: ['./agent-add.component.css'],
})
export class AgentAddComponent implements OnInit {
  @Output()
  addAgent: EventEmitter<AgentOptions> = new EventEmitter();

  addAgentForm: FormGroup;
  PHONE_PATTERN = Config.PHONE_MASK;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  onAddAgent(): void {
    this.addAgent.emit(this.addAgentForm.value);
    this.addAgentForm.reset();
  }

  private initForm(): void {
    this.addAgentForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.maxLength(50)]],
      phone: ['', [Validators.required, Validators.pattern(/(38) \([0-9 ]{3}\)\-[0-9 ]{2}\-[0-9 ]{2}\-[0-9 ]{3}/)]],
    });
  }
}
