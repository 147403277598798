import { Component, Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

export class SideInfo {
  constructor(public date?: Date, public info?: string, public error?: string) {}

  setInfo(info: string): this {
    this.info = info;
    return this;
  }

  setDate(): this {
    this.date = new Date();
    return this;
  }

  setError(error: string): this {
    this.error = error;
    return this;
  }

  public isAfter(): boolean {
    try {
      let d = new Date(this.date.toISOString());
      d.setMilliseconds(5000);
      return d.getTime() > new Date().getTime();
    } catch (e) {}
  }

  public isBefore(): boolean {
    try {
      let d = new Date(this.date.toISOString());
      d.setMilliseconds(5000);
      return d.getTime() < new Date().getTime();
    } catch (e) {}
  }

  public compare(obj: SideInfo) {
    try {
      if (obj.date.getTime() > this.date.getTime()) {
        return -1;
      }
      if (obj.date.getTime() < this.date.getTime()) {
        return 1;
      }
      return 0;
    } catch (e) {}
  }
}

export class ErrorStatus {
  error;
  email;
  message;
  infoMessage;
  url;
}

@Injectable({ providedIn: 'root' })
export class SideInfoRxService {
  private _info: Subject<SideInfo> = new Subject<SideInfo>();
  info$ = this._info.asObservable();

  add(s: SideInfo) {
    this._info.next(s);
  }
}

@Component({
  selector: 'app-side-info-bar',
  templateUrl: './side-info-bar.component.html',
  styleUrls: ['./side-info-bar.component.css'],
})
export class SideInfoBarComponent implements OnInit {
  last: SideInfo;
  info: SideInfo[] = [];
  errorStatus: ErrorStatus;

  constructor(private _sideInfoRxService: SideInfoRxService) {
    _sideInfoRxService.info$.subscribe(
      next => {
        if (this.info.length == 0) this.setTimerDelete();
        this.setLast(next);
        this.info.push(next);
        this.sort();
      },
      error => {
        console.error(error);
      },
    );
  }

  sort() {
    this.info = this.info.sort((a, b) => b.compare(a));
  }

  delete(o: SideInfo) {
    try {
      if (o == this.last) this.last = null;
      this.info = this.info.filter(value => value != o);
      this.sort();
    } catch (e) {}
  }

  s;

  setLast(l: SideInfo) {
    try {
      this.last = l;
      try {
        clearTimeout(this.s);
      } catch (e) {}
      this.s = setTimeout(() => {
        this.last = null;
      }, 1000);
    } catch (e) {}
  }

  setTimerDelete() {
    this.info = this.info.filter(value => value.isAfter());
    this.sort();
    setTimeout(() => {
      if (this.info.length > 0) this.setTimerDelete();
    }, 4000);
  }

  sendError(i: SideInfo) {
    if (i.error) {
      console.error(i.error);
      this.errorStatus = new ErrorStatus();
      this.errorStatus.error = i.error;
      this.errorStatus.infoMessage = i.info;
      this.errorStatus.url = window.location.href;
    }
  }

  send() {
    // this._errorStatusService.send(this.errorStatus).subscribe(next => {
    //   console.error(this.errorStatus);
    //   this.errorStatus = null;
    //   alert('ваша заявка № ' + next + ' буде розглянута в найближчий час \n дякуєм за допомогу');
    // }, error => {
    //   this.errorStatus = null;
    //   alert('помилка при надсиланні заявки');
    //   console.error(error);
    // });
  }

  trackById(index, obj) {
    return obj.date.getTime();
  }

  ngOnInit() {}
}
