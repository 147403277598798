import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Created by sold-out-admin-client in company M-PLUS.
 * User: nazar
 * Date: 21.02.2019
 * Time: 10:10
 */
@Injectable({ providedIn: 'root' })
export class OnlineService {

  private controller = '/online';

  constructor(private _httpClient: HttpClient) {
  }

  ping(): Observable<any> {
    return this._httpClient.get(this.controller + '/ping').pipe(catchError(err => throwError(err)));
  }

}
