import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MainLoaderService {
  load: 'ON' | 'OFF';
  private _load = new Subject<'ON' | 'OFF'>();
  load$ = this._load.asObservable();

  constructor() {
    this.endLoader();
    let timeout;
    this.load$.subscribe(next => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (next) {
        timeout = setTimeout(() => {
          this.endLoader();
          timeout = undefined;
        }, 60000);
      }
    });
  }

  startLoader() {
    this.load = 'ON';
    this._load.next(this.load);
  }

  endLoader() {
    this.load = 'OFF';
    this._load.next(this.load);
  }
}
