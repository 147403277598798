import { PartHall } from "./PartHall";
import { Seat } from "./Seat";
import { ColorPrice } from "./color-price";
import { Event } from "./Event";
import { TicketGroupWithoutScheme } from "./TicketGroupWithoutScheme";

export class Ticket {
  id: number;
  lastModified: string;
  partHall: PartHall;
  colorPrice: ColorPrice;
  realizationOffline: boolean;
  realizationOnline: boolean;
  ticketGroupWithoutScheme: TicketGroupWithoutScheme;
  ticketGroupWithOutSachem: TicketGroupWithoutScheme;
  // bin: Bin;
  finalPrice: number;
  firstname: string;
  lastname: string;
  sector: string;
  row: string;
  seatPosition: string;
  seat: Seat;
  event: Event;
  barcode: string;
  price: number;
  count?: number;
  firstName: string;
  lastName: string;
  constructor(seat?: Seat) {
    this.seat = seat;
  }
  fanzone?: boolean;
}
