import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AccessRoleService, IAccessList } from '../service/backend/access-roles.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuGuard implements CanActivate {
  constructor(private accessRoleService: AccessRoleService, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.accessRoleService.getCashierAccesses().pipe(
      map((exlude: IAccessList) => {
        return exlude?.excludedModule !== 'REPORT';
      }),
      tap(isGuardCheck => (!isGuardCheck ? this.router.navigateByUrl('/') : null)),
    );
  }
}
