import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { UserWithBoxOfficeDto } from '../../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { UserDetailsService } from '../../../../../../shared/service/user-details-service.service';

@Component({
  selector: 'app-accountant-wrapper',
  templateUrl: './accountant-wrapper.component.html',
  styleUrls: ['./accountant-wrapper.component.css']
})
export class AccountantWrapperComponent implements OnInit {
  public user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  constructor(private userDetailsService: UserDetailsService) { }

  ngOnInit() {
    this.user = this.userDetailsService.getUser();
    this.userDetailsService.user$.pipe(take(1)).subscribe(user => this.user = user);
  }
}
