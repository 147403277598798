<div class="container">
  <div class="white_container">
    <div class="filter_container_name">
      <p>{{user.firstName}} {{user.lastName}}</p>
    </div>
  </div>
  <div class="index_content">
    <div class="left">
      <ul>
        <li [ngClass]="{'active': tab == 0}" (click)="tab = 0">{{'settings.aboutCashier'|translate}}</li>
        <li [ngClass]="{'active': tab == 1}" (click)="tab = 1">{{'settings.cashiers'|translate}}</li>
        <li [ngClass]="{'active': tab == 2}" (click)="tab = 2">{{'settings.settings'|translate}}</li>
      </ul>
    </div>
    <div class="right">
      <div class="tab" *ngIf="tab == 0&&user">
        <h2 class="main-h2">{{'settings.viewEvents'|translate}}</h2>
        <p class="items_info">
          <span class="items_info_title" style="color: #27AE60">{{'settings.status'|translate}}:</span>
          <span class="items_info_value" *ngIf="user.role == 'CASHIER_PLUS'">Касир +</span>
          <span class="items_info_value" *ngIf="user.role == 'CASHIER'">Касир</span>
        </p>
        <p class="items_info">
          <span class="items_info_title">{{'settings.box'|translate}}:</span>
          <span class="items_info_value" valueByLang [dictionary]="user.boxOffice.name"></span>
        </p>
        <p class="items_info">
          <span class="items_info_title">{{'settings.mail'|translate}}:</span>
          <span class="items_info_value">{{user.mail}}</span>
        </p>
        <p class="items_info">
          <span class="items_info_title">{{'settings.netBox'|translate}}:</span>
          <span class="items_info_value" valueByLang [dictionary]="user.boxOffice.netBoxOffice.name"></span>
        </p>
        <p class="items_info">
          <span class="items_info_title">{{'settings.phone'|translate}}:</span>
          <span class="items_info_value">{{user.phone}}</span>
        </p>
      </div>
      <div class="tab" *ngIf="tab == 1 && cashiers">
        <div class="item_cashier" *ngFor="let one of cashiers.content">
          <div class="item_cashier_one_info border_right">
            <p class="item_cashier_one_info_main">{{one.firstName}} {{one.lastName}}</p>
          </div>
          <div class="item_cashier_one_info border_right">
            <div>
              <p class="item_cashier_one_info_up">{{'settings.mail'|translate}}</p>
              <p class="item_cashier_one_info_down">{{one.mail}}</p>
            </div>
          </div>
          <div class="item_cashier_one_info border_right">
            <div>
              <p class="item_cashier_one_info_up">{{'settings.saved'|translate}}</p>
              <p class="item_cashier_one_info_down">{{one.registrationDate|localdate}}</p>
            </div>
          </div>
          <div class="item_cashier_one_info border_right">
            <div>
              <p class="item_cashier_one_info_up">{{'settings.status'|translate}}</p>
              <p class="item_cashier_one_info_down" *ngIf="one.role == 'CASHIER'">Касир</p>
              <p class="item_cashier_one_info_down" *ngIf="one.role == 'CASHIER_PLUS'">касир +</p>
            </div>
          </div>
          <div class="item_cashier_one_info">
            <div>
              <p class="item_cashier_one_info_up">{{'settings.phone'|translate}}</p>
              <p class="item_cashier_one_info_down">{{one.phone}}</p>
            </div>
          </div>
          <div class="item_cashier_svg">
            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.7964 4.29655C9.7964 6.66207 8.21783 7.72414 6.26783 7.72414C4.31783 7.72414 2.73926 6.66207 2.73926 4.29655C2.73926 1.93103 4.31783 0 6.26783 0C8.21783 0 9.7964 1.93103 9.7964 4.29655Z"
                fill="white"/>
              <path
                d="M9.51786 7.6759C8.68214 8.35177 7.52143 8.6897 6.26786 8.6897C5.10714 8.6897 4.03929 8.40004 3.20357 7.82073C1.3 8.73797 0 10.7173 0 13.0345V13.5173C0 13.6621 0 13.8552 0.0464286 14H13C13 13.8552 13.0464 13.6621 13.0464 13.5173V13.0345C13 10.6207 11.5607 8.54487 9.51786 7.6759Z"
                fill="white"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="tab" *ngIf="tab == 2">
        <h2 class="main-h2">{{'settings.viewEvents'|translate}}</h2>
        <div class="settings_container">
          <div class="settings_item">
            <label class="border_right label" style="display: inline-block;cursor: pointer;width: auto;">
              <input type="radio" id="fourLine" class="check_active" name="line"
                     style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.line == 'FOUR'"
                     (change)="changeSetting('line', 'FOUR')">
              <label for="fourLine" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>
                </svg>
              </label>
              {{'settings.fourLine'|translate}}
            </label>
            <label class="label" style="display: inline-block;cursor: pointer;width: auto;">
              <input type="radio" id="oneLine" class="check_active" name="line"
                     style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.line == 'ONE'"
                     (change)="changeSetting('line', 'ONE')">
              <label for="oneLine" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>
                </svg>
              </label>
              {{'settings.oneLine'|translate}}
            </label>
            <!--<div class="settings_item">-->
            <!--<label class="border_right label" style="display: inline-block;cursor: pointer;width: auto;">-->
            <!--<input type="radio" id="colorDark" class="check_active" name="color"-->
            <!--style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.color == 'dark'"-->
            <!--(change)="changeSetting('color', 'dark')">-->
            <!--<label for="colorDark" class="label_wrapper">-->
            <!--<svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"-->
            <!--xmlns="http://www.w3.org/2000/svg">-->
            <!--<path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>-->
            <!--</svg>-->
            <!--</label>-->
            <!--dark-->
            <!--</label>-->
            <!--<label class="label" style="display: inline-block;cursor: pointer;width: auto;">-->
            <!--<input type="radio" id="colorWhite" class="check_active" name="color"-->
            <!--style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.color == 'white'"-->
            <!--(change)="changeSetting('color', 'white')">-->
            <!--<label for="colorWhite" class="label_wrapper">-->
            <!--<svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"-->
            <!--xmlns="http://www.w3.org/2000/svg">-->
            <!--<path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>-->
            <!--</svg>-->
            <!--</label>-->
            <!--white-->
            <!--</label>-->
            <!--</div>-->
          </div>
          <div class="settings_item">
            <label class="border_right label" style="display: inline-block;cursor: pointer;width: auto;">
              <input type="radio" id="withDate" class="check_active" name="date"
                     style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.date == 'WITH'"
                     (change)="changeSetting('date', 'WITH')">
              <label for="withDate" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>
                </svg>
              </label>
              {{'settings.withDate'|translate}}
            </label>
            <label class="label" style="display: inline-block;cursor: pointer;width: auto;">
              <input type="radio" id="withoutDate" class="check_active" name="date"
                     style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.date == 'WITHOUT'"
                     (change)="changeSetting('date', 'WITHOUT')">
              <label for="withoutDate" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>
                </svg>
              </label>
              {{'settings.withoutDate'|translate}}
            </label>
          </div>
        </div>
        <h2 class="main-h2">Мови</h2>
        <div class="settings_container">
          <div class="settings_item">
            <label class="border_right label" style="display: inline-block;cursor: pointer;width: auto;">
              <input type="radio" id="langUA" class="check_active" name="lang"
                     style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.lang == 'ua'"
                     (change)="changeSetting('lang', 'ua')">
              <label for="langUA" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>
                </svg>
              </label>
              українська
            </label>
            <label class="label" style="display: inline-block;cursor: pointer;width: auto;">
              <input type="radio" id="langRU" class="check_active" name="lang" disabled
                     style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.lang == 'ru'"
                     (change)="changeSetting('lang', 'ru')">
              <label for="langRU" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>
                </svg>
              </label>
              русский
            </label>
          </div>
        </div>
        <h2 class="main-h2">Пагінація</h2>
        <div class="settings_container">
          <div class="settings_item">
            <label class="border_right label" style="display: inline-block;cursor: pointer;width: auto;">
              <input type="radio" id="pageableSTANDART" class="check_active" name="pageable"
                     style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.pageable=='STANDART'"
                     (change)="changeSetting('pageable', 'STANDART')">
              <label for="pageableSTANDART" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>
                </svg>
              </label>
              стандарт

            </label>
            <label class="label" style="display: inline-block;cursor: pointer;width: auto;">
              <input type="radio" id="pageableMODERN" class="check_active" name="pageable"
                     style="opacity: 0;width: 0;height: 0;display: block;" [checked]="config.pageable == 'MODERN'"
                     (change)="changeSetting('pageable', 'MODERN')">
              <label for="pageableMODERN" class="label_wrapper">
                <svg style="display: none;" width="11" height="14" viewBox="0 0 11 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 3.88889L3.85714 10L9 0" transform="translate(1 1)" stroke="#808080" stroke-width="2"/>
                </svg>
              </label>
              модерн
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
