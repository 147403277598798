
import { throwError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../../models/Category';


@Injectable({ providedIn: 'root' })
export class CategoryService {

  controller = '/category';

  constructor(private _httpClient: HttpClient) { }

  create(category: Category): Observable<Category> {
    return this._httpClient.post<Category>(this.controller + '/create', JSON.stringify(category)).pipe(catchError(err => throwError(err)));
  }

  findAlll(): Observable<Category[]> {
    return this._httpClient.get<Category[]>(this.controller + '/find-all').pipe(catchError(err => throwError(err)));
  }

  findAll(): Observable<Category[]> {
    return this._httpClient.get<Category[]>(this.controller + '/active/find-all').pipe(catchError(err => throwError(err)));
  }

  findAllWith(): Observable<Category[]> {
    return this._httpClient.get<Category[]>(this.controller + '/find-all-with-svg').pipe(catchError(err => throwError(err)));
  }

  findOne(id: number): Observable<Category> {
    return this._httpClient.get<Category>(this.controller + '/find-one/' + id).pipe(catchError(error => throwError(error)));
  }

  changeValue(listCategory: Category[]): Observable<Category[]> {
    return this._httpClient.post<Category[]>(this.controller + '/change-value', JSON.stringify(listCategory)).pipe(catchError(error => throwError(error)));
  }

  changeAvailable(category: Category): Observable<Category> {
    return this._httpClient.post<Category>(this.controller + '/change-available', JSON.stringify(category)).pipe(catchError(error => throwError(error)));
  }
}
