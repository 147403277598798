import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { ClientEventOneBaseDataDto } from '../../../../../shared/models/dto/ClientEventOneBaseDataDto';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { Page } from '../../../../../shared/models/Page';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { Bin } from '../../../../../shared/models/Bin';
import { PdfService } from '../../../../../shared/service/backend/pdf.service';
import { Ticket } from '../../../../../shared/models/Ticket';

@Component({
  selector: 'app-event-invitation-list',
  templateUrl: './event-invitation-list.component.html',
  styleUrls: ['./event-invitation-list.component.css']
})
export class EventInvitationListComponent implements OnInit {
  currentEvent: ClientEventOneBaseDataDto = new ClientEventOneBaseDataDto();
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();
  lang: string;
  bins: Page<any>;
  eventId: string;
  page = 0;
  size = 10;
  invites: Bin[] = [];
  deletionConfirmModal?: NzModalRef;
  tickets: Ticket[];
  public isPrintModalVisible: boolean = false;
  public currentOrderId: string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _load: MainLoaderService,
    private _userDetailsService: UserDetailsService,
    private _translateService: TranslateService,
    private bookingService: BookingService,
    private pdfService: PdfService,
  ) { }

  ngOnInit() {
    this.user = this._userDetailsService.getUser();
    this._userDetailsService.user$.subscribe(next => {
      this.user = next;
    }, error => {
      console.error(error);
    });

    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(next => {
      this.lang = next.lang;
    });

    this.activatedRoute.params.subscribe(params => {
      this.eventId = params.id;
      this.load();
    });
  }

  load() {
    this.bookingService.getInvitesByEvent(this.eventId).pipe(take(1))
      .subscribe(invites => {
        this.invites = invites;
        this._load.endLoader();
      });
  }

  openPage(i) {
    this.page = i;
    this.load();
  }

  public onOpenPrintDialog(orderId: string): void {
    this.bookingService.findByBinId(orderId).subscribe(bin => {
      this.tickets = bin?.tickets ?? [];
      this.currentOrderId = orderId;
      this.isPrintModalVisible = true;
    });
  }
}
