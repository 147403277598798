<div class="container">
  <div class="white_container">
    <div class="filter_container_name">
      <p>{{ user.firstName }} {{ user.lastName }}</p>
    </div>
    <div class="divForm filter_container_form">
      <form #mainFilterForm>
        <div class="container_form_filter">
          <label class="label_search">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" class="iconSearch">
              <path
                d="M18.7123 17.3065L14.0265 12.6013C15.1885 11.2636 15.8432 9.58311 15.8824 7.83741C15.9217 6.09171 15.3431 4.38531 14.2423 3.00019C13.1416 1.61508 11.5845 0.634136 9.82838 0.219461C8.07226 -0.195214 6.22223 -0.0188042 4.58397 0.719541C2.94571 1.45789 1.61728 2.71398 0.818194 4.28025C0.0191041 5.84653 -0.202822 7.62925 0.189088 9.33385C0.580998 11.0384 1.56329 12.5629 2.97364 13.6553C4.384 14.7476 6.13801 15.3426 7.94584 15.3418C9.57558 15.3461 11.1663 14.8606 12.4977 13.9528L17.218 18.6913C17.3142 18.7893 17.43 18.8673 17.5585 18.9203C17.687 18.9734 17.8254 19.0005 17.9652 19C18.1681 18.9997 18.3666 18.9419 18.5358 18.8337C18.7051 18.7255 18.8377 18.5717 18.9173 18.3913C18.9968 18.211 19.0198 18.012 18.9834 17.8192C18.9469 17.6263 18.8527 17.4481 18.7123 17.3065ZM7.94584 1.99386C9.10749 1.99386 10.2431 2.32657 11.2089 2.94991C12.1748 3.57325 12.9276 4.45924 13.3722 5.49582C13.8167 6.53241 13.933 7.67303 13.7064 8.77347C13.4798 9.8739 12.9204 10.8847 12.099 11.6781C11.2776 12.4714 10.231 13.0117 9.09168 13.2306C7.95236 13.4495 6.77141 13.3372 5.69819 12.9078C4.62497 12.4784 3.70767 11.7513 3.06229 10.8184C2.41691 9.88552 2.07245 8.78873 2.07245 7.66674C2.07359 6.16254 2.69276 4.72026 3.79399 3.65663C4.89521 2.59299 6.38847 1.99496 7.94584 1.99386Z"
                fill="#C4C4C4"
              />
            </svg>
            <input (input)="onSearchInput()" type="text" class="search" name="researcher" />
          </label>
          <label class="label_city">
            <select class="city" (change)="changeFilterCity($event.target.value)" name="cityId">
              <option value="null">{{ 'return.city' | translate }}</option>
              <option *ngFor="let one of allCities; trackBy: trackById" value="{{ one?.id }}">
                <span *ngIf="lang == 'ua'">{{ one.dictionary.valueUA }}</span>
                <span *ngIf="lang == 'ru'">{{ one.dictionary.valueRU }}</span>
                <span *ngIf="lang == 'en'">{{ one.dictionary.valueEN }}</span>
              </option>
            </select>
          </label>
          <label class="label_category">
            <select name="null" class="category" name="categoryId" (change)="filter()">
              <option value="null">{{ 'return.category' | translate }}</option>
              <option *ngFor="let one of categories; trackBy: trackById" value="{{ one?.id }}">
                <span *ngIf="lang == 'ua'">{{ one.name.valueUA }}</span>
                <span *ngIf="lang == 'ru'">{{ one.name.valueRU }}</span>
                <span *ngIf="lang == 'en'">{{ one.name.valueEN }}</span>
              </option>
            </select>
          </label>
          <label class="label_ploschadka">
            <select name="ploschadka" class="ploschadka" name="platformHallId" (change)="filter()">
              <option value="null">{{ 'return.ploschadka' | translate }}</option>
              <option *ngFor="let one of platformHalls; trackBy: trackById" value="{{ one?.id }}">
                <span *ngIf="lang == 'ua'">{{ one.name.valueUA }}</span>
                <span *ngIf="lang == 'ru'">{{ one.name.valueRU }}</span>
                <span *ngIf="lang == 'en'">{{ one.name.valueEN }}</span>
              </option>
            </select>
          </label>
        </div>
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          (click)="clearForm()"
          class="iconClear clearForm"
        >
          <path d="M15 1.5L13.5 0L7.5 6L1.5 0L0 1.5L6 7.5L0 13.5L1.5 15L7.5 9L13.5 15L15 13.5L9 7.5L15 1.5Z" fill="#C4C4C4" />
        </svg>
      </form>
    </div>
  </div>

  <div class="container_index">
    <div class="index_container">
      <div class="index_container_declaration" id="title">
        <h2 class="main-h2">{{ 'platforms.platform' | translate }}</h2>
      </div>
      <div class="platform_event" *ngIf="platforms">
        <app-platform-events-one *ngFor="let one of platforms.content; trackBy: trackById" [one]="one"></app-platform-events-one>
      </div>
    </div>
    <div *ngIf="platforms" class="container_pageable">
      <app-pageable [page]="platforms" (countOnPage)="changeCount($event)" (openPage)="open($event)" [range]="4"></app-pageable>
    </div>
  </div>
</div>
