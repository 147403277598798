<div class="container" *ngIf="bin||commission">
  <div class="container-inner">
    <div *ngIf="bin" class="bin-info"></div>
    <div *ngIf="commission" class="bin-collected">
      <table>
        <tr>
          <td>комісія броні</td>
          <td>{{commission.bookedCommission * 100}} %</td>
          <td>{{commission.bookedCommissionPrice}} грн</td>
        </tr>
        <tr>
          <td>комісія сервісу</td>
          <td>{{commission.commission}} %</td>
          <td>{{commission.commissionPrice}} грн</td>
        </tr>
        <tr>
          <td>сума номіналу</td>
          <td colspan="2">{{commission.priceWithOutCommission}} грн</td>
        </tr>
        <tr>
          <td>сума з урахуванням комісій</td>
          <td colspan="2">{{commission.priceWithOutCommission}} грн</td>
        </tr>
        <tr>
          <td>внесена сумма</td>
          <td colspan="2">
            <label>
              <input type="number" (input)="collectedPrice()" [value]="0">
            </label>
          </td>
        </tr>
        <tr>
          <td>решта</td>
          <td colspan="2">{{outPrice}}</td>
        </tr>
        <tr>
          <td colspan="3">
            <div *ngIf="status!=null&&status">замовлення оформлено</div>
            <div *ngIf="status!=null&&!status">сталась помилка при завершенні замовлення</div>
            <button (click)="send()" *ngIf="!status">кошти отримано</button>
            <button (click)="close()" *ngIf="status">закрити</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
