import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { VendorOrderBuyPrintOptions, SellKGReportOptions } from '../../../shared/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  controller = '/excel-report-builder';

  constructor(private _httpClient: HttpClient) {}

  loadCGReport(options: SellKGReportOptions): Observable<any> {
    let params = new HttpParams();
    params = params.set('cashierId', String(options.cashierId));
    params = params.set('dateFrom', String(options.dateFrom));
    params = params.set('dateTo', String(options.dateTo));
    params = params.set('isVendor', String(options.isVendor));
    params = params.set('isReverted', String(options.isReverted));
    params = params.set('isRevertCounted', String(options.isRevertCounted));
    params = params.set('numberInvoice', String(''));

    if (options.hasOwnProperty('ownEventsOnly')) {
      params = params.set('ownEventsOnly', String(options.ownEventsOnly));
    }

    if (options.hasOwnProperty('typeOfPayment')) {
      params = params.set('typeOfPayment', String(options.typeOfPayment));
    }

    return this._httpClient.get(this.controller + '/cashier-event-sell-ticket-report', { params, responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = `кг_13_${options.dateFrom}_${options.dateFrom}.xls`;
        a.click();
      }),
      catchError(err => throwError(err)),
    );
  }
  newLoadCGReport(options: SellKGReportOptions): Observable<any> {
    let params = new HttpParams();
    params = params.set('cashierId', String(options.cashierId));
    params = params.set('dateFrom', String(options.dateFrom.replace('T', ' ') + ':00'));
    params = params.set('dateTo', String(options.dateTo.replace('T', ' ') + ':00'));
    params = params.set('isVendor', String(options.isVendor));
    params = params.set('isReverted', String(options.isReverted));
    params = params.set('isRevertCounted', String(options.isRevertCounted));
    params = params.set('numberInvoice', String(''));

    if (options.hasOwnProperty('ownEventsOnly')) {
      params = params.set('ownEventsOnly', String(options.ownEventsOnly));
    }

    if (options.hasOwnProperty('typeOfPayment')) {
      params = params.set('typeOfPayment', String(options.typeOfPayment));
    }

    return this._httpClient
      .get(
        `https://z${
          environment.production ? '' : '.test'
        }.soldout.com.ua/sold-out-statistic/excel-report-builder/cashier-event-sell-ticket-report`,
        {
          params,
          responseType: 'blob',
        },
      )
      .pipe(
        map(value => {
          window.URL = window.URL || (<any>window).webkitURL;
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(value);
          a.download = `NEW кг_13_${options.dateFrom}_${options.dateFrom}.xls`;
          a.click();
        }),
        catchError(err => throwError(err)),
      );
  }

  loadKG7report(options: VendorOrderBuyPrintOptions): Observable<void> {
    let params = new HttpParams();
    params = params.set('eventId', String(options.eventId));
    params = params.set('binIdList', String(options.orderId));
    params = params.set('cashier', String(options.cashier));
    params = params.set('reason', String(options.reason));
    params = params.set('numberInvoice', String(options.numberInvoice));
    params = params.set('vendor', String(options.vendor));
    params = params.set('revert', String(options.revert));

    return this._httpClient.get('/excel-kg/create-kg-seven-report-excel', { params, responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = `Накладна_на_видачу_${options.orderId}.xls`;
        a.click();
      }),
      catchError(err => throwError(err)),
    );
  }
  newLoadKG7report(options: VendorOrderBuyPrintOptions): Observable<void> {
    let params = new HttpParams();
    params = params.set('eventId', String(options.eventId));
    params = params.set('binIdList', String(options.orderId));
    params = params.set('cashier', String(options.cashier));
    params = params.set('reason', String(options.reason));
    params = params.set('numberInvoice', String(options.numberInvoice));
    params = params.set('vendor', String(options.vendor));
    params = params.set('revert', String(options.revert));

    return this._httpClient
      .get(`https://z${environment.production ? '' : '.test'}.soldout.com.ua/sold-out-statistic/excel-kg/create-kg-seven-report-excel`, {
        params,
        responseType: 'blob',
      })
      .pipe(
        map(value => {
          window.URL = window.URL || (<any>window).webkitURL;
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(value);
          a.download = `NEW Накладна_на_видачу_${options.orderId}.xls`;
          a.click();
        }),
        catchError(err => throwError(err)),
      );
  }

  // TODO: define returned type
  sellDataReport(options: SellKGReportOptions): Observable<any> {
    let params = new HttpParams();
    params = params.set('cashierId', String(options.cashierId));
    params = params.set('dateFrom', String(options.dateFrom));
    params = params.set('dateTo', String(options.dateTo));
    params = params.set('isVendor', String(options.isVendor));
    params = params.set('isReverted', String(options.isReverted));
    params = params.set('isRevertCounted', String(options.isRevertCounted));

    if (options.hasOwnProperty('ownEventsOnly')) {
      params = params.set('ownEventsOnly', String(options.ownEventsOnly));
    }

    if (options.hasOwnProperty('typeOfPayment')) {
      params = params.set('typeOfPayment', String(options.typeOfPayment));
    }

    return this._httpClient
      .get<any>(`${this.controller}/cashier-event-sell-ticket-report-detailed-statistic`, { params })
      .pipe(catchError(err => throwError(err)));
  }

  sellKGReport(options: SellKGReportOptions): Observable<any> {
    let params = new HttpParams();
    params = params.set('cashierId', String(options.cashierId));
    params = params.set('dateFrom', String(options.dateFrom));
    params = params.set('dateTo', String(options.dateTo));
    params = params.set('isVendor', String(options.isVendor));
    params = params.set('isReverted', String(options.isReverted));
    params = params.set('isRevertCounted', String(options.isRevertCounted));

    if (options.hasOwnProperty('ownEventsOnly')) {
      params = params.set('ownEventsOnly', String(options.ownEventsOnly));
    }

    if (options.hasOwnProperty('typeOfPayment')) {
      params = params.set('typeOfPayment', String(options.typeOfPayment));
    }

    return this._httpClient
      .get<any>(`${this.controller}/cashier-event-sell-ticket-report-general-statistic`, { params })
      .pipe(catchError(err => throwError(err)));
  }

  createKgNineReportExcel(eventId: number, dateFrom: string, dateTo: string, number: string): Observable<Blob> {
    let body = `?eventId=${eventId}&dateFrom=${dateFrom}&dateTo=${dateTo}&number=${number}`;
    return this._httpClient.get('/excel-kg/create-kg-nine-report-excel' + body, { responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = 'Звіти_Кг_9' + '.xls';
        a.click();
        return value;
      }),
      catchError(err => throwError(err)),
    );
  }
  newCreateKgNineReportExcel(eventId: number, dateFrom: string, dateTo: string, number: string): Observable<Blob> {
    let body = `?eventId=${eventId}&dateFrom=${dateFrom ? dateFrom.replace('T', ' ') + ':00' : ''}&dateTo=${
      dateTo ? dateTo.replace('T', ' ') + ':00' : ''
    }&number=${number}`;
    console.log(dateFrom, dateTo);
    return this._httpClient
      .get(
        `https://z${environment.production ? '' : '.test'}.soldout.com.ua/sold-out-statistic/excel-kg/create-kg-nine-report-excel` + body,
        { responseType: 'blob' },
      )
      .pipe(
        map(value => {
          window.URL = window.URL || (<any>window).webkitURL;
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(value);
          a.download = 'NEW Звіти_Кг_9' + '.xls';
          a.click();
          return value;
        }),
        catchError(err => throwError(err)),
      );
  }

  createKgElevenReportExcel(eventId: number, boxOfficeId: number, dateFrom: string, dateTo: string, number: string) {
    let body = `?eventId=${eventId}&boxOfficeId=${boxOfficeId}&dateFrom=${dateFrom}&dateTo=${dateTo}&number=${number}`;
    return this._httpClient.get('/excel-kg/create-kg-eleven-report-excel' + body, { responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = 'Звіти_Кг_11' + '.xls';
        a.click();
        return value;
      }),
      catchError(err => throwError(err)),
    );
  }
  newCreateKgElevenReportExcel(eventId: number, boxOfficeId: number, dateFrom: string, dateTo: string, number: string) {
    let body = `?eventId=${eventId}&boxOfficeId=${boxOfficeId}&dateFrom=${dateFrom ? dateFrom.replace('T', ' ') + ':00' : ''}&dateTo=${
      dateTo ? dateTo.replace('T', ' ') + ':00' : ''
    }&number=${number}`;
    return this._httpClient
      .get(
        `https://z${environment.production ? '' : '.test'}.soldout.com.ua/sold-out-statistic/excel-kg/create-kg-eleven-report-excel` + body,
        { responseType: 'blob' },
      )
      .pipe(
        map(value => {
          window.URL = window.URL || (<any>window).webkitURL;
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(value);
          a.download = 'NEW Звіти_Кг_11' + '.xls';
          a.click();
          return value;
        }),
        catchError(err => throwError(err)),
      );
  }

  createKgTenReportExcel(eventId: number, dateFrom: string, dateTo: string, number: string) {
    let body = `?eventId=${eventId}&dateFrom=${dateFrom}&dateTo=${dateTo}&number=${number}`;
    return this._httpClient.get('/excel-kg/create-kg-ten-report-excel' + body, { responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = 'Звіти_Кг_10' + '.xls';
        a.click();
        return value;
      }),
      catchError(err => throwError(err)),
    );
  }
  newCreateKgTenReportExcel(eventId: number, dateFrom: string, dateTo: string, number: string) {
    let body = `?eventId=${eventId}&dateFrom=${dateFrom ? dateFrom.replace('T', ' ') + ':00' : ''}&dateTo=${
      dateTo ? dateTo.replace('T', ' ') + ':00' : ''
    }&number=${number}`;
    return this._httpClient
      .get(
        `https://z${environment.production ? '' : '.test'}.soldout.com.ua/sold-out-statistic/excel-kg/create-kg-ten-report-excel` + body,
        { responseType: 'blob' },
      )
      .pipe(
        map(value => {
          window.URL = window.URL || (<any>window).webkitURL;
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(value);
          a.download = 'NEW Звіти_Кг_10' + '.xls';
          a.click();
          return value;
        }),
        catchError(err => throwError(err)),
      );
  }

  createKgTenReportExcelD(eventId: number, dateFrom: string, dateTo: string, number: string) {
    let body = `?eventId=${eventId}&dateFrom=${dateFrom}&dateTo=${dateTo}&number=${number}`;
    return this._httpClient.get('/excel-kg/create-kg-ten-addition-report-excel' + body, { responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = 'Звіти_Кг_10_додаток' + '.xls';
        a.click();
        return value;
      }),
      catchError(err => throwError(err)),
    );
  }
  newCreateKgTenReportExcelD(eventId: number, dateFrom: string, dateTo: string, number: string) {
    let body = `?eventId=${eventId}&dateFrom=${dateFrom ? dateFrom.replace('T', ' ') + ':00' : ''}&dateTo=${
      dateTo ? dateTo.replace('T', ' ') + ':00' : ''
    }&number=${number}`;
    return this._httpClient
      .get(
        `https://z${environment.production ? '' : '.test'}.soldout.com.ua/sold-out-statistic/excel-kg/create-kg-ten-addition-report-excel` +
          body,
        { responseType: 'blob' },
      )
      .pipe(
        map(value => {
          window.URL = window.URL || (<any>window).webkitURL;
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(value);
          a.download = 'NEW Звіти_Кг_10_додаток' + '.xls';
          a.click();
          return value;
        }),
        catchError(err => throwError(err)),
      );
  }

  createKgSevenReportExcel(eventId: number, netBoxId: string, numberInvoice: number) {
    let body = `?eventId=${eventId}&netBoxId=${netBoxId}&numberInvoice=${numberInvoice}`;
    return this._httpClient.get('/excel-report-builder/find-one-event-by-netbox-report' + body, { responseType: 'blob' }).pipe(
      map(value => {
        window.URL = window.URL || (<any>window).webkitURL;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(value);
        a.download = 'Звіти_Кг_7' + '.xls';
        a.click();
        return value;
      }),
      catchError(err => throwError(err)),
    );
  }
}
