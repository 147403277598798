import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { User } from '../../models/User';
import { environment } from 'src/environments/environment';

export interface IAuthTokenData {
  access_token: string;
  expires_in: number;
  jti: string;
  refresh_token: string;
  scope: 'user-client';
  token_type: 'bearer';
}

@Injectable({ providedIn: 'root' })
export class SignInService {
  isGoogleInit = false;

  constructor(private http: HttpClient) {}

  initGoogleLogin(
    googleBtn: HTMLElement,
    afterLoginCallbacks: { success: (tokenData: IAuthTokenData) => void; error: (error: any) => void },
  ): void {
    const showLogin = () => {
      google.accounts.id.prompt();
      google.accounts.id.renderButton(googleBtn, { type: 'standard' });
    };

    if (!this.isGoogleInit) {
      this.loadGoogleScript();

      window.onGoogleLibraryLoad = () => {
        google.accounts.id.initialize({
          client_id: environment.googleClientId,
          callback: (res: google.accounts.id.CredentialResponse) => {
            this.loginViaGoogle(res.credential, afterLoginCallbacks);
          },
        });

        this.isGoogleInit = true;
        showLogin();
      };
    } else {
      showLogin();
    }
  }

  loadGoogleScript(): void {
    const script = document.createElement('script');
    const scriptConfig: { [key: string]: string } = {
      src: 'https://accounts.google.com/gsi/client',
      async: 'true',
      defer: 'true',
    };

    for (const key in scriptConfig) {
      script.setAttribute(key, scriptConfig[key]);
    }

    document.body.appendChild(script);
  }

  loginViaGoogle(
    googleToken: string,
    afterLoginCallbacks: { success: (tokenData: IAuthTokenData) => void; error: (error: any) => void },
  ): void {
    console.log(googleToken);
    this.http
      .post<{ token: IAuthTokenData }>(
        `https://z${
          environment.production ? '' : '.test'
        }.soldout.com.ua/sold-out-statistic/me/google/token?token=${googleToken}&clientId=CASHIER`,
        {},
      )
      .subscribe(
        res => afterLoginCallbacks.success(res.token),
        error => afterLoginCallbacks.error(error),
      );
  }

  sendCredentials(model): Observable<IAuthTokenData> {
    return this.http
      .post<IAuthTokenData>('/oauth/token', null, {
        params: new HttpParams().set('username', model.username).set('password', model.password).set('grant_type', 'password'),
      })
      .pipe(catchError(error => throwError(error)));
  }

  getUser(): Observable<User> {
    return this.http.get<User>('/user/get').pipe(catchError(error => throwError(error)));
  }

  resetPassword(mail: string): Observable<any> {
    return this.http
      .post<any>('/user/get-mail/reset', null, {
        params: new HttpParams().set('mail', mail),
      })
      .pipe(catchError(error => throwError(error)));
  }
}
