import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { BinHistoryBoxOfficeDto } from '../../../../../shared/models/dto/BinHistoryBoxOfficeDto';
import { Event } from '../../../../../shared/models/Event';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { PlatformHall } from '../../../../../shared/models/PlatformHall';
import { HallService } from '../../../../../shared/service/backend/hall.service';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { ExcelBuilderService } from '../../../../../shared/service/backend/excel-builder.service';
import { Ticket } from '../../../../../shared/models/Ticket';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { UserService } from '../../../../../shared/service/backend/user.service';
import { User } from '../../../../../shared/models/User';
import { PdfService } from '../../../../../shared/service/backend/pdf.service';
import { take } from 'rxjs/operators';
import { BoxOfficeService } from '../../../../../shared/service/backend/box-office.service';
import { SaleStatistics } from '../../../../../shared/models/Bin';
import { downloadExcel } from 'src/shared/service/utils/download-exel';

class BinDate {
  date: string;
  bins: BinHistoryBoxOfficeDto[] = [];
}

class Pageable {
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: number;
  first: number;
  numberOfElements: number;
}

export class DataForPopUp {
  event: Event;
  tickets: Ticket[] = [];
}

@Component({
  selector: 'app-history-container',
  templateUrl: './history-container.component.html',
  styleUrls: ['./history-container.component.css'],
})
export class HistoryContainerComponent implements OnInit {
  bins: BinDate[] = [];
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();
  popUpMain: DataForPopUp = new DataForPopUp();

  lang: string;

  public filterForm: FormGroup;
  public ticketsToPrint: number[] = [];
  public satesStatistics: SaleStatistics = {
    quantity: 0,
    amount: 0,
  };

  public organizers$: Observable<User[]> = this.userService
    .findAllBySimpleFilter(null, null, null, null, 'SPOPNSOR')
    .pipe(map(data => data.content));

  pageable: Pageable = new Pageable();

  page: number = 0;
  count: number = 20;

  platforms: PlatformHall[] = [];

  constructor(
    private fb: FormBuilder,
    private boxOfficeService: BoxOfficeService,
    private _bookingService: BookingService,
    private _userDetailsService: UserDetailsService,
    private userService: UserService,
    private _translateService: TranslateService,
    private _hallService: HallService,
    private _mainLoaderService: MainLoaderService,
    private _excelBuilderService: ExcelBuilderService,
    private pdfService: PdfService,
  ) {
    this.user = this._userDetailsService.getUser();
    this._userDetailsService.user$.subscribe(
      next => {
        this.user = next;
      },
      error => {
        console.error(error);
      },
    );
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(
      next => {
        this.lang = next.lang;
      },
      error => {
        console.error(error);
      },
    );
    this._bookingService.filterBinBoughtTicket(this.page, this.count, 'BOUGHT', 'DESC').subscribe(
      next => {
        this.groupEventByDate(next);
        this.pageable = next;
      },
      error => {
        console.error(error);
      },
    );
    this._hallService.findAllPlatformHallsAvailable().subscribe(
      next => {
        this.platforms = next;
      },
      error => {
        console.error(error);
      },
    );
  }

  public get ownEvents() {
    return this.filterForm.get('ownEventsOnly').value;
  }

  private get dateFrom() {
    if (this.filterForm.get('dateFrom').value) {
      return this.filterForm.get('dateFrom').value;
    } else {
      return this.formatDate(new Date());
    }
  }

  private get dateTo() {
    if (this.filterForm.get('dateTo').value) {
      return this.filterForm.get('dateTo').value;
    } else {
      return this.formatDate(new Date());
    }
  }

  public get salesPeriod() {
    if (this.dateFrom !== this.dateTo) {
      return `${this.dateFrom} - ${this.dateTo}`;
    } else {
      return this.dateFrom;
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.gateSalesStatistics();
  }

  changePaymentType(ev: 'CASH' | 'CARD', el: BinHistoryBoxOfficeDto) {
    this.boxOfficeService.changeHistoryPaymentType(el.id, ev).subscribe();
  }

  private initForm(): void {
    const dateFrom = new Date(Date.now() - 24 * 3600000);
    const dateTo = new Date();

    this.filterForm = this.fb.group({
      // dateFrom: `${dateFrom.getFullYear()}-${('0' + (dateFrom.getMonth() + 1)).slice(-2)}-${('0' + dateFrom.getDate()).slice(-2)}`,
      dateFrom: '',
      // dateTo: `${dateTo.getFullYear()}-${('0' + (dateTo.getMonth() + 1)).slice(-2)}-${('0' + dateTo.getDate()).slice(-2)}`,
      dateTo: '',
      // dateFrom: null,
      // dateTo: null,
      type_of_payment: 0,
      event_id: 0,
      platform_hall: 0,
      ownEventsOnly: 0,
      organizators: null,
    });
  }

  private formatDate(date: Date): string {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  private gateSalesStatistics(): void {
    const status_bin = 'BOUGHT';
    this.boxOfficeService
      .getSalesStatistics(
        status_bin,
        this.filterForm.value.dateFrom,
        this.filterForm.value.dateTo,
        this.filterForm.value.type_of_payment,
        this.filterForm.value.platform_hall,
        this.filterForm.value.ownEventsOnly,
      )
      .subscribe(
        statistics => (this.satesStatistics = statistics),
        error => {
          console.error(error);
        },
      );
  }

  changeCount(e) {
    this.count = e;
    this.page = 0;
    this.loadBins();
  }

  filter() {
    this.page = 0;
    this.loadBins();
    this.gateSalesStatistics();
  }

  loadBins() {
    this._bookingService.filterBinBoughtTicket(this.page, this.count, 'BOUGHT', 'DESC', this.filterForm.value).subscribe(
      next => {
        this.groupEventByDate(next);
        this.pageable = next;
      },
      error => {
        console.error(error);
      },
    );
  }

  resetFilter() {
    this.page = 0;
    this.filterForm.reset();
    this.loadBins();
  }

  groupEventByDate(arr) {
    this._mainLoaderService.startLoader();
    this.bins = [];
    let tempArr: BinDate = new BinDate();
    arr.content.forEach((value, index) => {
      if (index == 0) {
        tempArr.date = value.created;
        tempArr.bins.push(value);
        if (arr.content.length == 1) {
          this.bins.push(tempArr);
        }
      } else {
        if (new Date(value.created).toLocaleDateString() == new Date(tempArr.date).toLocaleDateString()) {
          tempArr.bins.push(value);
          if (index == arr.content.length - 1) {
            this.bins.push(tempArr);
          }
        } else {
          this.bins.push(tempArr);
          tempArr = new BinDate();
          tempArr.date = value.created;
          tempArr.bins.push(value);
          if (index == arr.content.length - 1) {
            this.bins.push(tempArr);
          }
        }
      }
    });
    this._mainLoaderService.endLoader();
  }

  open(i) {
    this.page = i;
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.loadBins();
  }

  nextPage() {
    this.page++;
    this.loadBins();
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  prevPage() {
    this.page--;
    this.loadBins();
    document.getElementById('title').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  downloadExcel() {
    this._excelBuilderService.createBinHistoryBoxOfficeExcel(this.page, this.count, 'BOUGHT', 'DESC', this.filterForm.value).subscribe(
      next => {
        downloadExcel(next, 'Історія_Оплачених_Квитків_' + new Date().toISOString().slice(0, 10));
      },
      error => {
        console.error(error);
      },
    );
  }

  openCloseDetails(id: string, ids: string, one) {
    document.getElementById(id).classList.toggle('display-flex');
    document.getElementById(ids).classList.toggle('tranforms');
  }

  openClosePopUp(id: string, order?: string, one?: Event) {
    this.ticketsToPrint = [];
    if (order)
      this._bookingService.findBinByOrderId(order).subscribe(
        next => {
          this.popUpMain.event = one;
          this.popUpMain.tickets = next;
        },
        error => {
          console.error(error);
          return;
        },
      );
    else this.popUpMain = new DataForPopUp();
    document.getElementById(id).classList.toggle('display-flex');
  }

  public setAllToPrint(loadToPrint): void {
    this.ticketsToPrint = [];
    this.ticketsToPrint = loadToPrint.target.checked ? [...this.popUpMain.tickets].map(ticket => ticket.id) : [];
  }

  public setToPrint(loadToPrint, ticketId: number): void {
    if (loadToPrint.target.checked) {
      this.ticketsToPrint.push(ticketId);
    } else {
      this.ticketsToPrint.forEach((item, index) => {
        if (item === ticketId) this.ticketsToPrint.splice(index, 1);
      });
    }
  }

  public printTickets(typePrint: 'a4' | 'thermo' | 'regular'): void {
    if (typePrint == 'thermo') {
      this.pdfService
        .generatePdfTerm(this.ticketsToPrint, false)
        .pipe(take(1))
        .subscribe(
          next => {
            let mediaType = 'application/pdf';
            let blob = new Blob([next], { type: mediaType });
            this.printPdf(URL.createObjectURL(blob));
          },
          error => catchError(error),
        );
    } else if (typePrint == 'regular') {
      this.pdfService
        .generateBigPdfTerm(this.ticketsToPrint, false)
        .pipe(take(1))
        .subscribe(
          next => {
            let mediaType = 'application/pdf';
            let blob = new Blob([next], { type: mediaType });

            this.printPdf(URL.createObjectURL(blob));
          },
          error => catchError(error),
        );
    } else {
      this.pdfService
        .generatePdfA4(this.ticketsToPrint, false)
        .pipe(take(1))
        .subscribe(
          next => {
            let mediaType = 'application/pdf';
            let blob = new Blob([next], { type: mediaType });

            this.printPdf(URL.createObjectURL(blob));
          },
          error => catchError(error),
        );
    }
  }

  private printPdf(url: string): void {
    let iframe;
    iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = url;
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }
}
