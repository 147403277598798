<div class="{{ mainSettingConfig.color }}_container">
  <div class="left" (mouseover)="openHover('dark-cont_' + eventData.id)" (mouseout)="closeHover('dark-cont_' + eventData.id)">
    <div class="container-dark pointer" id="dark-cont_{{ eventData.id }}" (click)="openMoreInfo()">
      <div class="dark">
        <svg width="68" height="39" viewBox="0 0 68 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M66.5671 20.6313L67.2602 19.5L66.5671 18.3681C66.4509 18.1792 63.6626 13.6786 58.2456 9.16854C51.0409 3.17067 42.553 0 33.6992 0C24.8476 0 16.3389 3.16898 9.09297 9.16403C3.64497 13.6719 0.825095 18.169 0.707789 18.3585L0 19.5L0.707789 20.6415C0.825095 20.831 3.64497 25.3281 9.09297 29.836C16.3389 35.831 24.8476 39 33.6992 39C42.553 39 51.0409 35.8293 58.2456 29.8315C63.6626 25.3214 66.4509 20.8208 66.5671 20.6313ZM55.3592 26.5976C48.878 31.9531 41.5903 34.6681 33.6992 34.6681C25.8052 34.6681 18.4939 31.9514 11.9687 26.5926C8.59385 23.8212 6.3058 21.0148 5.18519 19.5C6.30637 17.984 8.59442 15.1783 11.9687 12.4069C18.4939 7.04856 25.8047 4.33133 33.6992 4.33133C41.5903 4.33133 48.8775 7.04687 55.3592 12.4018C58.7188 15.1777 60.9871 17.988 62.0925 19.5C60.986 21.0126 58.7183 23.8229 55.3592 26.5976Z"
            fill="white"
            fill-opacity="0.6"
          />
          <path
            d="M33.6347 6.48682C26.4592 6.48682 20.6215 12.3245 20.6215 19.5C20.6215 26.6754 26.4592 32.5131 33.6347 32.5131C40.8101 32.5131 46.6478 26.6754 46.6478 19.5C46.6478 12.3245 40.8101 6.48682 33.6347 6.48682ZM33.6347 28.1818C28.8477 28.1818 24.9528 24.287 24.9528 19.5C24.9528 14.713 28.8477 10.8181 33.6347 10.8181C38.4217 10.8181 42.3165 14.713 42.3165 19.5C42.3165 24.287 38.4217 28.1818 33.6347 28.1818Z"
            fill="white"
            fill-opacity="0.6"
          />
        </svg>
      </div>
    </div>
    <img [src]="eventData.mainImageSmall?.path | img" alt="" (click)="openMoreInfo()" />
    <div class="container_count">
      <p class="up">{{ 'events.event_small.available_seats' | translate }}</p>
      <p class="down">{{ eventData.freeSeatsNumber }}</p>
    </div>
  </div>
  <div *ngIf="eventData.isDiscountAvailable" class="discount-chip">Діє Знижка</div>
  <div class="right">
    <h2 routerLink="/buy/{{ eventData.id }}" valueByLang [dictionary]="eventData.name"></h2>
    <p class="category">
      <span *ngFor="let one of eventData.eventCategory" valueByLang [dictionary]="one.name"></span>
    </p>
    <p class="hall">
      <span valueByLang [dictionary]="eventData.platformHall.city.dictionary"></span>
      ,
      <span valueByLang [dictionary]="eventData.platformHall.name"></span>
    </p>
    <div class="date_container">
      <div class="date_container_left">
        <p class="date_container_left_up">
          {{ eventData.beginDate | localdate }}
          ({{ 'day.' + (eventData.beginDate | localDayOfTheWeek) + '' | translate }})
          <b>{{ eventData.beginDate | localtime }}</b>
        </p>
        <p class="date_container_left_down">
          {{ 'events.event_small.from' | translate }} {{ eventData.minPrice }} {{ 'events.event_small.to' | translate }}
          {{ eventData.maxPrice }}{{ 'money' | translate }}
        </p>
      </div>
      <div class="date_container_right" routerLink="buy/{{ eventData.id }}">
        {{ 'events.event_small.tickets' | translate }}
      </div>
      <div *ngIf="!eventData.abonementEvent" class="btn-item btn-more-actions" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
        <a class="more-actions-icon">
          <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li *ngIf="eventData.eventCategory && eventData.eventCategory[0].abonementCategory && !eventData.abonementEvent" nz-menu-item>
              <a (click)="useAbonement()">Використати абонемент</a>
            </li>
            <li nz-menu-item>
              <a routerLink="/order/{{ eventData.id }}" [queryParams]="{ vendor: 'none' }">Замовлення контрагента</a>
            </li>
            <li nz-menu-item>
              <a routerLink="/block/{{ eventData.id }}/list">Блокування</a>
            </li>
            <li nz-menu-item>
              <a routerLink="/invitations/{{ eventData.id }}/list">Запрошення</a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</div>
