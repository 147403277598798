<div class="container">
  <div class="back-item">
    <a [routerLink]="['/accountant/events-statistics']">
      {{ 'accountant.event_statistics.general.back' | translate }}
    </a>
  </div>
  <app-event-record [eventItem]="eventDetails"></app-event-record>
  <ul class="page-nav">
    <li class="page-nav-item">
      <a [routerLink]="['general', eventId]" class="nav-link" routerLinkActive="page-nav-item-active">
        {{ 'accountant.event_statistics.nav.general' | translate }}
      </a>
    </li>
    <li class="page-nav-item">
      <a [routerLink]="['by-sellers', eventId]" class="nav-link" routerLinkActive="page-nav-item-active">
        {{ 'accountant.event_statistics.nav.by_seller' | translate }}
      </a>
    </li>
    <li class="page-nav-item">
      <a [routerLink]="['reports', eventId]" class="nav-link" routerLinkActive="page-nav-item-active">
        {{ 'accountant.event_statistics.nav.reports' | translate }}
      </a>
    </li>
  </ul>
  <div class="container_tabs_index">
    <router-outlet></router-outlet>
  </div>
</div>
