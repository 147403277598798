import {BoxOffice} from './BoxOffice';
import {Role} from './enums/enums';

export class User {
  id: number;
  lastModified: string;
  firstName: string;
  middleName: string;
  lastName: string;
  name: string;
  password: string;
  mail: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  image: ImageD;
  // imagePath: string;
  uuid: string;
  role: Role;
  registrationDate: string;
  boxOffice: BoxOffice;
  available: boolean;
  lock: boolean;

  // inspectors: Inspector[] = [];
}

export class ImageD {
  constructor() {
  }

  id: number;
  lastModified: string;
  path: string;
  title: string;
  alt: string;
}
