import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Hall } from '../../models/Hall';
import { PartHall } from '../../models/PartHall';
import { PlatformHall } from '../../models/PlatformHall';
import { IHall, IPartHall } from 'src/app/client/client/events/event-action/event-action.component';

@Injectable({ providedIn: 'root' })
export class HallService {
  private controller: string = '/hall/client';

  constructor(private _http: HttpClient) {}

  save(obj: Hall): Observable<any> {
    return this._http.post(this.controller + '/save', JSON.stringify(obj)).pipe(catchError(error => throwError(error)));
  }

  findAll(): Observable<Hall[]> {
    return this._http.get<Hall[]>(this.controller + '/find-all').pipe(catchError(error => throwError(error)));
  }

  findOne(id: number): Observable<Hall> {
    return this._http.get<Hall>(this.controller + '/find-one/' + id).pipe(catchError(error => throwError(error)));
  }

  delete(id: number): Observable<Boolean> {
    return this._http.delete<Boolean>(this.controller + '/delete/' + id).pipe(catchError(error => throwError(error)));
  }

  findAllGeneral(): Observable<Hall[]> {
    return this._http.get<Hall[]>(this.controller + '/find-all-general').pipe(catchError(err => throwError(err)));
  }

  getCities(country?: string): Observable<string[]> {
    return this._http
      .get<string[]>(`${this.controller}/cities${country ? '' : `-by-country?country=${country}`}`)
      .pipe(catchError(err => throwError(err)));
  }

  getCountries(): Observable<string[]> {
    return this._http.get<string[]>(`${this.controller}/countries`).pipe(catchError(err => throwError(err)));
  }

  findOneHallByEvent(id: number): Observable<IHall> {
    return this._http.get<IHall>(this.controller + '/find-one-by-event/' + id).pipe(catchError(err => throwError(err)));
  }

  findFirstPartHall(id: number): Observable<IPartHall> {
    return this._http.get<IPartHall>(this.controller + '/find-first-part-hall/' + id).pipe(catchError(err => throwError(err)));
  }

  findPartHallBuRoutingHall(hallId: number, routingName: string): Observable<PartHall> {
    return this._http
      .get<PartHall>(this.controller + '/find-part-hall-by-routing-hall/' + hallId + '/' + routingName)
      .pipe(catchError(err => throwError(err)));
  }

  findAllPlatformHallsByCity(id: number): Observable<PlatformHall[]> {
    return this._http
      .get<PlatformHall[]>(this.controller + `/find-all-platform-halls-by-city/${id}`)
      .pipe(catchError(err => throwError(err)));
  }

  findAllPlatformHallsAvailable(): Observable<PlatformHall[]> {
    return this._http.get<PlatformHall[]>('/hall/client/find-all-platform-hall-available').pipe(catchError(err => throwError(err)));
  }
}
