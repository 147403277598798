import { BinStatus } from './enums/enums';
import { User } from './User';
import { Event } from './Event';
import { Ticket } from './Ticket';
import { UserWithBoxOfficeDto } from './dto/UserWithBoxOfficeDto';

export class Bin {
  id: number;
  lastModified: string;
  created: string;
  timeUnlock?: string;
  binStatus: BinStatus;
  user: UserWithBoxOfficeDto | User;
  ticketList?: Ticket[] = [];
  event?: Event;
  price: number;
  order: string;
  sender: User;
  comment: string;
  typeOfPayment: 'CASH' | 'CARD' | 'NO_CASH';
  tickets?: Ticket[] = [];
  totalPrice?: number;
}

export interface SaleStatistics {
  quantity: number;
  amount: number;
}
