import { timer as observableTimer, Subscription } from 'rxjs';
import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[timeInput]'
})
export class TimerInputDirective implements OnInit {

  @Input() time: number;
  timer: Subscription;
  @Output() timeInput = new EventEmitter();

  constructor(private el: ElementRef, private renderer: Renderer2) {
    renderer.listen(this.el.nativeElement, 'input', (e) => {
    if (this.timer) {
        this.timer.unsubscribe();
        this.timer = null;
    }
    this.timer = observableTimer(this.time, 0).subscribe(next => {
        this.timeInput.emit(e);
        if (this.timer) {
            this.timer.unsubscribe();
            this.timer = null;
        }
    });
});
  }

  ngOnInit(): void {
  }
}
