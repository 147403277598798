import { Ticket } from './Ticket';
import { TicketGroupWithoutScheme } from './TicketGroupWithoutScheme';

export class ColorPrice {
  id: number;
  lastModified: string;
  color: string;
  price: number;
  ticketList: Ticket[] = [];
  ticketGroupWithoutScheme: TicketGroupWithoutScheme[] = [];
}

export class ColorPriceWrapper {
  id: number;
  ticketList: any[] = [];
  bonusGet: number;
  bonusSell: number;
  available: boolean;
  color: string;
  price: number;
}
