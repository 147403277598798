<div class="modal-wrapper">
  <select name="vendorSelector">
    <option [value]="null">Всі</option>
    <option [value]="true">Власні</option>
    <option [value]="false">Чужі</option>
  </select>
</div>

<ng-template #section>
  <h3>Чужі</h3>
  <input type="checkbox">
  <div class="type-wrapper">
    <input type="checkbox"
      name="horns">
    <label for="horns">Horns</label>
  </div>
  <div class="type-wrapper">
    <input type="checkbox"
      name="horns">
    <label for="horns">Horns</label>
  </div>
  <div class="type-wrapper">
    <input type="checkbox"
      name="horns">
    <label for="horns">Horns</label>
  </div>
</ng-template>
