import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { Url } from '../../../shared/config/url';
import { ErrorMethod } from '../../../shared/service/error/error.method';
import { MainLoaderService } from '../../../shared/service/rx/main-loader.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: Object,
    private _loadService: MainLoaderService,
    private _router: Router,
  ) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (req.url.includes('i18n')) {
      return next.handle(req);
    }
    let headers;
    if (!isPlatformBrowser(this.platformId)) {
      headers = new HttpHeaders();
    } else {
      headers = this.getHeaders(req);
    }
    if ((req.url.includes('http://') || req.url.includes('https://')) && !req.url.includes('sold-out-statistic')) {
      req = req;
    } else {
      req = req.clone({ headers, url: !req.url.includes('sold-out-statistic') ? Url.url + req.url : req.url });
    }
    return next.handle(req).pipe(
      catchError(err => {
        this._loadService.endLoader();
        new ErrorMethod(err).message('HttpInterceptor', Url.url + req.url);
        if (err.status === 401) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('user_details');
          localStorage.removeItem('user');
          localStorage.removeItem('ROLE');
          for (let key in localStorage) {
            if (key.includes('order')) {
              localStorage.removeItem(key);
            } else if (key.includes('time')) {
              localStorage.removeItem(key);
            }
          }
          this._router.navigateByUrl('/sign-in');
          return throwError(err);
        }
        if (err.status === 403) {
          return throwError(err);
        } else {
          return throwError(err);
        }
      }),
    );
  }

  getHeaders(req: HttpRequest<any>): HttpHeaders {
    let authKey = '';
    let headers = new HttpHeaders();
    let temp: HttpRequest<any>;
    if (!(<any>req.headers)) {
      temp = req.clone({ headers });
    } else {
      temp = req.clone();
    }
    if (temp.headers.keys().indexOf('multipart') != -1 || temp.headers.keys().indexOf('enctype') != -1) {
      headers = headers.append('enctype', 'form-data/multipart');
    } else {
      if (!!localStorage.getItem('access_token') && localStorage.getItem('access_token') != '') {
        authKey = 'Bearer ' + localStorage.getItem('access_token');
      } else if (req.params.get('grant_type') != null) {
        authKey = 'Basic  dXNlci1jbGllbnQ6MTIzNDU2';
        if (temp.headers.keys().indexOf('Content-Type') != -1) {
          if (temp.headers.get('Content-Type').indexOf('application/x-www-form-urlencoded') == -1) {
            headers = headers.set('Content-Type', temp.headers.get('Content-Type') + ';application/x-www-form-urlencoded');
          }
          if (temp.headers.get('Content-Type').indexOf('application/json') == -1) {
            headers = headers.set('Content-Type', temp.headers.get('Content-Type') + ';application/json');
          }
        } else {
          headers = headers.append('Content-Type', 'application/x-www-form-urlencoded;application/json');
        }
      }

      if (headers.keys().indexOf('Content-Type') != -1) {
        if (headers.get('Content-Type').indexOf('application/json') == -1) {
          headers = headers.set('Content-Type', temp.headers.get('Content-Type') + ';application/json');
        }
      } else {
        headers = headers.append('Content-Type', 'application/json');
      }
      headers = headers.append('Authorization', authKey);
    }
    headers = headers.append('Accept', 'application/json');

    return headers;
  }
}
