import { Component, OnInit } from '@angular/core';
import { Bin } from '../../../../../shared/models/Bin';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { User } from '../../../../../shared/models/User';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { Page } from '../../../../../shared/models/Page';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';

@Component({
  selector: 'app-invite-container',
  templateUrl: './invite-container.component.html',
  styleUrls: ['./invite-container.component.css'],
})
export class InviteContainerComponent implements OnInit {

  invites: Page<any>;
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  page: number = 0;
  count: number = 10;

  constructor(private _userDetailService: UserDetailsService, private _bookingService: BookingService) {
    this.user = this._userDetailService.getUser();
    this._userDetailService.user$.subscribe(next => {
      this.user = next;
    }, error => {
      console.error(error);
    });
  }

  loadBins(value: string) {
    if (value.length >= 10) {
      this._bookingService.filterForInvitationFree(this.page, this.count, value).subscribe(next => {
        this.invites = next;
      }, error => {
        console.error(error);
      });
    }
  }

  ngOnInit() {
  }

}
