import { throwError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TicketOrderWithOutSchemDto } from '../../models/dto/TicketOrderWithOutSchemDto';
import { TicketGroupWithoutScheme } from '../../models/TicketGroupWithoutScheme';
import { TicketOrderInBinDto } from '../../models/dto/TicketOrderInBinDto';
import { EventFilterType } from '../../models/enums/enums';
import { Page } from '../../models/Page';
import { ClientEventOneBaseDataDto } from '../../models/dto/ClientEventOneBaseDataDto';
import { BoxOfficeEventDto } from '../../models/dto/BoxOfficeEventDto';
import { IEvent } from 'src/shared/interfaces/event';
import { IFreeTicket, ITicketGrop } from 'src/app/client/client/events/event-action/event-action.component';

export interface IEventDiscount {
  colorPriceList: {
    available: boolean;
    color: string;
    id: number;
    price: number;
  }[];
  id: number;
  eventId: number;
  everyCounter: number;
  discunt: number;
  excludedColorPrices: string;
}

@Injectable({ providedIn: 'root' })
export class EventService {
  private controller = '/event/client';

  constructor(private httpClient: HttpClient) {}

  findFreeTicketsByEventId(eventId: number): Observable<IFreeTicket[]> {
    return this.httpClient.get<IFreeTicket[]>(`${this.controller}/online/find-all-free-tickets-by-event-id/${eventId}`);
  }

  getEventDataById(id: number): Observable<IEvent> {
    return this.httpClient
      .get<IEvent>(`${this.controller}/find-one-public-event-parse-client-event-one-base-data-dto-short/${id}`)
      .pipe(catchError(err => throwError(err)));
  }
  findOnePublicEventParseClientEventOneBaseDataDto(id: number): Observable<ClientEventOneBaseDataDto> {
    return this.httpClient
      .get<ClientEventOneBaseDataDto>(this.controller + '/find-one-public-event-parse-client-event-one-base-data-dto/' + id)
      .pipe(catchError(err => throwError(err)));
  }

  onlinefindAllFreeTicketByEventIdWithOutSchema(id: number): Observable<TicketOrderWithOutSchemDto[]> {
    return this.httpClient
      .get<TicketOrderWithOutSchemDto[]>(this.controller + '/online/find-all-free-ticket-by-event-id-with-out-schema/' + id)
      .pipe(catchError(err => throwError(err)));
  }

  getTicketGroups(id: number): Observable<ITicketGrop[]> {
    return this.httpClient
      .get<ITicketGrop[]>(this.controller + '/online/find-all-ticket-group-with-out-schema/' + id)
      .pipe(catchError(err => throwError(err)));
  }

  getAllAvailableTicketsInPartHall(eventId: number, partId: number): Observable<TicketOrderInBinDto[]> {
    return this.httpClient
      .get<TicketOrderInBinDto[]>(this.controller + '/online/find-all-free-ticket-by-event-id-and-part-hall-id/' + eventId + '/' + partId)
      .pipe(catchError(err => throwError(err)));
  }

  filterEventsBoxOfficeStatistic(
    size: number,
    page: number,
    eventFilterType: EventFilterType,
    cityId: number,
    platformHallId: number,
    categoryId: number,
    month: number,
    dateFrom: string,
    dateTo: string,
  ): Observable<Page<BoxOfficeEventDto>> {
    let body = `?size=${size}&page=${page}&`;
    body += this.parse(eventFilterType, 'eventFilterType');
    body += this.parse(cityId, 'cityId');
    body += this.parse(platformHallId, 'platformHallId');
    body += this.parse(categoryId, 'categoryId');
    if (month != undefined) {
      body += this.parse(month + 1, 'month');
    }
    body += this.parse(dateFrom, 'dateFrom');
    body += this.parse(dateTo, 'dateTo');
    return this.httpClient
      .get<Page<BoxOfficeEventDto>>(this.controller + '/filter-events-box-office-statistic' + body)
      .pipe(catchError(err => throwError(err)));
  }

  finEventInfoDto(id: string): Observable<any> {
    return this.httpClient.get(`/event/find-event-base-data-solo-info-dto/${id}`).catch(err => throwError(err));
  }

  parse(value, name): string {
    let body = '';
    if (value == 0) {
      value = '0';
    }
    if (value == '' || value == undefined) {
      return body;
    }
    body += name + '=' + value + '&';
    return body;
  }

  findAllParseEventColorPriceTicketDtoV2(id: number): Observable<any> {
    return this.httpClient.get(`/box-office-statistic/v2/find-all-parse-event-color-price-ticket-dto/${id}`).catch(err => throwError(err));
  }

  finEventTicketInfoDto(id: number): Observable<any> {
    return this.httpClient.get(`/event/find-event-ticket-info-dto/${id}`).catch(err => throwError(err));
  }

  groupBinsByEvent(id: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`/bin/group-bins-by-event?eventId=${id}`).catch(err => throwError(err));
  }

  findAllShortDto(page: number, size: number): Observable<any> {
    return this.httpClient.get(`/event/find-all-short-name-event?size=${size}&page=${page}`).catch(err => throwError(err));
  }

  getEventDiscounts(id: number): Observable<IEventDiscount[]> {
    return this.httpClient.get<IEventDiscount[]>('/discount/event/' + id).pipe(catchError(err => throwError(err)));
  }
}
