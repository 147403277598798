export class CollectionInfoDto {

  countTicket: number;
  priceCard: number;
  priceCash: number;
  allPrice: number;
  priceMinus: number;


}
