<!-- Section for print -->
<div>
  <button
    class="print-action"
    *ngIf="(actionMode === 'print' || actionMode === 'invite') && setOfCheckedId.size"
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="printOptions"
  >
    <i nz-icon nzType="printer"></i>
    Друкувати ({{ setOfCheckedId.size }})
  </button>
  <button
    class="print-action"
    *ngIf="(actionMode === 'print' || actionMode === 'invite') && setOfCheckedId.size"
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="downloadOptions"
  >
    <i nz-icon nzType="download"></i>
    Завантажити ({{ setOfCheckedId.size }})
  </button>
  <button
    class="print-action"
    *ngIf="(actionMode === 'print' || actionMode === 'invite') && setOfCheckedId.size"
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="downloadDifferentFilesOptions"
  >
    <i nz-icon nzType="download"></i>
    Окремими файлами ({{ setOfCheckedId.size }})
  </button>
  <div *ngIf="!isVendor" style="display: flex; gap: 10px; justify-content: start; align-items: center; margin-bottom: 5px">
    <input type="checkbox" name="with-price" [(ngModel)]="withPrice" />
    <label for="with-price">Вказувати ціну</label>
  </div>
</div>

<nz-dropdown-menu #printOptions="nzDropdownMenu">
  <ul *ngIf="actionMode === 'invite'; else ticketMode" nz-menu>
    <li nz-menu-item (click)="printInviteA4()">A4</li>
    <li nz-menu-item (click)="printInviteThermo()">Thermo</li>
    <li nz-menu-item (click)="printInviteThermoBig()">Thermo Big</li>
  </ul>
  <ng-template #ticketMode>
    <ul nz-menu>
      <li nz-menu-item (click)="printA4()">A4</li>
      <li nz-menu-item (click)="printThermo()">Thermo</li>
      <li nz-menu-item (click)="printThermoBig()">Thermo Big</li>
    </ul>
  </ng-template>
</nz-dropdown-menu>
<nz-dropdown-menu #downloadOptions="nzDropdownMenu">
  <ul *ngIf="actionMode === 'invite'; else downloadOptionsticketMode" nz-menu>
    <li nz-menu-item (click)="downloadInviteA4()">A4</li>
    <li nz-menu-item (click)="downloadInviteThermo()">Thermo</li>
    <li nz-menu-item (click)="downloadInviteThermoBig()">Thermo Big</li>
  </ul>
  <ng-template #downloadOptionsticketMode>
    <ul nz-menu>
      <li nz-menu-item (click)="downloadA4()">A4</li>
      <li nz-menu-item (click)="downloadThermo()">Thermo</li>
      <li nz-menu-item (click)="downloadThermoBig()">Thermo Big</li>
    </ul>
  </ng-template>
</nz-dropdown-menu>
<nz-dropdown-menu #downloadDifferentFilesOptions="nzDropdownMenu">
  <ul *ngIf="actionMode === 'invite'; else downloadDifferentFilesOptionsticketMode" nz-menu>
    <li nz-menu-item (click)="downloadInviteA4(true)">A4</li>
    <li nz-menu-item (click)="downloadInviteThermo(true)">Thermo</li>
    <li nz-menu-item (click)="downloadInviteThermoBig(true)">Thermo Big</li>
  </ul>
  <ng-template #downloadDifferentFilesOptionsticketMode>
    <ul nz-menu>
      <li nz-menu-item (click)="downloadA4(true)">A4</li>
      <li nz-menu-item (click)="downloadThermo(true)">Thermo</li>
      <li nz-menu-item (click)="downloadThermoBig(true)">Thermo Big</li>
    </ul>
  </ng-template>
</nz-dropdown-menu>

<!-- Section for return -->
<ng-container *ngIf="actionMode === 'return'">
  <button class="print-action" *ngIf="setOfCheckedId.size" (click)="returnTicketByIdMultiple()">
    Повернути квиток ({{ setOfCheckedId.size }})
  </button>
  <button class="print-action print-action-outline" (click)="returnTicketByOrder()">Повернути замовлення</button>
</ng-container>

<nz-table
  #ticketsTable
  [nzScroll]="{ y: '400px' }"
  [nzShowPagination]="false"
  [nzData]="tickets"
  [nzPageSize]="200"
  nzBordered
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th
        nzWidth="6%"
        *ngIf="!simplePreview"
        [(nzChecked)]="checked"
        [nzIndeterminate]="indeterminate"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th nzWidth="28%">Сектор</th>
      <th nzWidth="16%">Ряд</th>
      <th nzWidth="16%">Місце</th>
      <th nzWidth="24%">Ціна/Тип</th>
      <th *ngIf="!simplePreview"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of ticketsTable.data">
      <td
        nzWidth="6%"
        *ngIf="!simplePreview"
        [nzChecked]="setOfCheckedId.has(data.id)"
        (nzCheckedChange)="onItemChecked(data.id, $event)"
      ></td>
      <td nzWidth="28%">{{ data.sector }}</td>
      <td nzWidth="16%">{{ data.row }}</td>
      <td nzWidth="16%">{{ data.seatPosition }}</td>
      <td nzWidth="24%">
        {{ data?.finalPrice || data?.colorPrice?.price || '--' }}грн
        <i
          nz-icon
          [ngStyle]="{
            color: data?.colorPrice?.color,
            'font-size': '18px'
          }"
          nzType="file"
          nzTheme="fill"
        ></i>
      </td>
      <td *ngIf="(actionMode === 'print' || actionMode === 'invite') && !simplePreview" nzAlign="center">
        <i
          nz-icon
          nzType="printer"
          nzTheme="outline"
          [ngStyle]="{
            color: '#34AE60',
            'font-size': '18px'
          }"
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="printOption"
        ></i>
        <nz-dropdown-menu #printOption="nzDropdownMenu">
          <ul *ngIf="actionMode === 'invite'; else ticketMode" nz-menu>
            <li nz-menu-item (click)="printInviteA4([data?.id])">A4</li>
            <li nz-menu-item (click)="printInviteThermo([data?.id])">Thermo</li>
            <li nz-menu-item (click)="printInviteThermoBig([data?.id])">Thermo Big</li>
          </ul>
          <ng-template #ticketMode>
            <ul nz-menu>
              <li nz-menu-item (click)="printA4([data?.id])">A4</li>
              <li nz-menu-item (click)="printThermo([data?.id])">Thermo</li>
              <li nz-menu-item (click)="printThermoBig([data?.id])">Thermo Big</li>
            </ul>
          </ng-template>
        </nz-dropdown-menu>
      </td>

      <td *ngIf="actionMode === 'return' && !simplePreview" nzAlign="center">
        <i
          nz-icon
          nzType="swap"
          nzTheme="outline"
          [ngStyle]="{
            color: '#34AE60',
            'font-size': '18px'
          }"
          (click)="returnTicketById(data?.barcode)"
        ></i>
      </td>
    </tr>
  </tbody>
</nz-table>
