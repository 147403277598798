<div class="container">
  <!--white-->
  <div class="white_container">
    <div class="filter_container_name">
      <p>{{user.firstName + ' ' + user.lastName}}</p>
    </div>
  </div>

  <div class="title_container">
    <h2 class="main-h2">{{'reports.title'|translate}}</h2>
  </div>

  <div class="content-wrapper">
    <app-reports-cashier></app-reports-cashier>
  </div>
</div>
