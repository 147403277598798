import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Page } from '../../../shared/models/Page';
import { MainSettingConfig, MainSettingsService } from '../../../shared/service/rx/main-settings.service';

@Component({
  selector: 'app-pageable',
  templateUrl: './pageable.component.html',
  styleUrls: ['./pageable.component.css']
})
export class PageableComponent implements OnInit {

  mainSettingConfig: MainSettingConfig;

  countInPage: number[] = [];

  constructor(private _mainSettingsService: MainSettingsService) {
    this.mainSettingConfig = _mainSettingsService.getConfig();
    _mainSettingsService.settingConfig$.subscribe(next => {
      this.mainSettingConfig = next;
    }, error => {
      console.error(error);
    });
  }


  @Input() page: Page<any>;
  @Input() range: number = 2;
  @Output() openPage = new EventEmitter();
  @Output() countOnPage = new EventEmitter();


  generateCountInPage(): number[] {
    let temp = [];
    if (this.page.totalElements == 0) {
      temp.push(0);
    } else if (this.page.totalElements == 1) {
      temp.push(1);
    } else if (this.page.totalElements > 5) {
      temp.push(5);
    } else if (this.page.totalElements < 5) {
      temp.push(this.page.totalElements);
    }
    if (this.page.totalElements > 5) {
      let tempNumber = temp[0];
      for (let i = 0; i < this.page.totalElements; i++) {
        tempNumber = tempNumber * 2;
        if (tempNumber <= this.page.totalElements) {
          temp.push(tempNumber);
        } else {
          if (temp[temp.length - 1] < this.page.totalElements) {
            temp.push(this.page.totalElements);
          }
          break;
        }
      }
    }
    if (temp.indexOf(this.page.size) == -1) {
      temp.push(this.page.size);
    }
    return temp.sort((a, b) => a - b);
  }

  count(e) {
    this.countOnPage.emit(e.target.value);
  }

  generate(): number[] {
    let arr = [];
    for (let i = 0; i < this.page.totalPages; i++) {
      arr.push(i);
    }
    return arr;
  }

  onOpenPage(e) {

    if (e.target.value <= 0) {
      e.target.value = 1;
      if (this.page.number != (parseInt(e.target.value) - 1))
        this.open(parseInt(e.target.value) - 1);
    } else if (e.target.value <= this.page.totalPages) {
      this.open(parseInt(e.target.value) - 1);
    } else {
      e.target.value = this.page.totalPages;
      if (this.page.number != (parseInt(e.target.value) - 1))
        this.open(parseInt(e.target.value) - 1);
    }

  }

  open(i: number) {
    this.openPage.emit(i);
  }

  disabled(one: number) {
    if (this.page.number < this.range && one < (2 * this.range + 1)) {
      return true;
    } else if (((this.page.number + (2 * this.range)) > this.page.totalPages) && this.page.totalPages - (this.range * 2 + 1) <= one) {
      return true;
    } else if (!(this.page.number + this.range < one || this.page.number - this.range > one)) {
      return true;
    }
    return false;

  }

  ngOnInit() {
  }

}
