import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MainLoaderService } from 'src/shared/service/rx/main-loader.service';

@Component({
  selector: 'app-cg-daily-review',
  templateUrl: './cg-daily-review.component.html',
  styleUrls: ['./cg-daily-review.component.css']
})
export class CgDailyReviewComponent implements OnInit {
  @Output()
  public onReportCreate: EventEmitter<any> = new EventEmitter();

  public formGroup: FormGroup;
  public maxDate = Date.now();

  public get ownEventsOnly() {
    return this.formGroup.get('ownEventsOnly').value;
  }

  public get cashierWorkDay() {
    return this.formGroup.get('cashierWorkDay').value;
  }

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  public loadReport(): void {
    this.onReportCreate.emit(this.formGroup.value)
  }

  private initForm(): void {
    this.formGroup = this.fb.group({
      numberInvoice: [null, Validators.required],
      cashierWorkDay: [null, Validators.required],
      type_of_payment: 'All',
      ownEventsOnly: '',
      organizers: [],
    })
  }
}
