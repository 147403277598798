import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

export interface IAccessList {
  cashierId: number;
  excludedModule: 'REPORT';
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class AccessRoleService {
  accessList: BehaviorSubject<IAccessList> = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient) {
    this.getCashierAccesses().pipe(first()).subscribe();
  }

  getCashierAccesses() {
    const user = JSON.parse(localStorage.getItem('user'));

    return this.accessList.getValue() === null
      ? this.httpClient.get<IAccessList>(`/box_office_views/cashier/${user.id}`).pipe(
          tap(res => this.accessList.next(res || ({} as any))),
          catchError(err => throwError(err)),
        )
      : this.accessList.asObservable();
  }
}
