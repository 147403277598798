import { NgModule } from '@angular/core';

import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

const modules = [
  NzTableModule,
  NzDropDownModule,
  NzIconModule,
  NzEmptyModule,
  NzModalModule,
  NzSelectModule,
  NzButtonModule,
  NzSpinModule,
  NzInputModule,
  NzDatePickerModule,
  NzCheckboxModule,
  NzDividerModule,
  NzSwitchModule,
];

@NgModule({
  imports: [...modules],
  exports: [...modules],
})

export class AntModule { }
