import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { BoxOfficeEventDto } from '../../../../../../shared/models/dto/BoxOfficeEventDto';
import { MainSettingConfig, MainSettingsService } from '../../../../../../shared/service/rx/main-settings.service';
@Component({
  selector: 'app-event-one-big',
  templateUrl: './event-one-big.component.html',
  styleUrls: ['./event-one-big.component.css']
})
export class EventOneBigComponent implements OnInit {

  @Output() moreInfo = new EventEmitter();
  @Input() eventData: BoxOfficeEventDto;

  lang: string;
  mainSettingConfig: MainSettingConfig;

  constructor(
    private _translateService: TranslateService,
    private _mainSettingsService: MainSettingsService,
    private router: Router,
  ) {
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(next => {
      this.lang = next.lang;
    }, error => {
      console.error(error);
    });
    this._mainSettingsService.settingConfig$.subscribe(next => {
      this.mainSettingConfig = next;
    }, error => {
      console.error(error);
    });
    this.mainSettingConfig = this._mainSettingsService.getConfig();
  }

  ngOnInit() {
  }

  openMoreInfo() {
    this.moreInfo.emit(this.eventData.id);
  }

  openHover(id: string) {
    document.getElementById(id).classList.add('display-flex');
  }

  closeHover(id: string) {
    document.getElementById(id).classList.remove('display-flex');
  }

  // public goOrders(id) {
  //   this.router.navigate([`/order/${id}`], { queryParams: { venor: null } });
  // }
}
