import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { PlatformHall } from '../../../../../shared/models/PlatformHall';
import { IDictionary } from 'src/shared/interfaces/interfaces';

class PlatformDTO extends PlatformHall {
  numberOfEvents: number;
  cityName: IDictionary;
  cityId: number;
}

@Component({
  selector: 'app-platform-events-one',
  templateUrl: './platform-events-one.component.html',
  styleUrls: ['./platform-events-one.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformEventsOneComponent implements OnInit {
  @Input() one: PlatformDTO;

  lang: string;

  constructor(private _translateService: TranslateService, private _router: Router) {
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(
      next => {
        this.lang = next.lang;
      },
      error => {
        console.error(error);
      },
    );
  }

  navigationToHome() {
    if (!this.one) return;

    this._router.navigate(['/'], { queryParams: { platformHallId: this.one.id, cityId: this.one.cityId } });
  }

  ngOnInit() {}
}
