import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ImageD } from '../../models/User';

/**
 * Created by sold-out-admin-client in company M-PLUS.
 * User: nazar
 * Date: 14.08.2018
 * Time: 17:36
 */
@Injectable({ providedIn: 'root' })
export class ImageServiceService {

  private controller = '/image';

  constructor(private _httpClient: HttpClient) {
  }

  get(id: number): Observable<ImageD> {
    return this._httpClient.get<ImageD>(this.controller + `/img/${id}`).pipe(
      catchError(err => throwError(err)));
  }
  getAlt(id: number): Observable<string> {
    return this._httpClient.get(this.controller + `/alt/${id}`, { responseType: 'text' }).pipe(
      catchError(err => throwError(err)));
  }

  getPath(id: number): Observable<string> {
    return this._httpClient.get(this.controller + `/path/${id}`, { responseType: 'text' }).pipe(
      catchError(err => throwError(err)));
  }

  getTitle(id: number): Observable<string> {
    return this._httpClient.get(this.controller + `/title/${id}`, { responseType: 'text' }).pipe(
      catchError(err => throwError(err)));
  }

}
