import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NetBoxOffice } from '../../models/NetBoxOffice';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import { Page } from '../../models/Page';
import { NetBoxOfficeWithServiceCollectionDto } from '../../models/net-box-office-with-service-collection.dto';
import { NetBoxOfficeStatisticPayDto } from '../../models/dto/NetBoxOfficeStatisticPayDto';
import { NetBoxOfficeSearchDto } from '../../models/dto/NetBoxOfficeSearchDto';

@Injectable({ providedIn: 'root' })
export class NetBoxOfficeService {

  controller = '/net-box-office';

  constructor(private _httpClient: HttpClient) {

  }

  findAll(): Observable<NetBoxOffice[]> {
    return this._httpClient.get<NetBoxOffice[]>(this.controller + '/find-all-select').catch(err => Observable.throw(err));
  }

  findAllFilter(available: boolean, research: string, page: number, size: number): Observable<Page<NetBoxOfficeWithServiceCollectionDto>> {
    let p: HttpParams = new HttpParams()
      .set('available', available + '');
    if (research)
      p = p.set('research', research);
    return this._httpClient.get<Page<NetBoxOfficeWithServiceCollectionDto>>(`${this.controller}/find-all-filter?page=${page}&size=${size}`, {
      params: p
    }).catch(err => Observable.throw(err));
  }

  create(netBoxOffice: NetBoxOffice, ownerId?: string): Observable<NetBoxOffice> {
    if (ownerId != 'undefined') {
      return this._httpClient.post<NetBoxOffice>(this.controller + `/create?ownerId=${ownerId}`, JSON.stringify(netBoxOffice))
        .catch(err => Observable.throw(err));
    } else {
      return this._httpClient.post<NetBoxOffice>(this.controller + `/create`, JSON.stringify(netBoxOffice))
        .catch(err => Observable.throw(err));
    }
  }

  changeStatus(netBoxOffice: NetBoxOffice): Observable<NetBoxOffice> {
    return this._httpClient.post<NetBoxOffice>(this.controller + '/change-status', JSON.stringify(netBoxOffice)).catch(err => Observable.throw(err));
  }

  update(netBoxOffice: NetBoxOffice, ownerId?: string): Observable<NetBoxOffice> {
    return this._httpClient.post<NetBoxOffice>(this.controller + `/update?ownerId=${ownerId}`, JSON.stringify(netBoxOffice)).catch(err => Observable.throw(err));
  }

  findAllSortAndFilter(page: number, count: number, research: HTMLInputElement, available: HTMLSelectElement, direction: HTMLSelectElement, sort: string): Observable<NetBoxOffice[]> {
    return this._httpClient.get<NetBoxOffice[]>(`${this.controller}/find-all-sort-and-filter/${page}/${count}/${this.generator(research, available, direction)}&sort=id`);
  }

  setUserBoxOfficeManager(userId: number, netBoxOfficeId: number): Observable<any> {
    return this._httpClient.post(this.controller + `/set-user-box-office-manager`, new FormData(), {
      params: new HttpParams()
        .set('userId', userId + '')
        .set('netBoxOfficeId', netBoxOfficeId + '')
    }).catch(err => Observable.throw(err));
  }

  findByEventBox(id: number): Observable<NetBoxOfficeStatisticPayDto[]> {
    return this._httpClient.get<NetBoxOfficeStatisticPayDto[]>(this.controller + '/find-by-event-box/' + id).catch(err => Observable.throw(err));
  }

  findAllNetBixOfficesByEvent(eventId: number, page: number, size: number) {
    let body = `?page=${page}&size=${size}&`;
    if (eventId) {
      body += `eventId=${eventId}&`;
    }
    return this._httpClient.get(this.controller + '/find-all-net-box-offices-by-event/' + body).catch(err => Observable.throw(err));
  }

  private generator(research: HTMLInputElement, available: HTMLSelectElement, direction: HTMLSelectElement): string {
    let url = '?';
    if (research.value != '') {
      url += 'research=' + research.value;
    }
    if (available.value != 'null') {
      if (url != '?')
        url += '&';
      url += 'available=' + available.value;
    }
    if (direction.value != 'null') {
      if (url != '?')
        url += '&';
      url += 'direction=' + direction.value;
    }
    return url == '?' ? '' : url;
  }

  findOne(id: number): Observable<NetBoxOffice> {
    return this._httpClient.get<NetBoxOffice>(this.controller + '/find-one/' + id).catch(err => Observable.throw(err));
  }

  findAllAvailableNetBoxOfficesResearcher(eventId: number, researcher?: string): Observable<Page<NetBoxOfficeSearchDto>> {
    return this._httpClient.get<Page<NetBoxOfficeSearchDto>>(this.controller + '/find-all-available-net-box-offices-researcher',
      {
        params: new HttpParams()
          .set('researcher', (researcher && researcher.trim() != '') ? researcher : '')
          .set('eventId', eventId + '')
      }).catch(err => Observable.throw(err));
  }

  findAllBox(available: boolean) {
    let p: HttpParams = new HttpParams()
      .set('available', available + '');
    return this._httpClient.get(this.controller + '/find-all-filter', {
      params: p
    }).catch(err => Observable.throw(err));
  }

}
