import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { TranslateModule } from '@ngx-translate/core';

import { PipeModule } from '../shared/pipes/pipe.module';
import { DirectiveModule } from '../shared/directives/directive.module';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule,
  ], declarations: [
  ],
  exports: [
    PipeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TextMaskModule,
    DirectiveModule,
  ]
})
export class GlobalImportModule { }


