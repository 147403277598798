import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { CategoryService } from '../../../../../../shared/service/backend/category.service';
import { EventService } from '../../../../../../shared/service/backend/event.service';
import { HallService } from '../../../../../../shared/service/backend/hall.service';
import { LocationService } from '../../../../../../shared/service/backend/location.service';
import { MainLoaderService } from '../../../../../../shared/service/rx/main-loader.service';
import { MainSettingConfig, MainSettingsService } from '../../../../../../shared/service/rx/main-settings.service';
import { UserDetailsService } from '../../../../../../shared/service/user-details-service.service';
import { BookingService } from '../../../../../../shared/service/backend/booking.service';
import { UserWithBoxOfficeDto } from '../../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { BoxOfficeEventDto } from '../../../../../../shared/models/dto/BoxOfficeEventDto';
import { Page } from '../../../../../../shared/models/Page';
import { EventFilterType } from 'src/shared/models/enums/enums';

class EventDate {
  date: string;
  events: BoxOfficeEventDto[] = [];
}

@Component({
  selector: 'app-reports-accountant',
  templateUrl: './reports-accountant.page.html',
  styleUrls: ['./reports-accountant.page.css'],
})
export class ReportsAccountantPage implements OnInit {
  @ViewChild('researcher') researcher: ElementRef;

  configSetting: MainSettingConfig = new MainSettingConfig();
  filterDateFrom: string = undefined;
  filterDateTo: string = undefined;
  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();
  lang: string;
  count: number = 9;
  page: number = 0;
  events: Page<BoxOfficeEventDto>;
  showMoreEvents: boolean = true;
  filterEventFilterType: EventFilterType = 'ALL';
  eventsByDate: EventDate[] = [];
  filterMonth = undefined;
  sort: 'ASC' | 'DESC' = 'ASC';
  byDate: boolean;

  constructor(private _userDetailService: UserDetailsService, private _eventService: EventService,
    private _locationService: LocationService, private _categoryService: CategoryService,
    private _mainLoader: MainLoaderService, private _hallService: HallService,
    private _translateService: TranslateService, private _bookingService: BookingService,
    private _activatedRoute: ActivatedRoute, private _mainSettingsService: MainSettingsService,
    private _router: Router) {
    this.user = this._userDetailService.getUser();
    this._userDetailService.user$.subscribe(next => {
      this.user = next;
    }, error => {
      console.error(error);
    });
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(next => {
      this.lang = next.lang;
    }, error => {
      console.error(error);
    });
    this.configSetting = this._mainSettingsService.getConfig();
    this.configSetting.date == 'WITH' ? this.byDate = true : this.byDate = false;
    this._mainSettingsService.settingConfig$.subscribe(next => {
      this.configSetting = next;
      next.date == 'WITH' ? this.byDate = true : this.byDate = false;
    }, error => {
      console.error(error);
    });
    this._activatedRoute.queryParams.subscribe(next => {
      this.loadEventWithFilter();
    }, error => {
      console.error(error);
    });
  }

  ngOnInit() {
  }

  loadEventWithResearcher(value) {
    if (value.length > 0) {
      this.page = 0;
      this._mainLoader.startLoader();
      this._bookingService.filterEventsBoxOfficeStatisticResearcherbyDate(value, (this.lang == 'ua' ? 'uk' : this.lang).toLocaleUpperCase(), this.sort, this.byDate).subscribe(next => {
        this.events = next;
        if (next.content.length < this.count || next.last) {
          this.showMoreEvents = false;
        } else {
          this.showMoreEvents = true;
        }
        this.eventsByDate = this.groupEventByDate(next, this.eventsByDate);
        this.filterDateFrom = undefined;
        this.filterDateTo = undefined;
        this._mainLoader.endLoader();
      }, error => {
        this._mainLoader.endLoader();
        console.error(error);
      });
    } else {
      this.resetFilter();
    }
  }

  setDefaultDataToInput(type: boolean) {
    if (!((<HTMLInputElement>event.target).value))
      return;
    let date = new Date();
    if (type) {
      if ((!this.filterDateFrom) || this.filterDateFrom == '') {
        this.filterDateFrom = date.toISOString().slice(0, 10);
      }
    } else {
      if ((!this.filterDateTo) || this.filterDateTo == '') {
        this.filterDateTo = +((date.toISOString().slice(0, 10)).slice(0, 4)) + 1 + '-' + (date.toISOString().slice(0, 10)).slice(5, 8) + (date.toISOString().slice(0, 10)).slice(8, 10);
      }
    }
  }

  loadEventWithFilterDate() {
    this.page = 0;
    this._mainLoader.startLoader();
    this.filterEventFilterType = 'ALL';
    this.filterMonth = undefined;
    this._bookingService.filterEventsBoxOfficeStatisticByDate(this.count, this.page, this.filterEventFilterType, undefined, undefined, undefined, undefined, this.filterDateFrom, this.filterDateTo, this.sort, this.byDate).subscribe(next => {
      this.events = next;
      if (next.content.length < this.count || next.last) {
        this.showMoreEvents = false;
      } else {
        this.showMoreEvents = true;
      }
      this.eventsByDate = this.groupEventByDate(next, this.eventsByDate);
      this.researcher.nativeElement.value = '';
      this._mainLoader.endLoader();
    }, error => {
      this._mainLoader.endLoader();
      console.error(error);
    });
  }

  groupEventByDate(arr, arrResult: EventDate[]) {
    arrResult = [];
    let tempArr: EventDate = new EventDate();
    arr.content.forEach((value, index) => {
      if (index == 0) {
        tempArr.date = value.beginDate;
        tempArr.events.push(value);
        if (arr.content.length == 1) {
          arrResult.push(tempArr);
        }
      } else {
        if (new Date(value.beginDate).toLocaleDateString() == new Date(tempArr.date).toLocaleDateString()) {
          tempArr.events.push(value);
          if (index == (arr.content.length - 1)) {
            arrResult.push(tempArr);
          }
        } else {
          arrResult.push(tempArr);
          tempArr = new EventDate();
          tempArr.date = value.beginDate;
          tempArr.events.push(value);
          if (index == (arr.content.length - 1)) {
            arrResult.push(tempArr);
          }
        }
      }
    });
    return arrResult;
  }

  resetFilter() {
    this.filterEventFilterType = 'ALL';
    this.filterDateFrom = null;
    this.filterDateTo = null;
    this.researcher.nativeElement.value = '';
    this.loadEventWithFilter();
  }

  loadEventWithFilter() {
    this.page = 0;
    this._mainLoader.startLoader();
    this._bookingService.filterEventsBoxOfficeStatisticByDate(this.count, this.page, this.filterEventFilterType, undefined, undefined, undefined, this.filterMonth, this.filterDateFrom, this.filterDateTo, this.sort, this.byDate).subscribe(next => {
      this.events = next;
      if (next.content.length < this.count || next.last) {
        this.showMoreEvents = false;
      } else {
        this.showMoreEvents = true;
      }
      this.eventsByDate = this.groupEventByDate(next, this.eventsByDate);
      this.researcher.nativeElement.value = '';
      this._mainLoader.endLoader();
    }, error => {
      this._mainLoader.endLoader();
      console.error(error);
    });
  }

  trackById(index, obj) {
    return obj.id;
  }

  onEventStatusChange(event): void {
    this.filterEventFilterType = event.target.value ?? 'ALL';
    this.loadEventWithFilter();
  }
}
