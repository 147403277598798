export class BinPriceWinCommissionDto {

  price: number;
  priceWithOutCommission: number;
  commissionPrice: number;
  commission: number;
  bookedCommission: number;
  bookedCommissionPrice: number;

}
