import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { User } from '../../../../../shared/models/User';
import { PdfService } from '../../../../../shared/service/backend/pdf.service';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { Page } from '../../../../../shared/models/Page';
import { Bin } from '../../../../../shared/models/Bin';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { SideInfo, SideInfoRxService } from '../../../../source/side-info-bar/side-info-bar.component';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reserve-container',
  templateUrl: './reserve-container.component.html',
  styleUrls: ['./reserve-container.component.css'],
})
export class ReserveContainerComponent implements OnInit {

  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  bins: Page<any>;

  page = 0;
  count = 10;

  deleteOrderBin: string = '';

  idEvent: any;

  constructor(private _userDetailService: UserDetailsService, private _pdfService: PdfService,
    private _bookingService: BookingService, private _mainLoaderService: MainLoaderService,
    private _sideInfoRxService: SideInfoRxService, private _activatedRoute: ActivatedRoute) {
    this.user = this._userDetailService.getUser();
  }

  ngOnInit() {
    this.loadReservations();
  }

  openPage(i) {
    this.page = i;
    this.loadReservations();
  }

  loadBins(value: string) {
    if (value.length >= 5) {
      this._mainLoaderService.startLoader();
      this._bookingService.filterForBooked(this.page, this.count, value).subscribe(next => {
        this.bins = next;
        this._mainLoaderService.endLoader();
      }, error => {
        this._mainLoaderService.endLoader();
        console.error(error);
      });
    }

    if (value.length === 0) {
      this.loadReservations();
    }
  }

  deleteBinReserved(reason: string) {
    if (reason.length == 0 || reason.charAt(0) == ' ') {
      this._sideInfoRxService.add(new SideInfo().setInfo('Не вказано причину!').setDate().setError('error'));
      return;
    }
    this._bookingService.boxDeleteBin(this.deleteOrderBin, reason).subscribe(next => {
      this._sideInfoRxService.add(new SideInfo().setInfo('Замовлення успішно відмінено!').setDate());
      this.bins.content = this.bins.content.filter(value => value.order != this.deleteOrderBin);
    }, error => {
      console.error(error);
    });
  }

  openClosePopUp(id: string) {
    document.getElementById(id).classList.toggle('display-flex');
  }

  changeCount(perPage: number): void {
    this.count = perPage;
    this.loadReservations();
  }

  private loadReservations(): void {
    this._bookingService.filterForBooked(this.page, this.count, null).subscribe(next => {
      this.bins = next;
    }, error => {
      console.error(error);
    });
  }
}
