import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { User } from '../../models/User';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FieldStatusDto } from 'src/shared/models/enums/Field-status';
import { Page } from 'src/shared/models/Page';

@Injectable({ providedIn: 'root' })
export class ValidatorService {

  private controller = '/validator';

  constructor(private _httpClient: HttpClient) { }

  validEvent(id: number): Observable<FieldStatusDto[]> {
    return this._httpClient.get<FieldStatusDto[]>(this.controller + '/event/' + id)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
  findByEmail(email: string): Observable<User> {
    return this._httpClient.get<User>(this.controller + '/find-user-by-email', { params: new HttpParams().set("email", email) })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
  findAllByEmail(email: string, page: number, size: number): Observable<Page<User>> {
    return this._httpClient.get<Page<User>>(this.controller + '/find-all-user-by-email', {
      params: new HttpParams()
        .set("email", email)
        .set("page", String(page))
        .set("size", String(size))
    })
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
}
