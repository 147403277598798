import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

import { Ticket } from '../../../../../../shared/models/Ticket';
import { IOnSelectTicketEvent } from '../../event-action/event-action.component';
import { Router } from '@angular/router';

interface HoveredTicket {
  ticketPosition: string;
  ticketRow: string;
  ticketSector: string;
  price: number;
  hoveredTicket: any;
  fanzoneSeats: number | null;
  pageX: number;
  pageY: number;
}

@Component({
  selector: 'app-event-hall',
  templateUrl: './event-hall.component.html',
  styleUrls: ['./event-hall.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventHallComponent implements OnChanges, AfterViewChecked, OnDestroy {
  @ViewChild('svgtag') tests: ElementRef;
  @ViewChild('idsvgtemp') svgtemp: ElementRef;
  @ViewChild('plus') plus: ElementRef;
  @ViewChild('minus') minus: ElementRef;
  @ViewChild('ticketInfoTooltip') ticketTooltip: TemplateRef<any>;

  @Input() partHallPattern: string;
  @Input() isBuy: boolean;
  @Input() tickets: Ticket[] = [];
  // @Input() colorPrices: IColorPrice[] = [];
  @Input() selectType: 'seat' | 'row' | 'sector' = 'seat';
  @Input() isMultiHall: boolean;

  @Output() onSelectTickets: EventEmitter<IOnSelectTicketEvent> = new EventEmitter();

  zoomLevel: number = 1;
  stop: boolean = false;
  render: string = 'ready';
  hoveredTicket$: Subject<HoveredTicket | null> = new Subject();

  width: number = 49;
  height: number = 79;
  loadHall: boolean = false;
  zoomIsActive: boolean = false;
  tempX: number;
  tempY: number;
  onBuyTimeout = false;

  isAbonement: boolean;
  isPaintSections = false;
  isPaintSeats = false;

  constructor(private router: Router) {
    this.isAbonement = this.router.url.includes('?abonement');
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes?.tickets?.currentValue?.length) {
        this.addListenerToMultihallSections();
        this.paintSeat(this.tickets);
      }
      if (changes?.tickets?.currentValue.length && this.tests) {
        this.addEventListenerBuy();
      }
    }, 100);
  }
  ngAfterViewChecked(): void {
    if (!this.isPaintSections) {
      this.addListenerToMultihallSections();
    }
    if (!this.isPaintSeats) {
      this.paintSeat(this.tickets);
      this.addEventListenerBuy();
    }
  }

  paintSectors(section: HTMLElement, sectorLink: string) {
    const seatOfSector = document.querySelector(`[sector_name='${sectorLink}']`).querySelector('[seatId]') as HTMLElement;
    if (this.isBuy) {
      if (seatOfSector) {
        const colorPrice = this.tickets.find(el => +el.seat.id === +seatOfSector.getAttribute('seatId'))?.colorPrice?.color || 'rgb(0 0 0)';
        section.style.fill = colorPrice;
      }
    } else {
      const colorPrice = this.tickets.find(el => el.sector === sectorLink)?.colorPrice?.color || 'gray';
      section.style.fill = colorPrice;
    }
  }

  addListenerToMultihallSections() {
    // if (this.isMultiHall) {

    if (Array.from(document.querySelectorAll('[sector-link]')).length) {
      this.isPaintSections = true;
    }
    Array.from(document.querySelectorAll('[sector-link]')).forEach((section, idx) => {
      const sectorLink = section.getAttribute('sector-link');
      this.paintSectors(section as HTMLElement, sectorLink);
      section.addEventListener('click', () => {
        this.displayCurrentSector(document.querySelector(`[sector_name='${sectorLink}']`).id);
      });
    });
    if (document.getElementById('fanzone')) {
      document.getElementById('fanzone').style.fill = '#13a113';
    }
    // }
  }

  displayCurrentSector(id: string) {
    document.getElementById('general').style.display = id === 'general' ? 'block' : 'none';
    Array.from(document.querySelectorAll('[sector_name]')).forEach(el => {
      (el as HTMLElement).style.display = el.id === id ? 'block' : 'none';
    });
  }

  addEventListenerForZoomByScroll() {
    document.addEventListener('wheel', this.zoomByScroll);
  }

  rmEventListenerForZoomByScroll() {
    document.removeEventListener('wheel', this.zoomByScroll);
  }

  onZoom(zoomType) {
    if (!this.zoomIsActive) {
      (<HTMLDivElement>this.tests.nativeElement).style.transition = '400ms all';
      this.zoomIsActive = true;
      let zoomStep: number = 1.56;
      if (zoomType == 'in') {
        if (this.zoomLevel <= 9) {
          this.zoomLevel *= zoomStep;
        }
      } else {
        if (this.zoomLevel >= 1) {
          this.zoomLevel /= zoomStep;
        }
      }
    } else {
      return;
    }
    setTimeout(() => {
      this.zoomIsActive = false;
      (<HTMLDivElement>this.tests.nativeElement).style.transition = '';
    }, 400);
  }

  center() {
    (<HTMLDivElement>this.tests.nativeElement).style.transition = '400ms all';
    this.width = 49;
    this.height = 79;
    this.zoomLevel = 1;
    (<HTMLDivElement>this.tests.nativeElement).style.left = '';
    (<HTMLDivElement>this.tests.nativeElement).style.top = '';
    (<HTMLDivElement>this.tests.nativeElement).style.transition = '';
  }

  zoomByScroll = e => {
    e.deltaY > 0 ? this.onZoom('out') : this.onZoom('in');
  };

  mouseMove = e => {
    this.tests.nativeElement.style.left = this.tests.nativeElement.offsetLeft + e.pageX - this.tempX + 'px';
    this.tests.nativeElement.style.top = this.tests.nativeElement.offsetTop + e.pageY - this.tempY + 'px'; //todo
    this.tempX = e.pageX;
    this.tempY = e.pageY;
    e.preventDefault();
  };

  mouseDown = e => {
    this.tempX = e.pageX;
    this.tempY = e.pageY;
    this.stop = true;
    (<HTMLDivElement>this.tests.nativeElement).style.cursor = '-webkit-grabbing';
    document.addEventListener('mouseup', () => {
      (<HTMLDivElement>this.tests.nativeElement).style.cursor = '';
      this.stop = false;
      document.removeEventListener('mousemove', this.mouseMove);
    });
    document.addEventListener('mousemove', this.mouseMove);
    e.preventDefault();
  };

  // grab and show tooltip with seat info
  showInfoAboutSeat(event): void {
    const seatId = (<HTMLElement>event.target).getAttribute('seatId');
    const ticketPosition = (<HTMLElement>event.target).getAttribute('seat_position');
    const ticketRow = (<HTMLElement>event.target).parentElement.getAttribute('row_number');
    const ticketSector = (<HTMLElement>event.target).parentElement.parentElement.getAttribute('sector_name');
    const hoveredTicket = this.tickets.find(ticket => ticket?.seat.id === +seatId);
    const fanzoneSeats = hoveredTicket?.seat.fanzone ? this.getFanZoneFreeSeats(hoveredTicket?.seat.id) : null;
    const price = +(<HTMLElement>event.target).getAttribute('price');

    this.hoveredTicket$.next({
      ticketPosition,
      ticketRow,
      ticketSector,
      hoveredTicket,
      price,
      fanzoneSeats,
      pageX: event.pageX - 160,
      pageY: event.pageY - 90,
    });
  }

  // hides seat tooltip
  hideInfoAboutSeat(): void {
    this.hoveredTicket$.next(null);
  }

  // Color scheme when tickets received
  paintSeat(tickets: Ticket[]) {
    for (let i = 0; i < tickets.length; i++) {
      const seatElement = document.getElementById(tickets[i].seat.key);
      if (seatElement) {
        this.isPaintSeats = true;
        seatElement.style.fill = tickets[i].colorPrice.color;
        seatElement.setAttribute('seatId', tickets[i].seat.id + '');
        seatElement.setAttribute('price', tickets[i].colorPrice.price + '');
        seatElement.setAttribute('ticketId', tickets[i].id + '');
        seatElement.setAttribute('fanzone', 'false');
      }
      if (tickets[i].seat.fanzone) {
        document.getElementById(tickets[i].seat.key).setAttribute('fanzone', 'true');
      }
    }
  }

  addEventListenerBuy(): void {
    const svgWrapper = document.getElementById('svgtaginnerid');

    const seatMouseEnter = (event: MouseEvent) => this.showInfoAboutSeat(event);
    const seatMouseLeave = () => this.hideInfoAboutSeat();
    const svgWrapperClick = (event: MouseEvent) => {
      if (!this.onBuyTimeout) {
        const target = event.target as HTMLElement;
        let ticketsIds: number[] = [];
        const ticketId = +target.getAttribute('ticketId');
        if (target.getAttribute('seatId')) {
          if (target.getAttribute('fanzone') === 'true') {
            const ticketsCount = this.isAbonement ? 1 : +prompt('');
            if (ticketsCount) {
              ticketsIds = this.tickets
                .filter(el => el.seat.key === target.id)
                .slice(0, ticketsCount)
                .map(el => el.id);
            } else {
              this.onBuyTimeout = true;
              setTimeout(() => (this.onBuyTimeout = false), 100);
              return;
            }
          } else {
            if (this.selectType === 'row') {
              if (target.parentElement.getAttribute('row_number')) {
                ticketsIds = ([...(target.parentElement.children as any)] as HTMLElement[])
                  .map(seat => +seat.getAttribute('ticketId'))
                  .filter(el => el);
              }
            } else if (this.selectType === 'sector') {
              if (target.parentElement.parentElement.getAttribute('sector_name')) {
                ticketsIds = ([...(target.parentElement.parentElement.children as any)] as HTMLElement[])
                  .filter(row => row.getAttribute('row_number'))
                  .map(row => {
                    return ([...(row.children as any)] as HTMLElement[]).map(seat => +seat.getAttribute('ticketId')).filter(el => el);
                  })
                  .reduce((acc, val) => acc.concat(val));
              }
            } else {
              ticketsIds = [ticketId];
            }
            ticketsIds = [ticketId, ...ticketsIds.filter(el => el !== ticketId)];
          }

          this.onSelectTickets.emit({ ticketsIds, fanzoneKey: ticketsIds.length ? null : target.id });
        }
      }
      this.onBuyTimeout = true;
      setTimeout(() => (this.onBuyTimeout = false), 100);
    };

    if (svgWrapper && this.tests?.nativeElement) {
      svgWrapper.addEventListener('click', svgWrapperClick);
      for (let i = 0; i < document.getElementsByClassName('seat').length; i++) {
        document.getElementsByClassName('seat')[i].addEventListener('mouseenter', seatMouseEnter);
        document.getElementsByClassName('seat')[i].addEventListener('mouseleave', seatMouseLeave);
      }
      this.tests.nativeElement.addEventListener('mousedown', this.mouseDown);
    }
  }

  getFanZoneFreeSeats(id: number): number {
    return this.tickets.filter(ticket => ticket.seat.id === id).length;
  }

  ngOnDestroy(): void {}
}
