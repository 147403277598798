import { Routes } from '@angular/router';

import { ClientComponent } from './client/client.component';
import { ReturnedTicketsComponent } from './client/returned-tickets/returned-tickets.component';
import { EventsComponent } from './client/events/events.component';
import { EventContainerComponent } from './client/events/event-container/event-container.component';
import { EventBuyComponent } from './client/events/event-buy/event-buy.component';
import { PlatformComponent } from './client/platform/platform.component';
import { InviteComponent } from './client/invite/invite.component';
import { InviteContainerComponent } from './client/invite/invite-container/invite-container.component';
import { InviteContainerOneComponent } from './client/invite/invite-container-one/invite-container-one.component';
import { ReserveComponent } from './client/reserve/reserve.component';
import { ReserveContainerComponent } from './client/reserve/reserve-container/reserve-container.component';
import { ReserveContainerOneComponent } from './client/reserve/reserve-container-one/reserve-container-one.component';
import { HistoryComponent } from './client/history/history.component';
import { HistoryContainerComponent } from './client/history/history-container/history-container.component';
import { CollectionComponent } from './client/collection/collection.component';
import { CollectionContainerComponent } from './client/collection/collection-container/collection-container.component';
import { AdminCanActive } from '../../shared/can-active/admin/admin.can-active';
import { HistoryBookedContainerComponent } from './client/history/history-booked-container/history-booked-container.component';
import { HistoryReturnTicketContainerComponent } from './client/history/history-return-ticket-container/history-return-ticket-container.component';
import { UpdateCanDeActivate } from '../../shared/can-de-activate/update/update.can-de-activate';
import { HistoryBookedCanceledComponent } from './client/history/history-booked-canceled/history-booked-canceled.component';
import { ReportsCashierPage, ReportsAccountantPage } from './client/reports/pages';
import { ReportsWrapperComponent } from './client/reports/components';
import { AgentsPage } from './client/agents/pages/agents-page/agents.page';
import { EventLockListComponent } from './client/events/event-lock-list/event-lock-list.component';
import { EventInvitationListComponent } from './client/events/event-invitation-list/event-invitation-list.component';
import { ReportsByEventComponent } from './client/reports/pages/reports-by-event/reports-by-event.component';
import { EventActionComponent } from './client/events/event-action/event-action.component';
import {
  AccountantWrapperComponent,
  EventBySellersComponent,
  EventDetailsComponent,
  EventGeneralComponent,
  EventStatisticComponent,
  ReportsComponent,
  SailsByMonthComponent,
} from './client/accountant/pages';
import { MenuGuard } from 'src/shared/guards/guard.guard';

export const clientRoutes: Routes = [
  {
    path: '',
    component: ClientComponent,
    canActivate: [AdminCanActive],
    children: [
      {
        path: '',
        component: EventsComponent,
        children: [
          {
            path: '',
            component: EventContainerComponent,
          },
          {
            path: 'buy/:id',
            component: EventBuyComponent,
            canDeactivate: [UpdateCanDeActivate],
          },
          {
            path: 'order/:id',
            component: EventActionComponent,
            canDeactivate: [UpdateCanDeActivate],
          },
          {
            path: 'block/:id/list',
            component: EventLockListComponent,
            canDeactivate: [UpdateCanDeActivate],
          },
          {
            path: 'block/:id',
            component: EventActionComponent,
            canDeactivate: [UpdateCanDeActivate],
          },
          {
            path: 'block/:id',
            component: EventActionComponent,
            canDeactivate: [UpdateCanDeActivate],
          },
          {
            path: 'invitations/:id/list',
            component: EventInvitationListComponent,
            canDeactivate: [UpdateCanDeActivate],
          },
          {
            path: 'invitations/:id/new',
            component: EventActionComponent,
            canDeactivate: [UpdateCanDeActivate],
          },
        ],
      },
      {
        path: 'return',
        component: ReturnedTicketsComponent,
      },
      {
        path: 'platform',
        component: PlatformComponent,
      },
      {
        path: 'invite',
        component: InviteComponent,
        children: [
          { path: '', component: InviteContainerComponent },
          { path: ':id', component: InviteContainerOneComponent },
        ],
      },
      {
        path: 'reserve',
        component: ReserveComponent,
        children: [
          { path: '', component: ReserveContainerComponent },
          { path: ':id', component: ReserveContainerOneComponent },
        ],
      },
      {
        path: 'history',
        component: HistoryComponent,
        children: [
          { path: '', component: HistoryContainerComponent },
          { path: 'booked', component: HistoryBookedContainerComponent },
          { path: 'booked-canceled', component: HistoryBookedCanceledComponent },
          { path: 'return-tickets', component: HistoryReturnTicketContainerComponent },
        ],
      },
      {
        path: 'collection',
        component: CollectionComponent,
        children: [{ path: '', component: CollectionContainerComponent }],
      },
      {
        path: 'reports',
        component: ReportsWrapperComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: '',
            redirectTo: 'report-cashier',
            pathMatch: 'full',
          },
          {
            path: 'report-cashier',
            component: ReportsCashierPage,
          },
          {
            path: 'report-accountant',
            component: ReportsAccountantPage,
          },
          {
            path: 'report-by-event',
            component: ReportsByEventComponent,
          },
        ],
      },
      {
        path: 'accountant',
        component: AccountantWrapperComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: '',
            redirectTo: 'events-statistics',
            pathMatch: 'full',
          },
          {
            path: 'events-statistics',
            component: EventStatisticComponent,
          },
          {
            path: 'events-statistics/:id',
            component: EventDetailsComponent,
            children: [
              {
                path: '',
                redirectTo: 'general',
                pathMatch: 'full',
              },
              {
                path: 'general/:id',
                component: EventGeneralComponent,
              },
              {
                path: 'by-sellers/:id',
                component: EventBySellersComponent,
              },
              {
                path: 'reports/:id',
                component: ReportsByEventComponent,
              },
            ],
          },
          {
            path: 'report-cashier',
            component: ReportsComponent,
          },
          {
            path: 'sails-by-month',
            component: SailsByMonthComponent,
          },
        ],
      },
      {
        path: 'agents',
        component: AgentsPage,
      },
    ],
  },
];
