<div class="container">
  <ul>
    <li
      *ngFor="let one of info; trackBy: trackById"
      [ngClass]="{ container_info_animation: one === last, error: one.error || one.error == '' }"
      class="container_info"
      (click)="delete(one)"
    >
      <svg
        style="display: block; margin-right: 10px; width: 20px !important; height: 25px !important"
        width="20"
        [ngClass]="{ error: one.error }"
        height="25"
        (click)="delete(one)"
        viewBox="0 0 20 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [ngClass]="{ error: one.error }"
          d="M17.7778 2.22222H13.1111C12.6667 0.888889 11.4444 0 10 0C8.55556 0 7.33333 0.888889 6.88889 2.22222H2.22222C1 2.22222 0 3.22222 0 4.44444V22.2222C0 23.4444 1 24.4444 2.22222 24.4444H17.7778C19 24.4444 20 23.4444 20 22.2222V4.44444C20 3.22222 19 2.22222 17.7778 2.22222ZM10 2.22222C10.6667 2.22222 11.1111 2.66667 11.1111 3.33333C11.1111 4 10.6667 4.44444 10 4.44444C9.33333 4.44444 8.88889 4 8.88889 3.33333C8.88889 2.66667 9.33333 2.22222 10 2.22222ZM17.7778 22.2222H2.22222V4.44444H4.44444V7.77778H15.5556V4.44444H17.7778V22.2222Z"
        />
      </svg>
      <span>
        {{ one.info }}
      </span>
      <!--<button class="button" *ngIf="one.error" (click)="sendError(one)">send error</button>-->
    </li>
  </ul>
</div>
<!--<div class="container-info-send" *ngIf="errorStatus">-->
<!--<div class="form">-->
<!--<input type="text" [(ngModel)]="errorStatus.email" placeholder="email">-->
<!--<input type="text" [(ngModel)]="errorStatus.message" placeholder="message">-->
<!--<button class="button" (click)="send()">send</button>-->
<!--</div>-->
<!--</div>-->
