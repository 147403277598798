import {Role} from '../enums/enums';
import {ImageD} from '../User';
import {BoxOffice} from '../BoxOffice';
import {NetBoxOffice} from '../NetBoxOffice';

export class UserWithBoxOfficeDto {

  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  name: string;
  password: string;
  mail: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  uuid: string;
  role: Role;
  registrationDate: string;
  available: boolean;
  lock: boolean;
  image: ImageD;
  bonusCount: number;
  online: boolean;

  boxOffice: BoxOffice;
  netBoxOffice: NetBoxOffice;

}
