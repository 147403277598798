<div class="container">
  <form class="form"
    [formGroup]="reportForm"
    (ngSubmit)="generateReport()">

    <div class="form-row">
      <label>Період звіту:</label>
      <nz-date-picker formControlName="dateFrom"
        [nzDisabled]="trackNoDate"></nz-date-picker>
      <nz-date-picker formControlName="dateTo"
        [nzDisabled]="trackNoDate"></nz-date-picker>
    </div>

    <div class="form-row">
      <label nz-checkbox
        formControlName="trackNoDate"
        class="check-item">Не врахувати дату</label>
      <!-- [(ngModel)]="trackNoDate">Не врахувати дату</label> -->
    </div>

    <div class="form-row">
      <div class="row-item">
        <label for="reportType">Форма звіту</label>
        <select name="reportType"
          formControlName="reportType">
          <option *ngFor="let report of AVAILABLE_REPORTS"
            [value]="report.type">{{report.name}}</option>
        </select>
      </div>

      <div class="row-item">
        <label for="reportNumber">Номер звіту</label>
        <input name="reportNumber"
          type="text"
          formControlName="reportNumber"
          placeholder="12345">
      </div>

      <ng-container *ngIf="currentReportType === 'KG11'">
        <div class="row-item">
          <label for="netBox">Мережа кас</label>
          <select name="netBox"
            formControlName="netBox"
            (change)="changeNetwork()">
            <option *ngFor="let netBoxItem of netBoxList"
              [value]="netBoxItem.id">{{netBoxItem.name.valueUA}}</option>
          </select>
        </div>

        <div class="row-item">
          <label for="cashier">Каса</label>
          <select name="cashier"
            formControlName="cashier">
            <option *ngFor="let cashierItem of cashierList"
              [value]="cashierItem.id">{{cashierItem.name.valueUA}}</option>
          </select>
        </div>
      </ng-container>
    </div>

    <div class="form-row">
      <button class="form-button reset-button"
        type="button"
        (click)="initForm()">Скинути</button>
      <button class="form-button confirm-button"
        [disabled]="reportForm.invalid"
        type="submit">Завантажити</button>
    </div>
  </form>
</div>
