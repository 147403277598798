import { ClientPrevEventBaseDataDto } from './ClientPrevEventBaseDataDto';
import { IDictionary } from 'src/shared/interfaces/interfaces';
import { Video } from '../Video';
import { ImageD } from '../User';
import { Category } from '../Category';

export class ClientEventOneBaseDataDto extends ClientPrevEventBaseDataDto {
  abonementBaseEvent: ClientEventOneBaseDataDto;
  abonementEvent: boolean;
  description: IDictionary;
  eventCategory: Category[] = [];
  externalCommission: number;
  image: ImageD[] = [];
  internalCommission: number;
  isDiscountAvailable: boolean;
  shortDescription: IDictionary;
  similar: ClientPrevEventBaseDataDto[] = [];
  // sameEvent: ClientPrevEventBaseDataDto[] = [];
  ticketOrderTime: number;
  ticketOrderTimeBoxOffice: number;
  videos: Video[] = [];
  urlSegment: string;
}
