import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePipe } from './time.pipe';
import { Safe } from './safe-html.pipe';
import { ImagePipe } from './image.pipe';
import { LocalDateTimeToDayOfWeek } from './local-day-of-week.pipe';
import { LocaldatatimeToTimePipe } from './local-time.pipe';
import { LocalDataTimeToDate } from './local-date.pipe';
import { ValueByLanguage } from './value-by-lang.pipe';

const pipes = [
  TimePipe,
  Safe,
  ImagePipe,
  LocalDateTimeToDayOfWeek,
  LocaldatatimeToTimePipe,
  LocalDataTimeToDate,
  ValueByLanguage,
];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})

export class PipeModule {
}
