import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { BoxOffice } from '../../models/BoxOffice';
import { Page } from '../../models/Page';
import { EventFilterType } from '../../models/enums/enums';
import { BoxOfficeEventDto } from '../../models/dto/BoxOfficeEventDto';
import { SaleStatistics } from '../../models/Bin';

@Injectable({
  providedIn: 'root',
})
export class BoxOfficeService {
  controller = '/box-office';

  constructor(private _httpClient: HttpClient) {}

  changeHistoryPaymentType(binId: number, type: 'CASH' | 'CARD') {
    return this._httpClient.post<any>(`/v2/update-ticket-payment-type/${binId}?typeOfPayment=${type}`, {});
  }

  filterBoxOffice(
    id: number,
    page: number,
    size: number,
    cityId?: number,
    regionId?: number,
    countryId?: number,
    research?: string,
  ): Observable<any> {
    let p: HttpParams = new HttpParams();
    if (research) p = p.set('research', research);
    if (regionId) p = p.set('regionId', regionId + '');
    if (cityId) p = p.set('cityId', cityId + '');
    if (countryId) p = p.set('countryId', countryId + '');
    return this._httpClient
      .get<any>(`${this.controller}/filter-box-office/${id}?page=${page}&size=${size}`, {
        params: p,
      })
      .pipe(take(1))
      .catch(err => Observable.throw(err));
  }

  create(boxOffice: BoxOffice): Observable<BoxOffice> {
    return this._httpClient
      .post<BoxOffice>(this.controller + '/create', JSON.stringify(boxOffice))
      .pipe(catchError(err => throwError(err)));
  }

  findAllFilter(
    direction: 'ASC' | 'DESC',
    sort: 'id' | 'phone',
    page: number,
    size: number,
    research: HTMLInputElement,
    status: HTMLSelectElement,
    country: HTMLSelectElement,
    region: HTMLSelectElement,
    city: HTMLSelectElement,
  ): Observable<BoxOffice[]> {
    return this._httpClient
      .get<BoxOffice[]>(
        `${this.controller}/find-all-filter/${direction}/${sort}/${page}/${size}` + this.generator(research, status, country, region, city),
      )
      .pipe(catchError(err => throwError(err)));
  }

  private generator(
    research: HTMLInputElement,
    status: HTMLSelectElement,
    country: HTMLSelectElement,
    region: HTMLSelectElement,
    city: HTMLSelectElement,
  ): string {
    let url = '?';
    if (research.value != '') {
      url += 'research=' + research.value;
    }
    if (status.value != 'null') {
      if (url != '?') url += '&';
      url += 'available=' + status.value;
    }
    if (country.value != 'null') {
      if (url != '?') url += '&';
      url += 'city_region_country_id=' + country.value;
    }
    if (region.value != 'null') {
      if (url != '?') url += '&';
      url += 'city_region_id=' + region.value;
    }
    if (city.value != 'null') {
      if (url != '?') url += '&';
      url += 'city_id=' + city.value;
    }
    return url == '?' ? '' : url;
  }

  changeStatus(boxOffice: BoxOffice): Observable<BoxOffice> {
    return this._httpClient
      .post<BoxOffice>(this.controller + '/change-status', JSON.stringify(boxOffice))
      .pipe(catchError(err => throwError(err)));
  }

  update(boxOffice: BoxOffice): Observable<BoxOffice> {
    return this._httpClient
      .post<BoxOffice>(this.controller + '/update', JSON.stringify(boxOffice))
      .pipe(catchError(err => throwError(err)));
  }

  filterEventsBoxOfficeStatistic(
    size: number,
    page: number,
    eventFilterType: EventFilterType,
    cityId: number,
    platformHallId: number,
    categoryId: number,
    month: number,
    dateFrom: string,
    dateTo: string,
  ): Observable<Page<BoxOfficeEventDto>> {
    let body = `?size=${size}&page=${page}&`;
    body += this.parse(eventFilterType, 'eventFilterType');
    body += this.parse(cityId, 'cityId');
    body += this.parse(platformHallId, 'platformHallId');
    body += this.parse(categoryId, 'categoryId');
    if (month != undefined) {
      body += this.parse(month + 1, 'month');
    }
    body += this.parse(dateFrom, 'dateFrom');
    body += this.parse(dateTo, 'dateTo');
    return this._httpClient
      .get<Page<BoxOfficeEventDto>>(this.controller + '/filter-events-box-office-statistic' + body)
      .pipe(catchError(err => throwError(err)));
  }

  findAllBoxFilter(
    size: number,
    page: number,
    form?: HTMLFormElement,
    sort?: string,
    direction?: 'ASC' | 'DESC',
  ): Observable<Page<BoxOffice>> {
    let body = `?size=${size}&page=${page}&`;
    if (sort) {
      body += `sort=${sort},${direction}&`;
    }
    let data = new FormData(form);
    body += this.parseFormData(data, 'available');
    body += this.parseFormData(data, 'countryId');
    body += this.parseFormData(data, 'regionId');
    body += this.parseFormData(data, 'cityId');
    body += this.parseFormData(data, 'researcher');
    return this._httpClient
      .get<Page<BoxOffice>>(this.controller + '/find-all-box-office-filter' + body)
      .catch(err => Observable.throw(err));
  }

  parse(value, name): string {
    let body = '';
    if (value == 0) {
      value = '0';
    }
    if (value == '' || value == undefined) {
      return body;
    }
    body += name + '=' + value + '&';
    return body;
  }

  parseFormData(data, name, typeDate: boolean = false): string {
    let body = '';
    for (let i = 0; i < data.getAll(name).length; i++) {
      if (data.getAll(name)[i] + '' != 'null' && data.getAll(name)[i] + '' != '' && data.getAll(name)[i] + '' != 'undefined') {
        if (typeDate) {
          body += name + '=' + data.getAll(name)[i] + 'T23:59&';
        } else {
          body += name + '=' + data.getAll(name)[i] + '&';
        }
      }
    }
    return body;
  }

  public getSalesStatistics(
    status_bin: string,
    dateFrom: string,
    dateTo: string,
    type_of_payment?: string,
    platform_hall?: string,
    ownEventsOnly?: boolean,
  ): Observable<SaleStatistics> {
    let params = new HttpParams();
    params = params.set('status_bin', status_bin);
    if (dateFrom) {
      params = params.set('dateFrom', dateFrom + 'T00:00');
    }
    if (dateTo) {
      params = params.set('dateTo', dateTo + 'T23:59');
    }
    if (dateTo) {
      params = params.set('dateTo', dateTo + 'T23:59');
    }
    if (type_of_payment && +type_of_payment !== 0) {
      params = params.set('type_of_payment', type_of_payment);
    }
    if (platform_hall && +platform_hall !== 0) {
      params = params.set('platform_hall', platform_hall);
    }
    if (String(ownEventsOnly) === 'false' || String(ownEventsOnly) === 'true') {
      params = params.set('ownEventsOnly', String(ownEventsOnly));
    }

    return this._httpClient
      .get<SaleStatistics>('/box-office-booking/v1/filter-bin-bought-ticket-sum', { params })
      .pipe(catchError(err => throwError(err)));
  }

  public getSellersStatistic(eventId: string): Observable<any> {
    return this._httpClient.get(`/net-box-office/find-by-event-box/${eventId}`).pipe(catchError(err => throwError(err)));
  }

  public getSellersOnlineStatistic(eventId: string): Observable<any> {
    return this._httpClient.get(`/event/find-payment-online/${eventId}`).pipe(catchError(err => throwError(err)));
  }

  filterCashierResearch(page: number, size: number): Observable<any> {
    return this._httpClient.get(this.controller + `/filter-cashier-research?page=${page}&size=${size}`).catch(err => throwError(err));
  }

  findAllActiveWithCountEvent(): Observable<any> {
    return this._httpClient.get('/pay-system/find-all-active-with-count-event').pipe(catchError(err => throwError(err)));
  }

  filterCashierBoxofficeResearch(idBox: number): Observable<any> {
    return this._httpClient.get(this.controller + '/filter-cashier-boxoffice-research/' + idBox).pipe(
      take(1),
      catchError(err => Observable.throw(err)),
    );
  }
}
