export function downloadExcel(data: Blob, filename: string): void {
  let mediaType = 'application/xls';
  let blob = new Blob([data], { type: mediaType });
  const aElement = document.createElement('a');

  aElement.setAttribute('download', filename + '.xls');
  aElement.href = URL.createObjectURL(blob);
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(URL.createObjectURL(blob));
}
