import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-legend',
  templateUrl: './price-legend.component.html',
  styleUrls: ['./price-legend.component.css']
})
export class PriceLegendComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
